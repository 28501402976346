export default {
    title: 'My Deals',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'My Deals description'
        }
    ]
}
