<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import validation from '@/utils/validation'
import { getCountryOptions, getStateOptions } from '@/utils/countries-states'
import { authAPI } from '@/api/auth-api'
import {
    getFormLabels,
    getFormPlaceholders,
    productFamily,
    companyTypes,
    companyTypeOptions
} from '@/constants/deal-registration'
import { dealTypes } from '@/constants/deals'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { roles } from '@/constants/rbac/roles'
import { getExternalErrors } from '@/utils/formatting'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import { accountType } from '@/constants/rbac/accountType'

const states = getStateOptions()
const countries = getCountryOptions({ preferredCountries: ['us'] })
const USA = 'United States of America'

defineEmits(['intersection'])

const store = useStore()
const currentUser = computed(() => store.getters.currentUser)
const userAccountType = computed(() => store.getters.userAccountType)
const isManagerOrAdmin = computed(
    () =>
        store.getters.userRole === roles.MANAGER ||
        store.getters.userRole === roles.ADMIN
)
const isAccountReseller = computed(
    () => userAccountType.value === accountType.Reseller
)

const externalErrors = ref({})
const autoDirty = ref(false)

const users = ref([])
const totalUsersCount = ref(null)
const usersOptionsLoading = ref(false)
const prepareUsersOptions = (users) => {
    return users.map((user) => ({ value: user._id, text: user.name }))
}

const getUsers = (params) => {
    usersOptionsLoading.value = true
    authAPI
        .fetchUsers(params)
        .then((res) => {
            console.log('getUsers res: ', res)
            if (res?.results) {
                users.value = [
                    ...users.value,
                    ...prepareUsersOptions(res.results)
                ]

                if (!totalUsersCount.value) {
                    totalUsersCount.value = res._meta[0].count
                }
            }
        })
        .finally(() => {
            usersOptionsLoading.value = false
        })
}
if (isManagerOrAdmin.value) {
    getUsers()
}

const onIntersectionUsers = () => {
    const offset = users.value.length

    if (totalUsersCount.value > offset) {
        getUsers({ offset })
    }
}

const preparedUsers = computed(() => {
    const [currentUserOption] = prepareUsersOptions([currentUser.value])

    if (isManagerOrAdmin.value) {
        const filteredUsersOptions = users.value.filter(
            (u) => u.value !== currentUser.value._id
        )
        return [currentUserOption, ...filteredUsersOptions]
    } else {
        return [currentUserOption]
    }
})

const getInitForm = () => {
    return {
        createdBy: currentUser.value._id,
        routeToMarket: isAccountReseller.value ? companyTypes.IT : '',
        customerCompany: '',
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        customerCompanyDomain: '',
        country: '',
        state: '',
        closeDate: '',
        amount: '',
        type: '',
        productFamily: [],
        description: ''
    }
}
const form = ref(getInitForm())
const formLabels = computed(() => {
    return getFormLabels(form.value.routeToMarket)
})
const formPlaceholders = computed(() => {
    return getFormPlaceholders(form.value.routeToMarket)
})

const showNextStep = ref(false)
const showResponseMessage = ref(false)
const responseMessage = ref('')
const success = ref(false)

const rules = computed(() => {
    return {
        createdBy: {
            required: validation.required(formLabels.value.createdBy)
        },
        routeToMarket: {
            required: validation.required(formLabels.value.routeToMarket)
        },
        customerCompany: {
            required: validation.required(formLabels.value.customerCompany)
        },
        customerFirstName: {
            required: validation.required(formLabels.value.customerFirstName)
        },
        customerLastName: {
            required: validation.required(formLabels.value.customerLastName)
        },
        customerEmail: {
            required: validation.required(formLabels.value.customerEmail),
            validEmail: validation.validEmail
        },
        customerCompanyDomain: {
            required: validation.required(
                formLabels.value.customerCompanyDomain
            ),
            validDomain: validation.validDomain
        },
        country: {
            required: validation.required(formLabels.value.country)
        },
        state: {
            requiredIf: validation.requiredIf(
                formLabels.value.state,
                () => form.value.country === USA
            )
        },
        closeDate: {
            required: validation.required(formLabels.value.closeDate)
        },
        amount: {
            required: validation.required(formLabels.value.amount),
            minValue: validation.minValue(formLabels.value.amount, 1)
        },
        type: {
            required: validation.required(formLabels.value.type)
        },
        productFamily: {
            required: validation.required(formLabels.value.productFamily)
        },
        description: {
            maxLength: validation.maxLength(formLabels.value.description, 255)
        }
    }
})

const handlerForm = async ({ amount, state, ...form }) => {
    try {
        const preparedForm = {
            ...form,
            amount: +amount,
            state: form.country === USA ? state : ''
        }
        console.log('preparedForm: ', preparedForm)
        const res = await store.dispatch('submitDeal', preparedForm)
        console.log('Deal form res: ', res)
        if (res?.data) {
            const responseMssage = `
                 New Deal Registration was <span>Created</span>.\n
                 <div>A Channel Manager will be reaching out to you shortly.</div>
                `
            setDealResponse(responseMssage, true)
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true

            const errors = res.errInfo.errors
            externalErrors.value = getExternalErrors(errors)
            console.log('externalErrors.value: ', externalErrors.value)
        } else {
            setDealResponse('Failed to create a deal. Try again', false)
        }
    } catch (err) {
        pushNotification({
            message: `<span>${err.name}</span>: ${err.message}`,
            type: notificationTypes.ERROR
        })
    }
}
const setDealResponse = (message, isSuccess) => {
    responseMessage.value = message
    success.value = isSuccess
    showResponseMessage.value = true
    showNextStep.value = false
}
const resetForm = (initialForm) => {
    form.value = getInitForm()
}

const getValidation = (invalid, validator) => {
    const fieldsOnFirstStep = [
        'createdBy',
        'routeToMarket',
        'customerCompany',
        'customerFirstName',
        'customerLastName',
        'customerEmail',
        'customerCompanyDomain',
        'country',
        'productFamily'
    ]
    if (form.value.country === USA) {
        fieldsOnFirstStep.push('state')
    }

    const invalidFieldOnFirstStep = fieldsOnFirstStep.some(
        (field) => validator[field].$invalid
    )

    if (invalidFieldOnFirstStep) {
        showNextStep.value = false
    }

    return invalid ? validator.$touch() : null
}

const goNextStep = () => {
    if (form.value.routeToMarket) {
        showNextStep.value = true
    }
}
const routeToMarketIsMSP = computed(() => {
    return form.value.routeToMarket === companyTypes.MSP
})
const subtitle = computed(() => {
    return routeToMarketIsMSP.value ? 'MSP Information' : 'Enduser Information'
})
const descriptionNote = computed(() => {
    return routeToMarketIsMSP.value
        ? 'This could include amount of licenses required, compliance needs, etc.'
        : 'If a Reseller is involved in this deal, please add the Reseller information here.'
})
const dealTypeOptions = computed(() => {
    return [
        {
            value: dealTypes.NEW_BUSINESS,
            text: 'New Business'
        },
        {
            value: dealTypes.ADD_ON,
            text: routeToMarketIsMSP.value
                ? 'Add On (for existing customer)'
                : 'Add On'
        }
    ]
})
</script>

<script>
export default {
    name: 'DealRegistrationForm'
}
</script>

<template>
    <BaseBlock size="lg" class="deal-form">
        <div v-if="showResponseMessage" class="deal-form__response">
            <div
                class="deal-form__response--icon"
                :class="
                    success
                        ? 'deal-form__response--icon_success'
                        : 'deal-form__response--icon_error'
                "
            >
                <BaseIcon
                    :i="success ? 'notification-success' : 'notification-error'"
                />
            </div>
            <div v-html="responseMessage" class="deal-form__response--text" />
            <BaseButton
                @click="showResponseMessage = false"
                class="deal-form__response--action"
            >
                Register Next Deal
            </BaseButton>
        </div>
        <template v-else>
            <div class="deal-form__steps">
                <span
                    class="deal-form__step"
                    :class="{ 'deal-form__step_active': !showNextStep }"
                >
                    01
                </span>
                /
                <span
                    class="deal-form__step"
                    :class="{ 'deal-form__step_active': showNextStep }"
                >
                    02
                </span>
            </div>
            <BaseForm
                :form="form"
                :rules="rules"
                :external-errors="externalErrors"
                :auto-dirty="autoDirty"
                :on-submit="handlerForm"
                reset-form
                :on-reset="resetForm"
            >
                <template #default="{ validator }">
                    <div v-show="!showNextStep" class="deal-form__wrap">
                        <BaseSelect
                            :options="preparedUsers"
                            v-model="form.createdBy"
                            :validator="validator.createdBy"
                            :name="formLabels.createdBy"
                            :placeholder="formPlaceholders.createdBy"
                            :options-loading="usersOptionsLoading"
                            required
                            :disabled="!isManagerOrAdmin"
                            intersection
                            @intersection="onIntersectionUsers"
                        />
                        <BaseSelect
                            v-if="!isAccountReseller"
                            :options="companyTypeOptions"
                            v-model="form.routeToMarket"
                            :validator="validator.routeToMarket"
                            :name="formLabels.routeToMarket"
                            :placeholder="formPlaceholders.routeToMarket"
                            required
                        />
                        <template v-if="form.routeToMarket">
                            <h3 class="deal-form__subtitle">{{ subtitle }}</h3>
                            <BaseInput
                                type="text"
                                v-model="form.customerCompany"
                                :validator="validator.customerCompany"
                                :name="formLabels.customerCompany"
                                :placeholder="formPlaceholders.customerCompany"
                                required
                                autocomplete="off"
                            />
                            <BaseInput
                                type="text"
                                v-model="form.customerFirstName"
                                :validator="validator.customerFirstName"
                                :name="formLabels.customerFirstName"
                                :placeholder="
                                    formPlaceholders.customerFirstName
                                "
                                required
                                autocomplete="off"
                            />
                            <BaseInput
                                type="text"
                                v-model="form.customerLastName"
                                :validator="validator.customerLastName"
                                :name="formLabels.customerLastName"
                                :placeholder="formPlaceholders.customerLastName"
                                required
                                autocomplete="off"
                            />
                            <BaseInput
                                type="text"
                                v-model="form.customerEmail"
                                :validator="validator.customerEmail"
                                :name="formLabels.customerEmail"
                                :placeholder="formPlaceholders.customerEmail"
                                required
                                autocomplete="off"
                            />
                            <BaseInput
                                type="text"
                                v-model="form.customerCompanyDomain"
                                :validator="validator.customerCompanyDomain"
                                :name="formLabels.customerCompanyDomain"
                                :placeholder="
                                    formPlaceholders.customerCompanyDomain
                                "
                                required
                            />
                        </template>
                        <BaseSelect
                            :options="countries"
                            v-model="form.country"
                            :validator="validator.country"
                            :name="formLabels.country"
                            :placeholder="formPlaceholders.country"
                            required
                            searchable
                        />
                        <BaseSelect
                            v-if="form.country === USA"
                            :options="states"
                            v-model="form.state"
                            :validator="validator.state"
                            :name="formLabels.state"
                            :placeholder="formPlaceholders.state"
                            required
                            searchable
                        />
                        <BaseCheckboxBox
                            title="Product/Products Interesting in"
                            v-model="form.productFamily"
                            :options="productFamily"
                            :validator="validator.productFamily"
                            required
                            class="deal-form__checkbox-box"
                        />
                        <BaseButton
                            design="outline"
                            size="md"
                            :disabled="!form.routeToMarket"
                            @click="goNextStep"
                            class="deal-form__btn deal-form__btn_mt-auto"
                        >
                            Next step
                        </BaseButton>
                    </div>
                </template>
                <template #button="{ loading, invalid, submitText, validator }">
                    <div v-show="showNextStep" class="deal-form__wrap">
                        <BaseDate
                            v-model="form.closeDate"
                            :validator="validator.closeDate"
                            :placeholder="formPlaceholders.closeDate"
                            :name="formLabels.closeDate"
                            required
                        />
                        <BaseInput
                            type="currency"
                            v-model="form.amount"
                            :validator="validator.amount"
                            :name="formLabels.amount"
                            :placeholder="formPlaceholders.amount"
                            required
                            autocomplete="off"
                        />
                        <BaseSelect
                            :options="dealTypeOptions"
                            v-model="form.type"
                            :validator="validator.type"
                            :name="formLabels.type"
                            :placeholder="formPlaceholders.type"
                            required
                        />
                        <div class="deal-form__description-wrap">
                            <div class="deal-form__gloss deal-form__gloss_lg">
                                Provide details about the deal that will help
                                msp360 approve and help you progress your deal.
                            </div>
                            <div
                                v-if="!isAccountReseller"
                                class="deal-form__gloss deal-form__gloss_sm"
                            >
                                {{ descriptionNote }}
                            </div>

                            <BaseInput
                                type="textarea"
                                v-model="form.description"
                                :validator="validator.description"
                                :name="formLabels.description"
                                :placeholder="formPlaceholders.description"
                            />
                        </div>
                        <div class="deal-form__btns">
                            <BaseButton
                                v-if="
                                    checkHasPermissions([
                                        permissionsEnum.CREATE_DEAL
                                    ])
                                "
                                size="md"
                                :type="invalid ? 'button' : 'submit'"
                                :loading="loading"
                                :disabled="invalid"
                                @click="getValidation(invalid, validator)"
                                class="deal-form__btn deal-form__btn_mt-auto"
                            >
                                {{ submitText }}
                            </BaseButton>
                            <BaseButton
                                design="outline"
                                size="md"
                                @click="showNextStep = false"
                                class="deal-form__btn"
                            >
                                Previous step
                            </BaseButton>
                        </div>
                    </div>
                </template>
            </BaseForm>
        </template>
    </BaseBlock>
</template>

<style lang="scss" scoped>
.deal-form {
    &__steps {
        color: #dce1ff;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 16px;
    }
    &__step {
        &_active {
            color: #ff7231;
        }
    }
    &__btns {
        margin-top: auto;
    }
    &__btn {
        &.button_size-md {
            width: 100%;
            font-weight: 400;
            &:deep(.spinner) {
                height: 24px;
                width: 24px;
            }
        }
        &.button_theme-primary.button_design-outline {
            border: 1px solid $primary-btn-border-color;
        }
        &_mt-auto {
            margin-top: auto;
        }
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    &__wrap {
        display: flex;
        flex-direction: column;
        // min-height: 690px;
    }
    &__subtitle {
        color: #636363;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin: 14px 0 10px;
    }
    &__checkbox-box {
        margin-top: 20px;
        margin-bottom: 28px;
        &:deep(.checkbox-box__wrap) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 14px 8px;
        }
        &:deep(.checkbox-block input + label) {
            font-size: 12px;
            line-height: 16px;
        }
    }
    &__description-wrap {
        margin-bottom: 22px;
    }
    &__gloss {
        color: #969696;
        &_lg {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.026em;
            margin-top: 24px;
            margin-bottom: 8px;
        }
        &_sm {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;
        }
    }
    &__response {
        display: flex;
        flex-direction: column;
        align-items: center;
        &--icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            color: #ffffff;
            margin-bottom: 12px;
            &_success {
                background: #6caf7e;
            }
            &_error {
                background: #f47070;
            }
        }
        &--text {
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #757575;
            margin-bottom: 34px;
            max-width: 240px;
            :deep(span) {
                color: #179c2d;
            }
        }
    }
    &:deep(.input-block__input_textarea) {
        height: 120px;
    }
}
</style>
