export function getCurrency(target, options = {}) {
    const initOptions = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        useGrouping: false
    }

    const { local, ...customOptions } = options

    return new Intl.NumberFormat(local || 'en-IN', {
        ...initOptions,
        ...customOptions
    }).format(target)
}

export const getProcessedURL = (str) => {
    return decodeURIComponent(encodeURIComponent(str).replace(/%20/g, '+'))
}

export const getExternalErrors = (errors) => {
    /**
     * required type of output data:
     * { email: 'Bad Request' or ['Bad Request1', 'Bad Request2'] }
     */
    return errors.reduce((acc, error) => {
        acc[error.param] = error.value
            ? `${error.msg}: ${error.value}`
            : error.msg
        return acc
    }, {})
}
