<script setup>
import { ref } from 'vue'
import { useHead } from 'unhead'
import quoteCalculatorMeta from '@/constants/meta/quote-calculator'

import CalculatorMainBlock from '@/components/blocks/CalculatorMainBlock'
import LoadingSpinner from '@/components/common/LoadingSpinner'

import { pushNotification } from '@/utils/pushNotification'
import { notificationTypes } from '@/constants/notificationTypes'
import { productsAPI } from '@/api/products-api'

useHead(quoteCalculatorMeta)

const products = ref([])
const tier = ref('')
const discount = ref(0)

const loading = ref(true)
const error = ref(false)

const getProducts = async () => {
    try {
        const receivedProducts = await productsAPI.fetchProducts()
        console.log('products: ', receivedProducts)
        const info = await productsAPI.fetchProductsInfo()
        console.log('info: ', info)

        if (receivedProducts?.data && info?.data) {
            products.value = receivedProducts.data.results
            tier.value = info.data.tier
            discount.value = info.data.discount
        } else if (
            receivedProducts?.errInfo?.isError ||
            info?.errInfo?.isError
        ) {
            error.value = true
        } else {
            throw new Error('Unknown error in fetching the products')
        }
    } catch (err) {
        error.value = true

        pushNotification({
            message: `<span>${err.name}</span> ${err.message}`,
            type: notificationTypes.ERROR
        })
    }

    loading.value = false
}

getProducts()
</script>

<script>
export default {
    name: 'QuoteCalculatorPage'
}
</script>

<template>
    <div class="calculator-page">
        <h1 class="title title_mb">Product Pricing — Submit a Quote</h1>
        <div v-if="loading" class="calculator-page__loading-wrap">
            <LoadingSpinner size="lg" />
        </div>
        <CalculatorMainBlock
            v-else-if="!loading && !error"
            :products="products"
            :tier="tier"
            :discount="discount"
        />
        <div v-else class="text">Ooops... Something went wrong</div>
    </div>
</template>

<style lang="scss" scoped>
.calculator-page {
    &__loading-wrap {
        position: absolute;
        top: 50%;
        left: calc((100% + 384px) / 2);
        transform: translate(-50%, 0);
    }
}
</style>
