<script setup>
defineProps({
    table: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] }
})
</script>

<script>
export default {
    name: 'AdminTable'
}
</script>

<template>
    <div class="table-block">
        <table class="table-block__table">
            <thead class="table-block__table--header">
                <tr>
                    <th
                        v-for="(col, i) in table"
                        :key="i"
                        :class="{ sticky: col.sticky }"
                    >
                        {{ col.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table-block__table--row"
                    v-for="(item, i) in items"
                    :key="i"
                >
                    <td
                        v-for="(col, j) in table"
                        :key="j"
                        :class="{
                            sticky: col.sticky,
                            'long-text': col.longText
                        }"
                        :title="col.longText ? item[col.target] : null"
                    >
                        <slot v-if="col.slug" :name="col.slug" v-bind="item" />
                        <template v-else>
                            {{ item[col.target] }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.table-block {
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #f4f5fb;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: #c9cff2;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #bec4e4;
    }
    &__table {
        table-layout: auto;
        width: 100%;
        white-space: nowrap;
        th,
        td {
            padding: 16px 0;
            padding-right: 72px;
            width: max-content;
            &:last-child {
                padding-right: 0px;
            }
        }
        th {
            color: #b5b5b5;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.266px;
        }
        td {
            color: #757575;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0.416px;

            &.sticky {
                position: sticky;
                right: 0;
                z-index: 2;
                padding-left: 40px;
                text-align: end;
                background-image: linear-gradient(
                    to left,
                    rgb(255, 255, 255) 70%,
                    rgba(255, 255, 255, 0.1)
                );
            }
            &.long-text {
                max-width: 500px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &--row,
        &--header {
            border-bottom: 1px solid #f4f6fa;
        }
        &--row {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>
