import { alias } from '@/constants/quote-calculator-new'

export const getInitData = () => {
    return {
        [alias.DESKTOP]: { win: 0, macos: 0 },
        [alias.SERVER]: { win: 0, linux: 0 },
        [alias.SQL_SERVER]: { win: 0 },
        [alias.MS_EXCHANGE]: { win: 0 },
        [alias.VM_SERVER]: { vm: 0 },
        [alias.VM_SERVER_SOCKET]: { vm: 0 },
        [alias.OFFICE_365]: { ms: 0, gw: 0 },
        [alias.SHAREPOINT]: { ms: 0, gw: 0 },
        [alias.RMM]: { rmm: 0 },
        [alias.DI]: { di: 0 },
        [alias.CONNECT]: { win: 0, macos: 0 },

        [alias.REBRANDING]: { additional: 0 },
        [alias.SUPPORT]: { additional: false },
        [alias.ONBOARDING]: { additional: false }
    }
}
export const calculatorBlocks = [
    {
        theme: 'orange',
        header: {
            theme: 'orange',
            iconProduct: 'product-backup',
            text: 'MSP360 Managed Backup',
            link: {
                href: 'https://www.msp360.com/managed-backup/',
                text: 'Learn more'
            },
            tooltip: {
                content: 'Cross-platform backup and recovery solution',
                bgColor: 'orange'
            }
        },
        body: [
            {
                os: 'win',
                icon: 'win',
                title: 'Backup for <br/>Windows',
                products: [
                    alias.DESKTOP,
                    alias.SERVER,
                    alias.SQL_SERVER,
                    alias.MS_EXCHANGE
                ]
            },
            {
                os: 'macos',
                icon: 'macos',
                title: 'Backup for<br/>mac OS',
                products: [alias.DESKTOP]
            },
            {
                os: 'linux',
                icon: 'linux',
                title: 'Backup for<br/>Linux',
                products: [alias.SERVER]
            },
            {
                os: 'vm',
                icon: 'vm',
                title: 'Backup for<br/>Hyper-V / VMware',
                products: [alias.VM_SERVER, alias.VM_SERVER_SOCKET]
            },
            {
                os: 'ms',
                icon: 'ms',
                title: 'Backup for<br/>Microsoft 365',
                products: [alias.OFFICE_365, alias.SHAREPOINT]
            },
            {
                os: 'gw',
                icon: 'gw',
                title: 'Backup for<br/>Google Workspace',
                products: [alias.OFFICE_365, alias.SHAREPOINT]
            }
        ]
    },
    {
        theme: 'green',
        header: {
            theme: 'green',
            iconProduct: 'product-rmm',
            text: 'MSP360 RMM',
            points: [
                'Fully integrated RMM, Remote Access & Endpoint Protection',
                '1 Admin license - Unlimited RMM agents for Windows, Mac & Linux Devices',
                'Free integrated Connect license for remote sessions'
            ],
            link: { href: 'https://www.msp360.com/rmm/', text: 'Learn more' },
            tooltip: {
                content: 'Reliable endpoint monitoring and management solution',
                bgColor: 'green'
            }
        },
        body: [
            {
                os: 'rmm',
                icon: 'rmm',
                title: 'RMM <span class="small">Priced per Admin</span>',
                products: [alias.RMM]
            },
            {
                os: 'di',
                icon: 'di',
                title: 'Deep Instinct <span class="small">Priced per Endpoint</span>',
                products: [alias.DI]
            }
        ]
    },
    {
        theme: 'blue',
        header: {
            theme: 'blue',
            iconProduct: 'product-connect',
            text: 'MSP360 Managed Connect',
            link: {
                href: 'https://www.msp360.com/connect/',
                text: 'Learn more'
            },
            tooltip: {
                content: 'Secure remote access solution',
                bgColor: 'blue'
            }
        },
        body: [
            {
                os: 'win',
                icon: 'win',
                title: 'Remote Access for<br/>Windows',
                products: [alias.CONNECT]
            },
            {
                os: 'macos',
                icon: 'macos',
                title: 'Remote Access for<br/>mac OS',
                products: [alias.CONNECT]
            }
        ]
    }
]

export const calculatorAdditionalBlock = {
    header: {
        text: 'Additional services'
    },
    products: [alias.REBRANDING, alias.SUPPORT, alias.ONBOARDING]
}
