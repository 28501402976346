<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { getCurrency } from '@/utils/formatting'

const store = useStore()

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    withTitle: { type: Boolean, default: false }
})
const classes = computed(() => {
    const classes = ['deals-block']

    if (props.mode) {
        classes.push(`deals-block_${props.mode}`)
    }
    if (
        preparedAverageAmount.value.length > 6 ||
        preparedTotalAmount.value.length > 6
    ) {
        classes.push('deals-block_value-sm')
    }

    return classes
})

const substitutePartnerID = computed(() => store.getters.substitutePartnerID)
const statisticsLoading = computed(() => store.getters.statisticsLoading)
const dealsTotalCount = computed(() => store.getters.dealsTotalCount)
const dealsAverageAmount = computed(() => store.getters.dealsAverageAmount)
const dealsTotalAmount = computed(() => store.getters.dealsTotalAmount)

const basicRequest = () => {
    if (substitutePartnerID.value) {
        store.dispatch('setDealStatistics', {
            partner: substitutePartnerID.value
        })
    } else {
        store.dispatch('setDealStatistics')
    }
}

if (
    !dealsTotalCount.value ||
    !dealsAverageAmount.value ||
    !dealsTotalAmount.value
) {
    basicRequest()
}

watch(substitutePartnerID, () => {
    basicRequest()
})

const preparedCount = computed(() => {
    return dealsTotalCount.value || '0'
})
const preparedAverageAmount = computed(() => {
    const averageAmount = getCurrency(dealsAverageAmount.value)
    return averageAmount || '$0'
})
const preparedTotalAmount = computed(() => {
    const totalAmount = getCurrency(dealsTotalAmount.value)
    return totalAmount || '$0'
})
</script>
<script>
export default {
    name: 'DealsBlock'
}
</script>

<template>
    <div :class="classes">
        <h2 v-if="withTitle" class="subtitle subtitle_mb">Deals</h2>
        <div class="deals-block__wrap">
            <BaseBlock class="deals-block__card">
                <h3 class="deals-block__card--title">Number of Deals</h3>
                <div class="deals-block__card--content">
                    <BaseIcon class="deals-block__card--icon" i="count-deals" />
                    <div class="deals-block__card--value">
                        <LoadingSpinner
                            v-if="statisticsLoading"
                            class="deals-block__card--loading"
                        />
                        <div v-else :title="preparedCount">
                            {{ preparedCount }}
                        </div>
                    </div>
                </div>
            </BaseBlock>
            <BaseBlock class="deals-block__card">
                <h3 class="deals-block__card--title">Average Deal Size</h3>
                <div class="deals-block__card--content">
                    <BaseIcon class="deals-block__card--icon" i="statistics" />
                    <div class="deals-block__card--value">
                        <LoadingSpinner
                            v-if="statisticsLoading"
                            class="deals-block__card--loading"
                        />
                        <div v-else :title="preparedAverageAmount">
                            {{ preparedAverageAmount }}
                        </div>
                    </div>
                </div>
            </BaseBlock>
            <BaseBlock class="deals-block__card" bg="blue">
                <h3 class="deals-block__card--title">Total Value</h3>
                <div class="deals-block__card--content">
                    <BaseIcon class="deals-block__card--icon" i="total-price" />
                    <div class="deals-block__card--value">
                        <LoadingSpinner
                            v-if="statisticsLoading"
                            class="deals-block__card--loading"
                        />
                        <div v-else :title="preparedTotalAmount">
                            {{ preparedTotalAmount }}
                        </div>
                    </div>
                </div>
            </BaseBlock>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.deals-block {
    display: flex;
    flex-direction: column;
    &__wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        flex: 1;
    }
    &__card {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include media_lg {
            align-items: center;
        }
        &--title {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #969696;
            margin-bottom: 16px;
            flex: 1;

            @include media_lg {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 24px;
                text-align: center;
            }
        }
        &--content {
            display: flex;
            align-items: center;
            @include media_max_xl {
                flex-direction: column;
            }
        }
        &--icon.icon {
            width: 64px;
            height: 64px;
            flex: 0 0 64px;
            margin-right: 16px;

            @include media_max_xl {
                margin-right: 0;
            }
            @include media_lg {
                display: none;
            }
        }
        &--value {
            font-weight: 500;
            font-size: 40px;
            line-height: 56px;
            color: #2f76ff;
            @include media_lg {
                font-size: 32px;
                line-height: 36px;
            }
        }
        &--loading {
            width: 45px;
            height: 45px;
        }
    }
    &_value-sm {
        .deals-block__card--value {
            font-size: 34px;
            line-height: 44px;
            @include media_lg {
                font-size: 26px;
                line-height: 32px;
            }
        }
    }
}
</style>
