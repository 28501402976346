import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createHead } from 'unhead'
import VueClickAway from 'vue3-click-away'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

import VueAwesomePaginate from 'vue-awesome-paginate'
import 'vue-awesome-paginate/dist/style.css'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import tooltipThemes from '@/constants/tooltip-themes'

import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'
const vfm = createVfm()

import { Tabs, Tab } from 'vue3-tabs-component'

import UIComponents from '@/components/UI'
import directives from '@/directives'

const app = createApp(App)

app.component('VueTabs', Tabs)
app.component('VueTab', Tab)

// https://unhead.harlanzw.com/
const head = createHead()

// registration UI components globally
UIComponents.forEach((component) => {
    app.component(component.name, component)
})

// registration directives globally
Object.keys(directives).forEach((name) => {
    app.directive(name, directives[name])
})

// https://iamstevendao.com/vue-tel-input/usage/props.html
const telOptions = {
    mode: 'national',
    autoFormat: false,
    dropdownOptions: {
        showDialCodeInSelection: true,
        showSearchBox: true,
        width: '260px'
    },
    preferredCountries: ['US', 'CA', 'IN', 'DE', 'FR', 'GB', 'IT']
}

app.use(store)
    .use(router)
    .use(head)
    .use(VueClickAway)
    .use(FloatingVue, tooltipThemes)
    .use(VueTelInput, telOptions)
    .use(vfm)
    .use(VueAwesomePaginate)
    .mount('#app')
