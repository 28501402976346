<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useVfm } from 'vue-final-modal'
import { useHead } from 'unhead'
import _ from 'lodash'
import meta from '@/constants/meta/admin/users'
import { userStatuses } from '@/constants/user-statuses'
import { partnerRoleOptions } from '@/constants/partnerRegistration'
import { SEARCH_MODE_DYNAMIC } from '@/constants/baseSelect'

import useFilters from '@/composables/useFilters'
import useConfirm from '@/composables/useConfirm'
import useSelectPartners from '@/composables/useSelectPartners'

import AdminLayout from '@/components/blocks/admin/AdminLayout'
import AdminTable from '@/components/blocks/admin/AdminTable'
import AdminAction from '@/components/blocks/admin/AdminAction'
import ConfirmationModal from '@/components/common/ConfirmationModal'
import UserRegistrationFormForAdmin from '@/components/forms/admin/UserRegistrationFormForAdmin'
import FilterWrapper from '@/components/filters/FilterWrapper'

useHead(meta)
const store = useStore()
const vfm = useVfm()

const users = computed(() => store.getters['admin/getUsers'](limit.value))
const limit = ref(10)
const currentPage = ref(1)
const editableUser = ref({})

/* filters */
const filtersContent = {
    email: {
        label: {
            id: 'email',
            text: 'By Email'
        },
        filter: {
            type: 'search',
            id: 'email',
            name: 'email',
            placeholder: 'Enter an Email'
        }
    },
    assignedPartner: {
        label: {
            id: 'assignedPartner',
            text: 'By Partner'
        },
        filter: {
            name: 'Search for Partner',
            placeholder: 'Search for Partner',
            id: 'assignedPartner',
            intersection: true,
            searchable: true,
            searchMode: SEARCH_MODE_DYNAMIC,
            optionsPlaceholder: "Start typing Partner's name"
        }
    },
    role: {
        filter: {
            title: 'By Role',
            options: partnerRoleOptions
        }
    }
}

const { filters, isFilterSet, resetFilters } = useFilters({
    email: '',
    assignedPartner: '',
    role: []
})
/* filters */

const blockActionId = Symbol('block-confirmafion')
const deleteActionId = Symbol('delete-confirmafion')
const registerUserModal = Symbol('register-user-modal')
const {
    partnerOptions,
    getPartners,
    partnerOptionsLoading,
    searchPartnersResultText,
    onIntersection,
    onSearchPartners,
    onClearSearchPartners,
    resetOption
} = useSelectPartners()

getPartners() // init partners for select

const partnerOptionsForFilter = computed(() => {
    return [resetOption, ...partnerOptions.value]
})

const tableColumns = [
    {
        target: 'name',
        title: 'Name'
    },
    {
        target: 'email',
        title: 'Email'
    },
    {
        target: 'role',
        title: 'Role'
    },
    {
        slug: 'status',
        title: 'Status'
    },
    {
        target: 'assignedPartner',
        title: 'Assigned Partner'
    },
    {
        slug: 'actions',
        sticky: true
    }
]

const getStatus = (item) => {
    if (item.confirmed && !item.deleted) {
        return userStatuses.ACTIVE
    } else if (item.deleted) {
        return userStatuses.BLOCKED
    } else {
        return userStatuses.NEW
    }
}
const tagInfo = (item) => {
    const status = getStatus(item)

    switch (status) {
        case userStatuses.ACTIVE:
            return {
                text: 'Active',
                styling: 'green'
            }
        case userStatuses.BLOCKED:
            return {
                text: 'Blocked',
                styling: 'grey'
            }
        case userStatuses.NEW:
            return {
                text: 'New',
                styling: 'bright-blue'
            }
    }
}
const blockUser = async (user) => {
    editableUser.value = user
    const [getConfirmation] = useConfirm(blockActionId)
    const confirmation = await getConfirmation()

    if (confirmation) {
        store.dispatch('admin/blockUser', user._id)
    }
}
const deleteUser = async (user) => {
    editableUser.value = user
    const [getConfirmation] = useConfirm(deleteActionId)
    const confirmation = await getConfirmation()

    if (confirmation) {
        store.dispatch('admin/deleteUser', user.email)
    }
}

const getActions = (user) => {
    const status = getStatus(user)

    return [
        {
            icon: 'block',
            hint: {
                content: 'Block'
            },
            disabled: status === userStatuses.BLOCKED,
            cb: blockUser.bind(this, user)
        },
        {
            icon: 'delete',
            hint: {
                content: 'Delete'
            },
            cb: deleteUser.bind(this, user)
        },
        {
            icon: 'invite',
            hint: {
                content: 'Invite'
            },
            disabled: status !== userStatuses.NEW,
            cb: store.dispatch.bind(this, 'admin/inviteUser', user)
        }
    ]
}
const params = computed(() => {
    return {
        limit: limit.value,
        offset: users.value.list.length * (currentPage.value - 1),
        isFilterSet: isFilterSet.value,
        ...filters.value
    }
})

onMounted(() => {
    store.dispatch('admin/fetchUsers', params.value)
})

const debouncedFetchUsers = _.debounce(() => {
    store.dispatch('admin/fetchUsers', params.value)
}, 1000)

watch(filters.value, debouncedFetchUsers)
</script>
<script>
export default {
    name: 'AdminUsersPage'
}
</script>

<template>
    <AdminLayout :loading="users.loading" class="admin-users-page">
        <template #filter>
            <FilterWrapper :label="filtersContent.email.label">
                <BaseInput
                    v-bind="filtersContent.email.filter"
                    v-model="filters.email"
                />
            </FilterWrapper>
            <FilterWrapper :label="filtersContent.assignedPartner.label">
                <BaseSelect
                    :options="partnerOptionsForFilter"
                    v-model="filters.assignedPartner"
                    @intersection="onIntersection"
                    :options-loading="partnerOptionsLoading"
                    @search="onSearchPartners"
                    @clearSearch="onClearSearchPartners"
                    :search-result-text="searchPartnersResultText"
                    v-bind="filtersContent.assignedPartner.filter"
                />
            </FilterWrapper>
            <FilterWrapper>
                <BaseCheckboxBox
                    v-model="filters.role"
                    v-bind="filtersContent.role.filter"
                />
            </FilterWrapper>
        </template>
        <template #filter-footer>
            <BaseButton design="outline-blue" @click="resetFilters">
                Reset
            </BaseButton>
        </template>
        <template #table>
            <AdminTable :table="tableColumns" :items="users.list">
                <template #status="item">
                    <BaseTag v-bind="tagInfo(item)" />
                </template>
                <template #actions="item">
                    <AdminAction
                        v-for="(action, index) in getActions(item)"
                        :key="index"
                        :action="action"
                    />
                </template>
            </AdminTable>
        </template>
        <template #toolbar>
            <BaseButton icon="plus" @click="() => vfm.open(registerUserModal)">
                Add User
            </BaseButton>
        </template>
        <template #footer>
            <BasePagination
                v-model="currentPage"
                :total-items="users.total"
                @click="() => store.dispatch('admin/fetchUsers', params)"
            />
        </template>
    </AdminLayout>
    <ConfirmationModal :id="blockActionId" text-on-resolve="Block">
        Are you sure you want to block the User
        <!-- eslint-disable -->
        <span>{{ editableUser.name }}</span>?
    </ConfirmationModal>
    <ConfirmationModal :id="deleteActionId" text-on-resolve="Delete">
        Are you sure you want to delete the User
        <!-- eslint-disable -->
        <span>{{ editableUser.name }}</span>?
    </ConfirmationModal>
    <BaseModal
        :modal-id="registerUserModal"
        :with-close-btn="false"
        :width-content="550"
        class="admin-users-page__modal"
    >
        <h2 class="block-title block-title_center">Add a New User</h2>
        <UserRegistrationFormForAdmin
            submit-text="Send invite"
            :partners="partnerOptions"
            @intersection="onIntersection"
            :partner-options-loading="partnerOptionsLoading"
            @searchPartners="onSearchPartners"
            @clearSearchPartners="onClearSearchPartners"
            :search-partners-result-text="searchPartnersResultText"
            @success="() => vfm.close(registerUserModal)"
            class="admin-users-page__registration-form"
        />
    </BaseModal>
</template>

<style lang="scss" scoped>
.admin-users-page {
    &__registration-form {
        padding: 0 54px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:deep(.button) {
            margin-top: 16px;
            align-self: center;
        }
    }
}
</style>
