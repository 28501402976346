<script setup>
import { computed } from 'vue'

const props = defineProps({
    mode: { type: String, default: 'light' } // light, dark
})
const classes = computed(() => {
    const classes = ['resources-block']

    if (props.mode) {
        classes.push(`resources-block_${props.mode}`)
    }

    return classes
})
// icon - book, paper, video
const resources = [
    {
        icon: 'paper',
        link: {
            href: 'https://www.msp360.com/download/msp360-backup-datasheet.pdf',
            text: 'Managed Backup Product Datasheet'
        }
    },
    {
        icon: 'paper',
        link: {
            href: 'https://www.msp360.com/download/msp360-rmm-datasheet.pdf',
            text: 'RMM Product Datasheet'
        }
    },
    {
        icon: 'paper',
        link: {
            href: 'https://get.msp360.com/hubfs/Partners/Resellers/msp360-connect-simple-reliable.pdf',
            text: 'Connect Managed Product Datasheet'
        }
    }
]
</script>
<script>
export default {
    name: 'ResourcesBlock'
}
</script>

<template>
    <div :class="classes">
        <h2 class="subtitle subtitle_mb">Marketing Resources</h2>
        <BaseBlock class="resources-block__links">
            <BaseLink
                v-for="(resource, index) in resources"
                :key="index"
                class="resources-block__link"
                :link="resource.link"
            >
                <BaseIcon :i="resource.icon" />
                {{ resource.link.text }}
            </BaseLink>
        </BaseBlock>
    </div>
</template>

<style lang="scss" scoped>
.resources-block {
    display: flex;
    flex-direction: column;

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        flex: 1;
    }
    &__link {
        display: flex;
        align-items: center;
        &.link {
            font-size: 18px;
            line-height: 28px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        .icon {
            width: 24px;
            height: 25px;
            flex: 0 0 24px;
            margin-right: 8px;
        }
    }
}
</style>
