import {
    helpers,
    required,
    requiredIf,
    minLength,
    maxLength,
    minValue,
    sameAs
} from '@vuelidate/validators'
import { countries } from '@/utils/countries-phone-masks'

const validEmail = helpers.regex(
    /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
)

const validDomain = helpers.regex(
    /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/
)

const getActualMask = (masks, code, value) => {
    if (Array.isArray(masks)) {
        const actualIndex = masks.findIndex(
            (mask) =>
                mask.match(/[#0-9]/g).length + code.length === value.length
        )
        return masks[actualIndex === -1 ? 0 : actualIndex]
    } else {
        return masks
    }
}

const phoneHelper = helpers.withParams({ type: 'phone' }, (value) =>
    countries.some(({ code, mask }) => {
        // Check for country code
        return (
            (!(value || '').indexOf(code) &&
                // Check for the right length
                code.length +
                    getActualMask(mask, code, value).match(/[#0-9]/g).length ===
                    (value || '').length) ||
            !value
        )
    })
)

export default {
    validEmail: helpers.withMessage(
        'Please specify valid email address',
        validEmail
    ),
    validDomain: helpers.withMessage(
        'Please specify valid domain name',
        validDomain
    ),
    phone: helpers.withMessage(
        'Please specify valid phone number',
        phoneHelper
    ),
    emailExists: 'This email is already associated with an account.',
    validCompanyName:
        'Allowed symbols: letters (A-z), numbers (0-9), special symbols =+-_()[].,&',
    required: (attribute) => {
        return helpers.withMessage(`Please specify your ${attribute}`, required)
    },
    requiredIf: (attribute, condition) => {
        return helpers.withMessage(
            `Please specify your ${attribute}`,
            requiredIf(condition)
        )
    },
    minLength: (attribute, min) => {
        return helpers.withMessage(
            `The field ${attribute} must be longer than ${min}`,
            minLength(min)
        )
    },
    maxLength: (attribute, max) => {
        return helpers.withMessage(
            `The field ${attribute} might have no more than ${max} symbols`,
            maxLength(max)
        )
    },
    serverError: (text) => `${text}`,
    minValue: (attribute, min) => {
        return helpers.withMessage(
            `The field ${attribute} must be more than ${min}`,
            minValue(min)
        )
    },
    sameAs: (attribute, val) => {
        return helpers.withMessage(
            `The "${attribute}" do not match`,
            sameAs(val)
        )
    },
    externalServerValidation: () => true // заглушка
}
