import store from '@/store'
import { routeNames } from '@/constants/routes'
import {
    pagesOnlyForUnauthorizedUser,
    getAuthStatus,
    privatePagesList,
    authStatuses,
    errorResponse,
    authRequiredResponse
} from '@/utils/auth-helper'

export async function checkAuthMiddleware(to) {
    try {
        const res = await store.dispatch('fetchCurrentUser')
        const resStatus = res?.response?.status ?? res?.status
        const resUserRole = res.data?.role
        console.log('checkAuth res: ', res)

        const userAuthStatus = getAuthStatus(resStatus, resUserRole, to.path)
        console.log('userAuthStatus: ', userAuthStatus)

        if (privatePagesList.includes(to.path)) {
            return authRequiredResponse[userAuthStatus] || errorResponse
        }

        if (pagesOnlyForUnauthorizedUser.includes(to.path)) {
            if (authStatuses.UNAUTHORIZED === userAuthStatus) {
                return (to.path === '/account/signup' ||
                    to.path === '/account/reset-password') &&
                    (!to.query.email || !to.query.token)
                    ? { name: routeNames.forbidden }
                    : true
            }

            return { name: routeNames.dashboard }
        }

        return true
    } catch (error) {
        console.log('checkAuthMiddleware error: ', error)
        return errorResponse
    }
}
