<script setup>
import { computed } from 'vue'
import { VueFinalModal, useVfm } from 'vue-final-modal'

const vfm = useVfm()

const props = defineProps({
    modalId: { type: Symbol, required: true },
    withCloseBtn: { type: Boolean, default: true },
    widthContent: { type: Number, default: 388 },
    options: { type: Object, default: () => {} }
})

const classes = computed(() => {
    const classes = ['base-modal']

    if (props.withCloseBtn) {
        classes.push('base-modal_with-close')
    }

    return classes
})

// https://vue-final-modal.org/api/components/vue-final-modal#props
const options = computed(() => {
    return {
        modalId: props.modalId,
        contentClass: 'base-modal__content',
        hideOverlay: false,
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-slide-up',
        contentStyle: {
            width: `${props.widthContent}px`
        },
        ...props.options
    }
})
</script>
<script>
export default {
    name: 'BaseModal'
}
</script>

<template>
    <VueFinalModal :class="classes" v-bind="{ ...options }">
        <div
            v-if="withCloseBtn"
            class="base-modal__close"
            @click="() => vfm.close(modalId)"
        >
            <BaseIcon i="cross" />
        </div>
        <slot />
    </VueFinalModal>
</template>

<style lang="scss">
.base-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        border-radius: 20px;
        padding: 32px 60px;
        background-color: #fff;
        position: relative;
        margin: 0 10px;
    }
    &__close {
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 50% 50% 50% 0;
        position: absolute;
        right: -32px;
        top: -32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #f74e00;
        cursor: pointer;
        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 9px;
            height: 9px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url('@/assets/images/common/close-modal.svg');
        }
        &:before {
            bottom: -8px;
            left: 17px;
        }
        &:after {
            bottom: 17px;
            left: -8px;
            transform: rotate(180deg);
        }
    }
    &_with-close {
        .base-modal__content {
            border-radius: 20px 0px 20px 20px;
            margin: 0 42px 0 10px;
        }
    }
}
</style>
