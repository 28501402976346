<script setup>
import { computed } from 'vue'

const props = defineProps({
    size: { type: String, default: 'sm' }
})
const classes = computed(() => {
    const classes = ['spinner']

    classes.push(`spinner_size-${props.size}`)

    return classes
})
</script>
<script>
export default {
    name: 'LoadingSpinner'
}
</script>

<template>
    <div :class="classes" />
</template>

<style scoped lang="scss">
.spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: 0.1em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
    &_size-sm {
        width: 1rem;
        height: 1rem;
    }
    &_size-lg {
        width: 10rem;
        height: 10rem;
    }
}

@keyframes spinner-border {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
