export default {
    title: 'My Leads',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'My Leads description'
        }
    ]
}
