<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { getProcessedURL, getExternalErrors } from '@/utils/formatting'
import validation from '@/utils/validation'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const store = useStore()

const token = ref(route.query.token)
const email = ref(
    route.query.email ? getProcessedURL(route.query.email) : undefined
)

const form = ref({
    email: email.value ?? '',
    password: '',
    confirm: ''
})
const error = ref({
    isError: false,
    errorText: ''
})

const externalErrors = ref({})
const autoDirty = ref(false)

const formLabels = {
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm password'
}
const formPlaceholders = {
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm password'
}

const rules = computed(() => {
    return {
        email: {
            required: validation.required(formLabels.email),
            validEmail: validation.validEmail
        },
        password: {
            required: validation.required(formLabels.password),
            minLength: validation.minLength(formLabels.password, 8)
        },
        confirm: {
            required: validation.required(formLabels.confirm),
            sameAs: validation.sameAs('Passwords', form.value.password)
        }
    }
})

const handlerForm = async ({ confirm, ...form }) => {
    try {
        const preparedForm = {
            token: token.value,
            ...form
        }
        const res = await store.dispatch('signup', preparedForm)
        console.log('Signup form res: ', res)

        const commonErrorStatuses = [401, 403]
        if (res.data?.success) {
            router.push({ path: '/' })
        } else if (commonErrorStatuses.includes(res?.errInfo.status)) {
            const [firstError] = res.errInfo.errors
            error.value.isError = true
            error.value.errorText = firstError.msg
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true

            const errors = res.errInfo.errors
            externalErrors.value = getExternalErrors(errors)
            console.log('externalErrors.value: ', externalErrors.value)
        } else {
            throw new Error()
        }
    } catch (err) {
        console.log('Signup form error: ', err)
        error.value.isError = true
        error.value.errorText = 'Error occurred while registering'
    }
}
</script>

<script>
export default {
    name: 'SignupForm'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        :error="error.isError"
        :error-text="error.errorText"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        :on-submit="handlerForm"
        submit-text="Register"
    >
        <template #default="{ validator }">
            <BaseInput
                type="text"
                v-model="form.email"
                :validator="validator.email"
                :name="formLabels.email"
                :placeholder="formPlaceholders.email"
                :disabled="email"
                required
            />
            <BaseInput
                type="password"
                v-model="form.password"
                :validator="validator.password"
                :name="formLabels.password"
                :placeholder="formPlaceholders.password"
                required
            />
            <BaseInput
                type="password"
                v-model="form.confirm"
                :validator="validator.confirm"
                :name="formLabels.confirm"
                :placeholder="formPlaceholders.confirm"
                required
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
