<script setup>
import { useHead } from 'unhead'
import meta from '@/constants/meta/admin/resources'

useHead(meta)
</script>
<script>
export default {
    name: 'AdminResourcesPage'
}
</script>

<template>AdminResourcesPage</template>
