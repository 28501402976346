<script setup>
import { ref, computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import validation from '@/utils/validation'
import {
    SEARCH_MODE_STATIC,
    SEARCH_MODE_DYNAMIC,
    searchModes
} from '@/constants/baseSelect'
import LoadingSpinner from '@/components/common/LoadingSpinner'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    validator: { type: [Object, Boolean], default: false },
    placeholder: { type: String, default: '' },
    options: { type: Array, default: () => [] },
    optionsPlaceholder: {
        type: String,
        default: 'The list is empty'
    },
    modelValue: { type: [String, Array], default: '' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    multipleWithTags: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    searchMode: {
        type: String,
        default: 'static',
        validator(value) {
            return searchModes.includes(value)
        }
    },
    optionsLoading: { type: Boolean, default: false },
    searchResultText: { type: String, default: '' },
    searchPlaceholder: { type: String, default: 'Search' },
    intersection: { type: Boolean, default: false }
})

const emit = defineEmits([
    'update:modelValue',
    'intersection',
    'search',
    'clearSearch'
])

const isOpened = ref(false)

const searchText = ref('')
const minLengthSearchText = 2
const rules = computed(() => {
    return {
        searchText: {
            minLength: validation.minLength(
                props.searchPlaceholder,
                minLengthSearchText
            )
        }
    }
})
const $v = useVuelidate(rules, { searchText })

const optionRefs = ref(null)

watch(searchText, (newVal, oldVal) => {
    if (props.searchMode === SEARCH_MODE_DYNAMIC) {
        newVal.length >= minLengthSearchText && emit('search', newVal)

        !newVal && oldVal && emit('clearSearch')
        $v.value.searchText.$touch()
    }
})

const filteredOptions = computed(() => {
    return searchText.value && props.searchMode === SEARCH_MODE_STATIC
        ? props.options.filter((option) =>
              option.text.toLowerCase().includes(searchText.value.toLowerCase())
          )
        : props.options
})
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})
const text = computed(() => {
    if (props.multiple && value.value) {
        const textArray = []
        props.options.forEach((option) => {
            if (value.value.includes(option.value)) {
                textArray.push(option.text)
            }
        })
        return textArray?.join(', ')
    } else if (!props.multiple && value.value) {
        return props.options.find((option) => option.value === value.value)
            ?.text
    } else {
        return ''
    }
})
const tags = computed(() => {
    if (props.multiple && props.multipleWithTags && value.value) {
        return props.options.filter((option) => {
            return value.value.includes(option.value)
        })
    }

    return false
})
const errorSearchText = computed(
    () => $v.value.searchText?.$errors[0]?.$message
)

const errorText = computed(() => {
    return (
        (props.validator.$error && props.validator.$errors[0]?.$message) ||
        (props.validator.$externalResults &&
            props.validator.$externalResults[0]?.$message)
    )
})

const classes = computed(() => {
    const classes = ['select-block']

    if (props.mode) {
        classes.push(`select-block_${props.mode}`)
    }

    if (props.required) {
        classes.push(`select-block_required`)
    }
    if (errorText.value) {
        classes.push(`select-block_error`)
    }
    if (
        (props.multiple && props.modelValue.length) ||
        (!props.multiple && props.modelValue)
    ) {
        classes.push(`select-block_selected`)
    }
    if (props.disabled) {
        classes.push(`select-block_disabled`)
    }

    return classes
})

const shownOptionsPlaceholder = computed(() => {
    return (
        !filteredOptions.value.length &&
        !errorSearchText.value &&
        !props.optionsLoading &&
        !searchText.value &&
        !props.searchResultText
    )
})

const isSearchError = computed(() => {
    return (
        (searchText.value && props.searchResultText) ||
        (searchText.value &&
            props.searchMode === SEARCH_MODE_STATIC &&
            !filteredOptions.value.length)
    )
})

const close = () => {
    isOpened.value = false

    // if (searchText.value) {
    //     searchText.value = ''
    // }
}
const toggle = () => {
    if (!props.disabled) {
        isOpened.value = !isOpened.value
    }
}
const handlerOption = (option) => {
    if (!props.multiple) {
        emit('update:modelValue', option.value)
        close()
    } else {
        if (props.modelValue.includes(option.value)) {
            emit(
                'update:modelValue',
                props.modelValue.filter((value) => value !== option.value)
            )
        } else {
            emit('update:modelValue', [...props.modelValue, option.value])
        }
    }
}
const handlerFocus = (option) => {
    if (isOpened.value) {
        const lastIndex = filteredOptions.value.length - 1

        if (option.value === filteredOptions.value[lastIndex].value) {
            optionRefs.value[0].focus() // TODO not always the same order
        }
    }
}
const optionClasses = (option) => {
    const classes = ['select-block__item']
    if (
        value.value === option.value ||
        (props.multiple && value.value.includes(option.value))
    ) {
        classes.push('select-block__item_selected')
    }
    return classes
}
</script>

<script>
export default {
    name: 'BaseSelect'
}
</script>

<template>
    <div :class="classes" v-click-outside-capturing="close" v-bind="$attrs">
        <div class="select-block__wrap">
            <div
                class="select-block__header"
                :class="{ 'select-block__header_open': isOpened }"
                @click="toggle"
                @keyup.enter="toggle"
                @keyup.esc="close"
                tabindex="0"
                :title="text"
            >
                {{ text }}
            </div>
            <label>{{ placeholder }}</label>

            <span
                v-if="validator.$error || validator.$externalResults"
                class="select-block__helper"
            >
                {{ errorText }}
            </span>
        </div>
        <Transition>
            <div
                class="select-block__inner"
                :class="{ 'select-block__inner_hide': !isOpened }"
                v-if="isOpened"
            >
                <input
                    v-if="searchable"
                    type="text"
                    v-model="searchText"
                    :placeholder="searchPlaceholder"
                    class="select-block__search-box"
                />
                <div
                    v-if="errorSearchText"
                    class="select-block__items-placeholder"
                >
                    {{ errorSearchText }}
                </div>

                <div
                    class="select-block__items"
                    role="listbox"
                    v-if="filteredOptions.length"
                >
                    <div
                        v-for="(option, i) of filteredOptions"
                        :key="i"
                        @click="handlerOption(option)"
                        @keyup.enter="handlerOption(option)"
                        @blur="handlerFocus(option)"
                        @keyup.esc="close"
                        ref="optionRefs"
                        tabindex="0"
                        role="option"
                        :class="optionClasses(option)"
                    >
                        {{ option.text }}
                    </div>
                    <div
                        v-if="intersection"
                        v-intersection
                        class="select-block__intersection"
                    />
                </div>

                <div v-if="optionsLoading" class="select-block__loading">
                    <LoadingSpinner />
                </div>

                <div
                    v-else-if="isSearchError"
                    class="select-block__items-placeholder"
                >
                    {{ searchResultText || 'No matches' }}
                </div>
                <div
                    v-if="shownOptionsPlaceholder"
                    class="select-block__items-placeholder"
                >
                    {{ optionsPlaceholder }}
                </div>
            </div>
        </Transition>
    </div>
    <div v-if="tags" class="select-block__tags">
        <div
            class="select-block__tag"
            v-for="(tag, index) in tags"
            :key="index"
        >
            <button class="select-block__tag-cross" @click="handlerOption(tag)">
                <BaseIcon i="cross" />
            </button>
            {{ tag.text }}
        </div>
    </div>
</template>

<style scoped lang="scss">
.select-block {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    &__wrap {
        position: relative;
        z-index: 2;
        padding: 8px 0;
    }
    &__header {
        // display: flex;
        border-radius: 32px;
        padding: 10px 46px 10px 20px;
        user-select: none;
        background-image: url('@/assets/images/common/select-arrow.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 24px top 50%, 0 0;
        background-size: 16px 10px;
        transition: 0.2s;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 46px;
        outline: none;
        position: relative;
        z-index: 1;
        &_open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    label {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
        z-index: -1;
        transition: 0.2s;
    }

    .select-block__header:focus ~ label,
    .select-block__header_open ~ label,
    &_selected .select-block__header ~ label {
        padding: 0 2px;
        top: 9px;
        z-index: 1;
        font-size: 12px;
        line-height: 16px;
    }

    &__helper {
        position: absolute;
        top: 100%;
        transform: translate(0, calc(-50% - 10px));
        left: 20px;
        font-size: 12px;
        line-height: 16px;
        padding: 0 2px;
        z-index: 1;
    }
    &__search-box {
        border: none;
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 45px;
        background: #f4f5fb;
        padding: 6px 30px 6px 12px;
        width: 100%;
        outline: none;
        background-image: url('@/assets/images/common/search.svg');
        background-repeat: no-repeat;
        background-position: right 12px top 50%;
        &::placeholder {
            color: #8aa0c6;
        }
        &:focus,
        &:hover {
            outline: 1px solid #8aa0c6;
        }
    }

    &__inner {
        position: absolute;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-style: solid;
        border-width: 1px;
        border-top: none;
        top: calc(100% - 16px);
        left: 0;
        right: 0;
        z-index: 3;
        overflow: hidden;
        padding: 10px 18px;
        // &_hide {
        //     display: none;
        // }
    }
    &__items {
        max-height: 200px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #eeeeee;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #bbbbbb;
            border-radius: 10px;
            &:hover {
                background-color: #aaaaaa;
            }
        }
    }
    &__item {
        padding: 8px 0px;
        cursor: pointer;
        user-select: none;
        transition: 0.3s;
        display: flex;
        outline: none;
    }
    &__items-placeholder {
        padding: 16px 0;
    }
    &__intersection {
        height: 5px; // TODO testing, probably need to increase the height
    }
    &__loading {
        text-align: center;
        padding-bottom: 10px;
    }
    &__tags {
        margin-top: 12px;
        max-height: 110px;
        overflow-x: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__tag {
        display: inline-flex;
        padding: 1px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 32px;
        border: 1px solid #7eaaff;
        background: rgba(126, 170, 255, 0.15);
        color: #7eaaff;
        font-family: 'ReadexPro', sans-serif;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.312px;
    }
    &__tag-cross {
        display: flex;
        border: none;
        outline: none;
        background: transparent;
        padding: 0;
        color: #7eaaff;
        .icon {
            width: 9px;
            height: 9px;
        }
        &:hover,
        &:focus {
            color: #2f76ff;
        }
    }

    &_light {
        color: $secondary-field-text-color;

        .select-block__header {
            border: 1px solid $primary-border-color;
            color: $primary-field-text-color;
        }
        &:not(.select-block_disabled) {
            .select-block__header {
                &:hover:not(.select-block__header_open),
                &:active:not(.select-block__header_open) {
                    border: 1px solid $hover-border-color;
                }

                &_open,
                &:focus {
                    border: 1px solid $focus-border-color;
                }
            }
        }
        &.select-block_error {
            .select-block__header {
                border: 1px solid $warning-border-color;
            }
            .select-block__inner {
                border-color: $warning-border-color;
            }
        }
        label {
            background-color: $primary-label-bg;
            color: $secondary-field-text-color;
        }
        .select-block__inner {
            border-color: $focus-border-color;
            background-color: $primary-options-bg;
        }
        .select-block__item {
            &_selected,
            &:hover,
            &:focus {
                // background-color: $primary-active-option-bg;
                color: $mbs-color;
            }
        }
        .select-block__helper {
            background-color: $primary-label-bg;
            color: $warning-text-color;
        }
        &.select-block_disabled {
            .select-block__header {
                color: #b5b5b5;
            }
        }
    }

    &_required {
        label {
            &:after {
                content: '*';
                color: $warning-text-color;
                padding-left: 5px;
            }
        }
    }
    &_disabled {
        cursor: not-allowed;
        .select-block__header {
            background-image: none;
        }
    }
    .v-enter-active,
    .v-leave-active {
        transition: all 0.1s ease-in-out;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
        transform: translate(0, -20px);
    }
}
</style>
