<script setup>
import { computed, ref } from 'vue'
const props = defineProps({
    theme: {
        type: String,
        default: 'default',
        validator(value) {
            return ['default', 'orange', 'green', 'blue'].includes(value)
        }
    },
    iconProduct: { type: [Boolean, String], default: false },
    text: { type: String, required: true },
    link: { type: [Boolean, Object], default: false },
    tooltip: { type: [Boolean, Object], default: false },
    points: { type: [Boolean, Array], default: false }
})

const isOpened = ref(false)

const classes = computed(() => {
    const classes = ['product-block']

    classes.push(`product-block_${props.theme}`)

    if (isOpened.value) {
        classes.push('product-block_opened')
    }
    return classes
})
</script>

<script>
export default {
    name: 'CalculatorProductBlock'
}
</script>

<template>
    <div :class="classes">
        <div class="product-block__header" @click="isOpened = !isOpened">
            <BaseIcon
                v-if="iconProduct"
                :i="iconProduct"
                class="product-block__icon"
            />
            <div class="product-block__content">
                <span class="product-block__text">{{ text }}</span>
                <BaseTooltip v-if="tooltip" v-bind="{ ...tooltip }" />
                <BaseLink
                    v-if="link"
                    class="product-block__link"
                    :link="link"
                />
            </div>
        </div>
        <div class="product-block__body">
            <BaseList v-if="points" :list="points" />
            <slot />
        </div>
    </div>
</template>

<style scoped lang="scss">
.product-block {
    border-radius: 20px;
    position: relative;
    padding: 24px;

    @include media_md {
        padding: 0 12px;
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;

        @include media_md {
            min-height: 62px;
            width: calc(100% - 20px);
            padding: 8px 0;

            &:before {
                content: '';
                position: absolute;
                right: -20px;
                top: 50%;
                width: 16px;
                height: 16px;
                transform: translate(0, -50%);
                background-image: url('@/assets/images/common/calculator-bullet.svg');
                background-repeat: no-repeat;
                background-size: contain;
                transition: 0.2s ease-out;
            }
        }
    }
    .icon.product-block__icon {
        width: 48px;
        height: 48px;
        flex: 0 0 48px;
        margin-right: 12px;
        @include media_md {
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
            margin-right: 4px;
        }
    }

    &__link {
        font-family: Roboto, sans-serif;
        display: block;
        width: fit-content;
        font-style: italic;
        font-size: 12px;
        line-height: 14px;
    }

    &__text {
        font-size: 20px;
        line-height: 28px;
        margin-right: 4px;

        @include media_md {
            font-size: 16px;
            line-height: 24px;
        }
    }
    &__body {
        padding-top: 20px;

        @include media_md {
            display: none;
            padding: 16px 0;
        }
    }
    &_default {
        background: $calc-product-default-bg;
        border: 1px solid $calc-product-default-border-color;
    }
    &_orange {
        background: $calc-product-primary-bg;
        border: 1px solid $calc-product-primary-border-color;
    }
    &_green {
        background: $calc-product-secondary-bg;
        border: 1px solid $calc-product-secondary-border-color;
    }
    &_blue {
        background: $calc-product-tertiary-bg;
        border: 1px solid $calc-product-tertiary-border-color;
    }
    &_opened {
        .product-block__body {
            display: block;
        }
        .product-block__header {
            &:before {
                transform: translate(0, -50%) rotate(180deg);
            }
        }
    }
    &:deep(.base-list) {
        font-size: 14px;
        line-height: 20px;
        padding-left: 12px;

        @include media_md {
            font-size: 10px;
            line-height: 12px;
        }
    }
    &:deep(.base-list__item) {
        margin-bottom: 0;
        &:before {
            top: 50%;
            left: -12px;
            transform: translate(0, -50%);
            width: 6px;
            height: 7px;

            @include media_md {
                top: 2px;
                transform: translate(0);
            }
        }
    }
}
</style>
