export const accountType = {
    MSP: 'MSP',
    Business: 'Business',
    Reseller: 'Reseller',
    Distributor: 'Distributor',
    PersonalUse: 'Personal Use',
    NonProfit: 'Non Profit',
    Partner: 'Partner',
    Integrator: 'Integrator',
    Other: 'Other',
    Hybrid: 'Hybrid'
}
