<script setup>
import { computed } from 'vue'

const props = defineProps({
    options: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    name: { type: String, required: true },
    value: { type: String, default: '' },
    modelValue: { type: String, default: '' }
})

const emit = defineEmits(['update:modelValue'])

const valueBox = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})
const errorText = computed(() => '')
</script>

<script>
export default {
    name: 'BaseRadioBox'
}
</script>

<template>
    <div>
        <div v-if="label">
            {{ label }}
        </div>

        <div>
            <div v-for="(option, index) in options" :key="index">
                <BaseRadio
                    :id="`${name}-${index}`"
                    :label="option.text"
                    :name="name"
                    :value="option.value"
                    :modelValue="option.value === modelValue"
                    v-model:value="valueBox"
                />
            </div>
        </div>

        <span v-if="errorText">
            {{ errorText }}
        </span>
    </div>
</template>
