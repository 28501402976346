<script setup>
import '/node_modules/flag-icons/css/flag-icons.min.css'
import { computed } from 'vue'
import { countryList } from '@/utils/countries-states'
console.log('countryList: ', countryList)

const props = defineProps({
    country: { type: String, default: '' },
    iso: { type: String, default: '' },
    squared: { type: Boolean, default: false }
})
const preparedISO = computed(() => {
    return props.country
        ? countryList.getCode(props.country).toLowerCase()
        : props.iso
        ? props.iso.toLowerCase()
        : false
})
const classes = computed(() => {
    const classes = ['fi']

    preparedISO.value && classes.push(`fi-${preparedISO.value}`)

    props.squared && classes.push('fis')

    return classes
})
</script>
<script>
export default {
    name: 'FlagIcon'
}
</script>

<template>
    <span v-if="preparedISO" :class="classes" />
</template>

<style lang="scss" scoped>
.fi {
    min-width: 20px;
    width: 20px;
    line-height: 0;
}
</style>
