<script setup>
import { useHead } from 'unhead'
import dealRegistrationMeta from '@/constants/meta/deal-registration'
import DealRegistrationForm from '@/components/forms/DealRegistrationForm'
useHead(dealRegistrationMeta)
</script>

<script>
export default {
    name: 'DealRegistrationPage'
}
</script>

<template>
    <div class="registration-page">
        <h1 class="subtitle subtitle_mb">Deal Registration</h1>
        <BaseImage
            class="registration-page__image"
            image="deal-registration/deal-bg"
            width="960"
            height="813"
            alt="Guy registers a deal"
        />
        <DealRegistrationForm class="registration-page__form" />
    </div>
</template>

<style lang="scss" scoped>
.registration-page {
    position: relative;
    flex: 1;
    &__form {
        max-width: 520px;
        position: relative;
    }
    &__image {
        position: absolute;
        bottom: -44px;
        right: -96px;

        @include media_max_xl {
            right: -52px;
            width: 69%;
        }
    }
}
</style>
