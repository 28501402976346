<script setup>
import { useHead } from 'unhead'
import leadsMeta from '@/constants/meta/leads'
import { newDealLink } from '@/constants/content'
import { leadsKeys } from '@/constants/content/leads'
import { filterActiveStatuses } from '@/constants/leadStatuses'
import LeadsBlock from '@/components/blocks/LeadsBlock'
import { useStore } from 'vuex'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import useEventsBus from '@/composables/useEventsBus'
import useFetchData from '@/composables/useFetchData'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import { roles } from '@/constants/rbac/roles'

useHead(leadsMeta)
const store = useStore()
const { bus } = useEventsBus()

const statusFilter = ref([])

const leadsLoading = computed(() => store.getters.leadsLoading)
const leads = computed(() => store.getters.leads)
const totalCount = computed(() => store.getters.amountActiveLeads)
const currentCount = computed(() => leads.value.length)
const substitutePartnerID = computed(() => store.getters.substitutePartnerID)
const message = computed(() => {
    if (!currentCount.value && !statusFilter.value.length) {
        return 'Here will be list of active leads'
    }
    if (!currentCount.value && statusFilter.value.length) {
        return "Sorry, we can't find leads on your request, try to change filter criteria"
    }
    return ''
})
const currentUser = computed(() => store.getters.currentUser)
const isValidCurrentRole = computed(() => {
    return store.getters.isValidCurrentRole([roles.ADMIN, roles.VIEWER])
})

const basicRequest = (params) => {
    const localParams = {
        ...params,
        archive: false
    }

    if (!params?.assignedPartner && isValidCurrentRole.value) {
        localParams.assignedPartner = currentUser.value.assignedPartner
    }

    if (statusFilter.value.length) {
        localParams.status = statusFilter.value
    }

    store.dispatch('getLeads', localParams)
}
const resetStore = () => {
    store.commit('RESET_ACTIVE_LEADS')
    store.commit('SET_AMOUNT_ACTIVE_LEADS', 0)
}

watch(
    () => bus.value.get('onStatusFilterApply'),
    (val) => {
        const [form] = val
        statusFilter.value = form.filter
        resetStore()
        initData()
    }
)
watch(
    () => bus.value.get('onStatusFilterReset'),
    () => {
        statusFilter.value = []
        resetStore()
        initData()
    }
)

const [initData, fetchDataOnIntersection] = useFetchData(
    totalCount,
    currentCount,
    substitutePartnerID,
    basicRequest
)

initData()

onBeforeUnmount(() => {
    if (statusFilter.value.length) {
        resetStore()
    }
})
</script>

<script>
export default {
    name: 'LeadsPage'
}
</script>

<template>
    <div class="leads-page">
        <LeadsBlock
            v-if="checkHasPermissions([permissionsEnum.READ_LEAD])"
            title="Leads List"
            :leads="leads"
            :button="newDealLink"
            :keys="leadsKeys"
            :filter-status-options="filterActiveStatuses"
            :loading="leadsLoading"
            intersection
            @intersection="fetchDataOnIntersection"
            :message="message"
        />
    </div>
</template>
