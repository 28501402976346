<script setup>
import { useHead } from 'unhead'
import dealsMeta from '@/constants/meta/deals'
import DealsBlock from '@/components/blocks/DealsBlock'
import DealsList from '@/components/blocks/DealsList'
import { useStore } from 'vuex'
import { computed } from 'vue'
import useFetchData from '@/composables/useFetchData'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'

useHead(dealsMeta)

const store = useStore()

const deals = computed(() => store.getters.deals)
const dealsCurrentCount = computed(() => store.getters.dealsCurrentCount)
const dealsTotalCount = computed(() => store.getters.dealsTotalCount)
const dealsLimit = computed(() => store.getters.dealsLimit)
const isEmptyDealsList = computed(() => store.getters.isEmptyDealsList)
const dealsLoading = computed(() => store.getters.dealsLoading)
const substitutePartnerID = computed(() => store.getters.substitutePartnerID)

const basicRequest = (params) => {
    store.dispatch('getDeals', { ...params, limit: dealsLimit.value })
}

const [initData, fetchDataOnIntersection] = useFetchData(
    dealsTotalCount,
    dealsCurrentCount,
    substitutePartnerID,
    basicRequest
)

initData()
</script>

<script>
export default {
    name: 'DealsPage'
}
</script>
<template>
    <div class="deals-page">
        <h1 class="subtitle subtitle_mb">Deal List</h1>
        <template v-if="checkHasPermissions([permissionsEnum.READ_DEAD])">
            <DealsBlock class="deals-page__statistics" />
            <DealsList
                :list="deals"
                :is-empty-list="isEmptyDealsList"
                intersection
                @intersection="fetchDataOnIntersection"
                :loading="dealsLoading"
            />
        </template>
    </div>
</template>

<style lang="scss" scoped>
.deals-page {
    &__statistics {
        max-width: 900px;
        margin-bottom: 56px;
    }
}
</style>
