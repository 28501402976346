export default {
    title: 'Sign Up to the MSP360 Partner Portal',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'Type and confirm a secure password for the account'
        }
    ]
}
