<script setup>
import { useHead } from 'unhead'
import partnersMeta from '@/constants/meta/account/partners'
import UserSettingsBlock from '@/components/blocks/UserSettingsBlock.vue'
import PartnerRegistrationForm from '@/components/forms/PartnerRegistrationForm'
import { registrationMode } from '@/constants/partnerRegistration'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useVfm } from 'vue-final-modal'
import { roles } from '@/constants/rbac/roles'
import useFetchData from '@/composables/useFetchData'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'

useHead(partnersMeta)
const store = useStore()
const vfm = useVfm()

const addPartnerPopup = Symbol('addPartnerPopup')

const users = computed(() => store.getters.users)
const usersLoading = computed(() => store.getters.usersLoading)
const usersTotalCount = computed(() => store.getters.usersTotalCount)
const usersCurrentCount = computed(() => users.value.length)
const substitutePartnerID = computed(() => store.getters.substitutePartnerID)

const preparedUsers = computed(() => {
    if (!substitutePartnerID.value) {
        const currentUser = { ...store.getters.currentUser, pivotal: true }
        const preparedUsers = users.value.filter(
            (user) => user._id !== currentUser._id
        )
        return [currentUser, ...preparedUsers]
    }

    return users.value
})

const isManager = computed(() => store.getters.userRole === roles.MANAGER)

const basicRequest = (params) => {
    console.log('basicRequest: ', params)
    store.dispatch('fetchUsers', params)
}

const [initData, fetchDataOnIntersection] = useFetchData(
    usersTotalCount,
    usersCurrentCount,
    substitutePartnerID,
    basicRequest
)

initData()

const userName = ref('')
const userEmail = ref('')
const successPopup = Symbol('successPartnerPopup')

const onSuccess = ({ email, name }) => {
    userName.value = name
    userEmail.value = email
    vfm.close(addPartnerPopup)
    vfm.open(successPopup)
}

const addNextPartner = () => {
    vfm.close(successPopup)
    vfm.open(addPartnerPopup)
}
</script>

<script>
export default {
    name: 'UserSettingsPage'
}
</script>

<template>
    <div class="user-settings">
        <h1 class="subtitle subtitle_mb">My Partners</h1>
        <div
            v-if="
                isManager && checkHasPermissions([permissionsEnum.CREATE_USER])
            "
            class="user-settings__panel"
        >
            <BaseButton @click="() => vfm.open(addPartnerPopup)">
                Add New
            </BaseButton>
        </div>
        <UserSettingsBlock
            v-if="checkHasPermissions([permissionsEnum.READ_USER])"
            :users="preparedUsers"
            :loading="usersLoading"
            intersection
            @intersection="fetchDataOnIntersection"
        />
    </div>
    <BaseModal
        :modal-id="addPartnerPopup"
        :with-close-btn="false"
        :width-content="702"
    >
        <div class="user-settings__modal">
            <h2 class="lg-title">Add Sales Rep</h2>
            <p class="text text_center">
                Please provide the information below to add a new Sales Rep.
            </p>
            <PartnerRegistrationForm
                :mode="registrationMode.MANAGER"
                submit-text="Add"
                @success="onSuccess"
                class="user-settings__modal--form"
            />
        </div>
    </BaseModal>
    <BaseModal
        :modal-id="successPopup"
        :with-close-btn="false"
        :width-content="702"
    >
        <div class="user-settings__modal">
            <p class="text text_center">
                <span class="user-settings__accent">{{ userName }}</span>
                has been successfully granted access as a
                <b>Sales Representative</b>
                to the Partner Portal.
            </p>
            <p class="text text_center">
                An email has been sent to
                <span class="user-settings__accent">
                    {{ userEmail }}
                </span>
                with further instructions to complete the registration process.
            </p>
            <p class="text text_center">
                <BaseButton @click="addNextPartner">
                    Add Next Partner
                </BaseButton>
            </p>
        </div>
    </BaseModal>
</template>
<style lang="scss" scoped>
.user-settings {
    &__panel {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }

    &__modal {
        padding: 64px 0 100px;
        @include media_lg {
            padding: 32px;
        }
        .text {
            margin-bottom: 24px;
        }
        &--form {
            padding: 0 100px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            &:deep(.button) {
                margin-top: 16px;
                align-self: center;
            }
        }
    }
    &__accent {
        color: #179c2d;
    }
}
</style>
