<script setup>
import { computed, ref, watch } from 'vue'
import { productsAPI } from '@/api/products-api'
import { formPlaceholders } from '@/constants/quote-calculator-new'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import validation from '@/utils/validation'
import { getExternalErrors } from '@/utils/formatting'

const props = defineProps({
    mainProducts: { type: Array, default: () => [] },
    additionalProducts: { type: Array, default: () => [] }
})
const emit = defineEmits(['resetForm'])

const externalErrors = ref({})
const autoDirty = ref(false)

const form = ref({
    products: [],
    comment: ''
})
const formRules = computed(() => {
    return {
        products: {
            required: validation.required(formPlaceholders.products)
        },
        comment: {
            externalServerValidation: validation.externalServerValidation
        }
    }
})
watch(
    () => props.mainProducts,
    (newMainProducts) => {
        form.value.products = newMainProducts
    }
)

const handlerForm = async (form) => {
    const preparedForm = {
        ...form,
        additionalServices: props.additionalProducts
    }

    try {
        const res = await productsAPI.submitQuote(preparedForm)
        console.log('submitQuote res!: ', res)
        if (res.data) {
            pushNotification({
                message:
                    'Thank you for submitting the quote. An email has been <span>successfully</span> sent',
                type: notificationTypes.SUCCESS
            })
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true
            externalErrors.value = getExternalErrors(res.errInfo.errors)
            console.log('externalErrors.value: ', externalErrors.value)
        } else if (!res?.errInfo?.isError) {
            throw new Error('Unknown error in submitting the quote')
        }
    } catch (err) {
        pushNotification({
            message: `<span>${err.name}</span>: ${err.message}`,
            type: notificationTypes.ERROR
        })
    }
}

const resetForm = (initialForm) => {
    Object.assign(form.value, initialForm)
    emit('resetForm')
}
</script>

<script>
export default {
    name: 'QuoteCalculatorFormNew'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="formRules"
        submit-text="Sent"
        :on-submit="handlerForm"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        reset-form
        :on-reset="resetForm"
        class="calculator"
    >
        <template #default="{ validator }">
            <div class="calculator__body">
                <slot name="default" />
                <div class="calculator__info">
                    <h3 class="calculator__info--title">
                        Additional information
                    </h3>
                    <BaseInput
                        v-model="form.comment"
                        :validator="validator.comment"
                        :placeholder="formPlaceholders.comment"
                        :name="formPlaceholders.comment"
                        theme="blue"
                        type="textarea"
                        class="calculator__info--textarea"
                    />
                </div>
            </div>
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <div class="calculator__sidebar">
                <slot name="summary" />

                <h4 class="calculator__subtitle">Send quote by email</h4>
                <BaseButton
                    v-if="checkHasPermissions([permissionsEnum.CREATE_QUOTE])"
                    :type="invalid ? 'button' : 'submit'"
                    :loading="loading"
                    :disabled="invalid"
                    size="sm"
                    @click="invalid ? validator.$touch() : null"
                >
                    {{ submitText }}
                </BaseButton>

                <div
                    v-if="
                        validator.products.$error ||
                        validator.products.$externalResults
                    "
                    class="calculator__error"
                >
                    {{
                        validator.products.$errors[0]?.$message ||
                        validator.products.$externalResults[0]?.$message
                    }}
                </div>
                <slot name="footer" />
            </div>
        </template>
    </BaseForm>
</template>

<style lang="scss" scoped>
.calculator {
    display: grid;
    grid-template-columns: 3.6fr 1fr;

    @include media_max_xl {
        grid-template-columns: 2.8fr 1fr;
    }

    &__body {
        display: grid;
        gap: 20px;
    }

    &__info {
        margin-bottom: 20px;
        &--title {
            font-size: 20px;
            line-height: 28px;
            color: $default-text-color;
            margin-bottom: 8px;
        }
        &--textarea {
            padding-bottom: 0;
            &:deep(textarea) {
                min-height: 106px;
            }
        }
    }

    &__sidebar {
        padding: 20px 0 20px 20px;
    }
    &__subtitle {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: $summary-block-subtitle-color;
        margin-bottom: 12px;
    }

    &__error {
        color: $warning-text-color;
        margin-top: 8px;
    }
}
</style>
