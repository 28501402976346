<script setup>
import { computed, ref } from 'vue'
import {
    alias,
    productsWithoutDiscount
} from '@/constants/quote-calculator-new'
import {
    getInitData,
    calculatorBlocks,
    calculatorAdditionalBlock
} from '@/constants/content/quote-calculator-new'
import { getDiscount } from '@/utils/common'

import CalculatorMainProducts from '@/components/blocks/CalculatorMainProducts'
import CalculatorAdditionalProducts from '@/components/blocks/CalculatorAdditionalProducts'
import QuoteCalculatorFormNew from '@/components/forms/QuoteCalculatorFormNew'
import CalculatorSummaryNew from '@/components/blocks/CalculatorSummaryNew'
import CalculatorLevel from '@/components/blocks/CalculatorLevel'

const props = defineProps({
    products: { type: Array, required: true },
    tier: { type: String, default: '' },
    discount: { type: Number, default: 0 }
})

const chosenProducts = ref(getInitData())

/* methods */
const findProduct = (alias, os = '') => {
    const { prices, ...rest } = props.products.find((p) => {
        return os ? p.alias === alias && p.os === os : p.alias === alias
    })

    const price = prices[0].price
    const isDiscount = productsWithoutDiscount.includes(alias)
    const discountPrice = getDiscount(price, isDiscount ? 0 : props.discount)
    return {
        ...rest,
        price,
        discountPrice
    }
}
const sum = (arr) => arr.reduce((sum, d) => (sum += +d), 0)
const onResetForm = () => (chosenProducts.value = getInitData())
/* methods */

/* computed */
const preparedMainBlocks = computed(() => {
    return calculatorBlocks.map((block) => {
        const body = block.body.map((subgroup) => {
            const products = subgroup.products.map((alias) => ({
                ...findProduct(alias, subgroup.os)
            }))

            return { ...subgroup, products }
        })

        return { ...block, body }
    })
})

const preparedAdditionalBlock = computed(() => {
    const { products, ...rest } = calculatorAdditionalBlock

    return {
        ...rest,
        products: products.map((locAlias) => {
            return { ...findProduct(locAlias, 'additional') }
        })
    }
})
const preparedProducts = computed(() => {
    const additionalAliases = [
        alias.REBRANDING,
        alias.SUPPORT,
        alias.ONBOARDING
    ]

    return Object.entries(chosenProducts.value).reduce(
        (acc, [locAlias, amountsByOS]) => {
            if (additionalAliases.includes(locAlias)) {
                const amount =
                    locAlias === alias.REBRANDING
                        ? +amountsByOS.additional
                        : amountsByOS.additional
                        ? 1
                        : 0

                if (amount) {
                    acc.additional.push({
                        ...findProduct(locAlias),
                        amount
                    })
                }
            } else {
                const amount = sum(Object.values(amountsByOS))
                if (amount) {
                    acc.main.push({ ...findProduct(locAlias), amount })
                }
            }

            return acc
        },
        { main: [], additional: [] }
    )
})
/* computed */
</script>

<script>
export default {
    name: 'CalculatorMainBlock'
}
</script>
<template>
    <QuoteCalculatorFormNew
        :main-products="preparedProducts.main"
        :additional-products="preparedProducts.additional"
        @resetForm="onResetForm"
    >
        <template #default>
            <CalculatorMainProducts
                :main-blocks="preparedMainBlocks"
                v-model:chosen-products="chosenProducts"
            />
            <CalculatorAdditionalProducts
                :additional-block="preparedAdditionalBlock"
                v-model:chosen-products="chosenProducts"
            />
        </template>
        <template #summary>
            <CalculatorSummaryNew
                :product-list="preparedProducts.main"
                :additional-services="preparedProducts.additional"
            />
        </template>
        <template #footer>
            <CalculatorLevel :tier="tier" :discount="discount" />
        </template>
    </QuoteCalculatorFormNew>
</template>
