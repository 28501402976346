<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import validation from '@/utils/validation'
import { partnerRoleOptions } from '@/constants/partnerRegistration'
import { roles } from '@/constants/rbac/roles'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { getExternalErrors } from '@/utils/formatting'
import { SEARCH_MODE_DYNAMIC } from '@/constants/baseSelect'

const props = defineProps({
    submitText: { type: String, default: '' },
    partners: { type: Array, default: () => [] },
    searchPartnersResultText: { type: String, default: '' },
    partnerOptionsLoading: { type: Boolean, default: false }
})

const emit = defineEmits([
    'intersection',
    'success',
    'searchPartners',
    'clearSearchPartners'
])

const store = useStore()

const externalErrors = ref({})
const autoDirty = ref(false)

const getInitForm = () => {
    return {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        assignedPartner: ''
    }
}
const form = ref(getInitForm())

const formLabels = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    role: 'Role',
    assignedPartner: 'Partner'
}
const formPlaceholders = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    role: 'Role',
    assignedPartner: 'Partner'
}

const error = ref({
    isError: false,
    errorText: ''
})

const rules = computed(() => {
    return {
        firstName: {
            required: validation.required(formLabels.firstName)
        },
        lastName: {
            required: validation.required(formLabels.lastName)
        },
        email: {
            required: validation.required(formLabels.email),
            validEmail: validation.validEmail
        },
        role: {
            required: validation.required(formLabels.role)
        },
        assignedPartner: {
            required: isRequiredAssignedPartner.value
                ? validation.required(formLabels.assignedPartner)
                : false
        }
    }
})

const preparedPartners = computed(() => {
    const resetOption = {
        text: 'Please choose an option',
        value: ''
    }
    return isRequiredAssignedPartner.value
        ? props.partners
        : [resetOption, ...props.partners]
})

const isRequiredAssignedPartner = computed(() => {
    return (
        form.value.role === roles.USER.toLowerCase() ||
        form.value.role === roles.MANAGER.toLowerCase()
    )
})

const handlerForm = async ({ firstName, lastName, ...form }) => {
    const preparedForm = {
        name: `${firstName} ${lastName}`,
        ...form
    }

    console.log('preparedForm: ', preparedForm)
    try {
        const res = await store.dispatch('admin/registerUser', preparedForm)

        if (res.data) {
            emit('success', preparedForm)
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true

            const errors = res.errInfo.errors
            // because name is firstName + lastName in the form
            const preparedExtErrors = getExternalErrors(errors)
            if (preparedExtErrors.name) {
                const nameMsg = preparedExtErrors.name
                delete preparedExtErrors.name
                preparedExtErrors.firstName = nameMsg
                preparedExtErrors.lastName = nameMsg
            }
            externalErrors.value = preparedExtErrors
        } else {
            throw new Error()
        }
    } catch (err) {
        error.value.isError = true
        error.value.errorText = 'An error occurred while adding a new user'

        pushNotification({
            message: `An error occurred while adding a new user`,
            type: notificationTypes.ERROR
        })
    }
}

const resetForm = (initialForm) => {
    form.value = getInitForm()
}

const onIntersection = () => emit('intersection')
const onSearchPartners = (searchText) => emit('searchPartners', searchText)
const onClearSearchPartners = () => emit('clearSearchPartners')
</script>

<script>
export default {
    name: 'UserRegistrationFormForAdmin'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        :error="error.isError"
        :error-text="error.errorText"
        :on-submit="handlerForm"
        :submit-text="submitText"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        reset-form
        :on-reset="resetForm"
    >
        <template #default="{ validator }">
            <BaseInput
                type="text"
                v-model.trim="form.firstName"
                :validator="validator.firstName"
                :name="formLabels.firstName"
                :placeholder="formPlaceholders.firstName"
                required
            />
            <BaseInput
                type="text"
                v-model.trim="form.lastName"
                :validator="validator.lastName"
                :name="formLabels.lastName"
                :placeholder="formPlaceholders.lastName"
                required
            />
            <BaseInput
                type="text"
                v-model="form.email"
                :validator="validator.email"
                :name="formLabels.email"
                :placeholder="formPlaceholders.email"
                required
            />
            <BaseSelect
                :options="partnerRoleOptions"
                v-model="form.role"
                :validator="validator.role"
                :name="formLabels.role"
                :placeholder="formPlaceholders.role"
                required
            />
            <BaseSelect
                :options="preparedPartners"
                v-model="form.assignedPartner"
                :validator="validator.assignedPartner"
                :name="formLabels.assignedPartner"
                :placeholder="formPlaceholders.assignedPartner"
                intersection
                @intersection="onIntersection"
                :options-loading="partnerOptionsLoading"
                :required="isRequiredAssignedPartner"
                @search="onSearchPartners"
                @clearSearch="onClearSearchPartners"
                :search-result-text="searchPartnersResultText"
                :search-mode="SEARCH_MODE_DYNAMIC"
                searchable
                options-placeholder="Start typing Partner's name"
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
