<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import validation from '@/utils/validation'

const store = useStore()
const router = useRouter()

const props = defineProps({
    signinForm: { type: Object, required: true }
})

const form = ref({ token: '' })

const success = ref({
    isSuccess: false,
    successText: 'Please wait for redirection to home page'
})

const error = ref({
    isError: false,
    errorText: ''
})

const formLabels = { token: 'Verification code' }

const formPlaceholders = { token: 'Verification code' }

const rules = computed(() => {
    return {
        token: {
            required: validation.required(formLabels.token)
        }
    }
})

const handlerForm = async ({ token }) => {
    try {
        const preparedForm = {
            ...props.signinForm,
            token
        }
        console.log('preparedForm: ', preparedForm)

        const res = await store.dispatch('signin', preparedForm)
        console.log('Verification res: ', res)

        if (res.status === 200 && res.data?.success) {
            success.value.isSuccess = true
            router.push({ path: '/' })
        } else if (res?.errInfo.status === 401) {
            const [firstError] = res.errInfo.errors
            error.value.isError = true
            error.value.errorText = firstError.msg
        } else if (!res?.errInfo?.isError) {
            throw new Error()
        }
    } catch (err) {
        error.value.isError = true
        error.value.errorText = 'Verification failed'
    }
}
</script>

<script>
export default {
    name: 'VerificationFormForSignin'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        :error="error.isError"
        :error-text="error.errorText"
        :success="success.isSuccess"
        :success-text="success.successText"
        :on-submit="handlerForm"
    >
        <template #default="{ validator }">
            <BaseInput
                type="text"
                v-model="form.token"
                :validator="validator.token"
                :name="formLabels.token"
                :placeholder="formPlaceholders.token"
                required
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
