<script setup>
import { computed } from 'vue'

const props = defineProps({
    bgStyles: { type: Object, default: () => {} },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    buttons: { type: Array, default: () => [] }
})

const background = computed(() => {
    return {
        color: props?.bgStyles?.color
            ? `#${props.bgStyles.color.toLowerCase()}`
            : '#4685ff',
        image: props?.bgStyles?.image
            ? `url(${require(`@/assets/images/banners/bgs/${props.bgStyles.image}.svg`)})`
            : 'none'
    }
})
</script>
<script>
export default {
    name: 'BaseBanner'
}
</script>

<template>
    <div class="base-banner">
        <div class="base-banner__content">
            <h1 class="base-banner__title">{{ title }}</h1>
            <p v-if="text || $slots.text" class="base-banner__text">
                <slot name="text">
                    {{ text }}
                </slot>
            </p>
            <div
                v-if="buttons.length || $slots.default"
                class="base-banner__footer"
            >
                <slot>
                    <BaseButton
                        v-for="(button, index) in buttons"
                        :key="'button-' + index"
                        v-bind="button"
                    >
                        {{ button.text }}
                    </BaseButton>
                </slot>
            </div>
        </div>
        <div v-if="$slots.background" class="base-banner__background">
            <slot name="background" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.base-banner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
    border-radius: 20px;
    overflow: hidden;
    background-color: v-bind('background.color');
    background-image: v-bind('background.image');
    background-repeat: no-repeat;
    background-size: cover;

    @include media_max_xl {
        gap: 16px;
        grid-template-columns: 4fr 3fr;
    }

    &__content {
        padding: 40px 70px;
        @include media_max_xl {
            padding: 32px 0 32px 48px;
        }
    }
    &__title {
        color: #ffffff;
        font-size: 56px;
        font-weight: 700;
        line-height: 64px;
        margin-bottom: 20px;
        @include media_lg {
            // TODO ask for designers
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 16px;
        }
    }
    &__text {
        color: #ffffff;
        font-family: Roboto, sans-serif;
        font-size: 18px;
        line-height: 24px;
    }
    &__footer {
        margin-top: 20px;
    }
    &__background {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}
</style>
