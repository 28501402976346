<script setup>
defineProps({
    i: { type: String, default: '', required: true },
    spin: { type: Boolean, default: false }
})

// import publicSvg from '/public/static/icons/symbol-defs.svg'
import publicSvg from '@/assets/icons/symbol-defs.svg'
</script>

<script>
export default {
    name: 'BaseIcon'
}
</script>

<template>
    <svg class="icon" :class="[`icon-${i}`, { icon_spin: spin }]">
        <use :xlink:href="`${publicSvg}#icon-${i}`" />
    </svg>
</template>

<style scoped lang="scss">
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.icon_spin {
    animation: fa-spin 2s linear infinite;
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
</style>
