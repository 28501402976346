export const layouts = {
    default: 'default',
    auth: 'auth',
    error: 'error'
}

export const layoutComponents = {
    default: 'LayoutDefault.vue',
    auth: 'LayoutAuth.vue',
    error: 'LayoutError.vue'
}
