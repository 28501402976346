export default {
    title: 'Sign In to the MSP360 Partner Portal',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'Sign In to the MSP360 Partner Portal'
        }
    ]
}
