import { layouts } from '@/constants/layoutTypes'
import { roles } from '@/constants/rbac/roles'
import { routeNames } from '@/constants/routes'

import DashboardPage from '@/views/DashboardPage.vue'
import LeadsPage from '@/views/LeadsPage.vue'
import ArchivedLeadsPage from '@/views/ArchivedLeadsPage.vue'
import ResourcesPage from '@/views/ResourcesPage.vue'
import DealRegistrationPage from '@/views/DealRegistrationPage.vue'
import DealsPage from '@/views/DealsPage.vue'
import QuoteCalculatorPage from '@/views/QuoteCalculatorPageNew.vue'

import SignupPage from '@/views/account/SignupPage.vue'
import SigninPage from '@/views/account/SigninPage.vue'
import ForgotPasswordPage from '@/views/account/ForgotPasswordPage.vue'
import ResetPasswordPage from '@/views/account/ResetPasswordPage.vue'
import UserSettingsPage from '@/views/account/UserSettingsPage.vue'
import PartnerRegistrationPage from '@/views/admin/PartnerRegistrationPage.vue'

import AdminUsersPage from '@/views/admin/AdminUsersPage.vue'
import AdminPartnersPage from '@/views/admin/AdminPartnersPage.vue'
import AdminLeadsPage from '@/views/admin/AdminLeadsPage.vue'
import AdminDealsPage from '@/views/admin/AdminDealsPage.vue'
import AdminProductsPage from '@/views/admin/AdminProductsPage.vue'
import AdminResourcesPage from '@/views/admin/AdminResourcesPage.vue'

import Error404Page from '@/views/errors/Error404Page.vue'
import Error403Page from '@/views/errors/Error403Page.vue'

export const routes = [
    {
        path: '/account',
        children: [
            {
                path: 'signup',
                name: routeNames.signUp,
                component: SignupPage,
                meta: {
                    layout: layouts.auth
                }
            },
            {
                path: 'signin',
                name: routeNames.signIn,
                component: SigninPage,
                meta: {
                    layout: layouts.auth
                }
            },
            {
                path: 'forgot-password',
                name: routeNames.forgotPassword,
                component: ForgotPasswordPage,
                meta: {
                    layout: layouts.auth
                }
            },
            {
                path: 'reset-password',
                name: routeNames.resetPassword,
                component: ResetPasswordPage,
                meta: {
                    layout: layouts.auth
                }
            },
            {
                path: 'partners',
                name: routeNames.userSettings,
                component: UserSettingsPage,
                meta: {
                    role: [roles.MANAGER, roles.ADMIN, roles.VIEWER]
                }
            }
        ]
    },
    {
        path: '/',
        name: routeNames.dashboard,
        component: DashboardPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/leads',
        name: routeNames.leads,
        component: LeadsPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/leads/archived',
        name: routeNames.archivedLeads,
        component: ArchivedLeadsPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/deal-registration',
        name: routeNames.dealRegistration,
        component: DealRegistrationPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/deals',
        name: routeNames.deals,
        component: DealsPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/resources',
        name: routeNames.resources,
        component: ResourcesPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/quote-calculator',
        name: routeNames.quoteCalculator,
        component: QuoteCalculatorPage,
        meta: {
            role: [...Object.values(roles)]
        }
    },
    {
        path: '/admin',
        children: [
            {
                path: 'registration',
                name: routeNames.partnerRegistration,
                component: PartnerRegistrationPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            },
            {
                path: 'users',
                name: routeNames.adminUsers,
                component: AdminUsersPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            },
            {
                path: 'partners',
                name: routeNames.adminPartners,
                component: AdminPartnersPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            },
            {
                path: 'leads',
                name: routeNames.adminLeads,
                component: AdminLeadsPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            },
            {
                path: 'deals',
                name: routeNames.adminDeals,
                component: AdminDealsPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            },
            {
                path: 'products',
                name: routeNames.adminProducts,
                component: AdminProductsPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            },
            {
                path: 'resources',
                name: routeNames.adminResources,
                component: AdminResourcesPage,
                meta: {
                    role: [roles.ADMIN, roles.VIEWER]
                }
            }
        ]
    },
    {
        path: '/forbidden',
        name: routeNames.forbidden,
        component: Error403Page,
        meta: {
            layout: layouts.error
        }
    },
    {
        path: '/404',
        name: routeNames.error404,
        component: Error404Page,
        meta: {
            layout: layouts.error
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: routeNames.error,
        component: Error404Page,
        meta: {
            layout: layouts.error
        }
    }
]
