export const alias = {
    DESKTOP: 'mbs_desktop',
    SERVER: 'mbs_baremetal',
    SQL_SERVER: 'mbs_sql',
    MS_EXCHANGE: 'mbs_exchange',
    VM_SERVER: 'mbs_vm',
    VM_SERVER_SOCKET: 'mbs_vm1socket',
    OFFICE_365: 'mbs_office365',
    SHAREPOINT: 'mbs_sharepoint',

    CONNECT: 'mbs_ra',

    RMM: 'mbs_rmm',
    DI: 'mbs_di',

    REBRANDING: 'mbs_rebranding',
    SUPPORT: 'mbs_premium_support',
    ONBOARDING: 'mbs_onboarding'
}

export const rmmWatchers = {
    rmm: alias.RMM,
    di: alias.DI
}

export const formPlaceholders = {
    comment: 'Other requirements',

    products: 'Products'
}

export const productsWithoutDiscount = [
    alias.DI,
    alias.REBRANDING,
    alias.SUPPORT,
    alias.ONBOARDING
]
