<script setup>
import { useHead } from 'unhead'
import archivedLeadsMeta from '@/constants/meta/archived-leads'
import { newDealLink } from '@/constants/content'
import { archivedLeadsKeys } from '@/constants/content/archived-leads'
import { filterArchivedStatuses } from '@/constants/leadStatuses'
import LeadsBlock from '@/components/blocks/LeadsBlock'
import { useStore } from 'vuex'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import useEventsBus from '@/composables/useEventsBus'
import useFetchData from '@/composables/useFetchData'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import { roles } from '@/constants/rbac/roles'

useHead(archivedLeadsMeta)
const store = useStore()
const { bus } = useEventsBus()

const statusFilter = ref([])

const leadsLoading = computed(() => store.getters.leadsLoading)
const archivedLeads = computed(() => store.getters.archivedLeads)
const totalCount = computed(() => store.getters.amountArchivedLeads)
const currentCount = computed(() => archivedLeads.value.length)
const substitutePartnerID = computed(() => store.getters.substitutePartnerID)
const message = computed(() => {
    if (!currentCount.value && !statusFilter.value.length) {
        return 'Here will be list of archived leads'
    }
    if (!currentCount.value && statusFilter.value.length) {
        return "Sorry, we can't find leads on your request, try to change filter criteria"
    }
    return ''
})
const currentUser = computed(() => store.getters.currentUser)
const isValidCurrentRole = computed(() => {
    return store.getters.isValidCurrentRole([roles.ADMIN, roles.VIEWER])
})

const basicRequest = (params) => {
    const localParams = {
        ...params,
        archive: true
    }

    if (!params?.assignedPartner && isValidCurrentRole.value) {
        localParams.assignedPartner = currentUser.value.assignedPartner
    }

    if (statusFilter.value.length) {
        localParams.status = statusFilter.value
    }

    console.log('localParams: ', localParams)
    store.dispatch('getLeads', localParams)
}
const resetStore = () => {
    store.commit('RESET_ARCHIVED_LEADS')
    store.commit('SET_AMOUNT_ARCHIVED_LEADS', 0)
}

watch(
    () => bus.value.get('onStatusFilterApply'),
    async (val) => {
        const [form] = val
        statusFilter.value = form.filter
        resetStore()
        initData()
    }
)
watch(
    () => bus.value.get('onStatusFilterReset'),
    () => {
        statusFilter.value = []
        resetStore()
        initData()
    }
)

const [initData, fetchDataOnIntersection] = useFetchData(
    totalCount,
    currentCount,
    substitutePartnerID,
    basicRequest
)

initData()

onBeforeUnmount(() => {
    if (statusFilter.value.length) {
        store.commit('RESET_ARCHIVED_LEADS')
        resetStore()
    }
})
</script>

<script>
export default {
    name: 'ArchivedLeadsPage'
}
</script>

<template>
    <div>
        <LeadsBlock
            v-if="checkHasPermissions([permissionsEnum.READ_LEAD])"
            title="Archived Leads"
            :leads="archivedLeads"
            :button="newDealLink"
            :keys="archivedLeadsKeys"
            :filter-status-options="filterArchivedStatuses"
            :loading="leadsLoading"
            intersection
            @intersection="fetchDataOnIntersection"
            :message="message"
        />
    </div>
</template>
