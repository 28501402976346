import { roles } from '@/constants/rbac/roles'
import { permissionsEnum } from '@/constants/rbac/permissions'

const ADMIN_PERMISSIONS = [
    permissionsEnum.READ_USER,
    permissionsEnum.CREATE_USER,
    permissionsEnum.DELETE_USER,
    permissionsEnum.BLOCK_USER,
    permissionsEnum.READ_LEAD,
    permissionsEnum.UPDATE_LEAD_STATUS,
    permissionsEnum.READ_DEAD,
    permissionsEnum.CREATE_DEAL,
    permissionsEnum.UPDATE_DEAL_STAGE,
    permissionsEnum.CREATE_QUOTE,
    permissionsEnum.READ_RESOURCES
]

const VIEWER_PERMISSIONS = [
    permissionsEnum.READ_USER,
    permissionsEnum.READ_LEAD,
    permissionsEnum.READ_DEAD,
    permissionsEnum.READ_RESOURCES
]

const MANAGER_PERMISSIONS = [
    permissionsEnum.READ_USER,
    permissionsEnum.CREATE_USER,
    permissionsEnum.BLOCK_USER,
    permissionsEnum.READ_LEAD,
    permissionsEnum.UPDATE_LEAD_STATUS,
    permissionsEnum.READ_DEAD,
    permissionsEnum.CREATE_DEAL,
    permissionsEnum.UPDATE_DEAL_STAGE,
    permissionsEnum.CREATE_QUOTE,
    permissionsEnum.READ_RESOURCES
]

const USER_PERMISSIONS = [
    permissionsEnum.READ_USER,
    permissionsEnum.READ_LEAD,
    permissionsEnum.UPDATE_LEAD_STATUS,
    permissionsEnum.READ_DEAD,
    permissionsEnum.CREATE_DEAL,
    permissionsEnum.UPDATE_DEAL_STAGE,
    permissionsEnum.CREATE_QUOTE,
    permissionsEnum.READ_RESOURCES
]

const factoryPermissions = (permissions) => {
    return permissions.reduce((acc, curr) => ((acc[curr] = true), acc), {})
}

export const permissionsByRole = {
    [roles.ADMIN]: factoryPermissions(ADMIN_PERMISSIONS),
    [roles.VIEWER]: factoryPermissions(VIEWER_PERMISSIONS),
    [roles.MANAGER]: factoryPermissions(MANAGER_PERMISSIONS),
    [roles.USER]: factoryPermissions(USER_PERMISSIONS)
}
