export default {
    title: '404 Page Not Found',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: '404 Page Not Found description'
        }
    ]
}
