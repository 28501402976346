<script setup>
defineProps({
    label: { type: [Boolean, Object], default: false }
})
</script>

<script>
export default {
    name: 'FilterWrapper'
}
</script>

<template>
    <div class="filter-wrapper">
        <label v-if="label" :for="label.id" class="filter-wrapper__lable">
            {{ label.text }}
        </label>
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.filter-wrapper {
    padding: 32px 0 24px;
    border-bottom: 1px solid #f4f6fa;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
    &__lable,
    &:deep(.checkbox-box__title) {
        color: #636363;
        font-size: 18px;
        line-height: 28px;
    }
    &__lable {
        margin-bottom: 2px;
    }
    &:deep(.checkbox-box__title) {
        margin-bottom: 8px;
    }
    &:deep(.checkbox-box__wrap) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        align-items: center;
    }
    &:deep(.checkbox-block input + label) {
        color: #969696;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 24px;
    }
}
</style>
