<script setup>
import { computed } from 'vue'
const props = defineProps({
    image: { type: String, required: true },
    defaultImage: { type: String, default: '' },
    alt: { type: String, default: '' },
    loading: { type: String, default: 'lazy' }
})
const defaultSrc = computed(() =>
    require(`@/assets/images/${props.defaultImage}`)
)
const src = computed(() => require(`@/assets/images/${props.image}.webp`))
const srcset = computed(() => require(`@/assets/images/${props.image}@2x.webp`))
</script>

<script>
export default {
    name: 'BaseImage'
}
</script>

<template>
    <div class="base-image">
        <img
            v-if="image"
            :src="src"
            :srcset="`${srcset} 2x`"
            :alt="alt"
            v-bind="$attrs"
            :loading="loading"
        />
        <img
            v-else-if="defaultImage"
            :src="defaultSrc"
            :alt="alt"
            v-bind="$attrs"
            :loading="loading"
        />
    </div>
</template>

<style scoped lang="scss">
.base-image {
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: auto;
        object-fit: contain;
    }
}
</style>
