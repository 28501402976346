<script setup>
import { computed } from 'vue'

const props = defineProps({
    content: { type: String, required: true },
    bgColor: { type: String, default: 'gray' }
})

const iconClasses = computed(() => {
    const classes = ['base-tooltip__icon']

    classes.push(`base-tooltip__icon_${props.bgColor}`)

    return classes
})

// https://v-tooltip.netlify.app/
const options = {
    content: props.content,
    placement: 'right',
    html: true,
    distance: 10,
    popperTriggers: ['hover']
}
</script>
<script>
export default {
    name: 'BaseTooltip'
}
</script>

<template>
    <span v-tooltip="options" class="base-tooltip">
        <slot name="tooltipIcon">
            <div :class="iconClasses" />
        </slot>
    </span>
</template>

<style lang="scss" scoped>
.base-tooltip {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: sub;
    position: relative;
    cursor: pointer;
    line-height: 0;

    &__icon {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            background-image: url('@/assets/images/common/info.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }

        &_light-gray {
            &::after {
                background-color: #b5b5b5;
            }
        }
        &_gray {
            &::after {
                background-color: #6c757d;
            }
        }
        &_orange {
            &::after {
                background-color: #f74e00;
            }
        }
        &_orange-light,
        &_mbs-light {
            &::after {
                background-color: #d7a895;
            }
        }

        &_green {
            &::after {
                background-color: #179c2d;
            }
        }
        &_green-light,
        &_rmm-light {
            &::after {
                background-color: #7bbb86;
            }
        }

        &_blue {
            &::after {
                background-color: #2f76ff;
            }
        }
        &_blue-light,
        &_connect-light {
            &::after {
                background-color: #92aee4;
            }
        }
    }
}
</style>
