export const dealStatuses = {
    PENDING: 'Pending',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected'
}

export const dealStatusOptions = [
    {
        value: dealStatuses.PENDING,
        text: 'Pending'
    },
    {
        value: dealStatuses.ACCEPTED,
        text: 'Accepted'
    },
    {
        value: dealStatuses.REJECTED,
        text: 'Rejected'
    }
]

export const dealStageOptions = [
    {
        value: 'Qualification',
        text: 'Qualification'
    },
    {
        value: 'Value Delivery',
        text: 'Value Delivery'
    },
    {
        value: 'Evaluation',
        text: 'Evaluation'
    },
    {
        value: 'Proposal/Price Quote',
        text: 'Proposal/Price Quote'
    },
    {
        value: 'Closed Won',
        text: 'Closed Won'
    },
    {
        value: 'Closed Lost',
        text: 'Closed Lost'
    }
]

export const dealTypes = {
    NEW_BUSINESS: 'New Business',
    ADD_ON: 'Add On'
}

export const dealTypeOptions = [
    {
        value: dealTypes.NEW_BUSINESS,
        text: 'New Business'
    },
    {
        value: dealTypes.ADD_ON,
        text: 'Add On'
    }
]
