<script setup>
import { ref } from 'vue'
import AdminLoader from '@/components/blocks/admin/AdminLoader'

defineProps({
    loading: { type: Boolean, default: false }
})

const tabLinks = [
    {
        text: 'Users',
        to: '/admin/users'
    },
    {
        text: 'Partners',
        to: '/admin/partners'
    },
    {
        text: 'Leads',
        to: '/admin/leads'
    },
    {
        text: 'Deals',
        to: '/admin/deals'
    },
    {
        text: 'Products',
        to: '/admin/products'
    },
    {
        text: 'Resources',
        to: '/admin/resources'
    }
]

const shownFilter = ref(true)
const toggleFilter = () => {
    shownFilter.value = !shownFilter.value
}
</script>

<script>
export default {
    name: 'AdminLayout'
}
</script>

<template>
    <div class="admin-layout">
        <h1 class="subtitle">Admin Page</h1>

        <div class="admin-layout__header">
            <div class="admin-layout__tabs">
                <BaseLink
                    v-for="(link, index) in tabLinks"
                    :key="index"
                    :link="link"
                    class="admin-layout__tabs--tab"
                />
            </div>
            <button
                @click="toggleFilter"
                class="admin-layout__filter-btn"
                :class="{ 'admin-layout__filter-btn_active': shownFilter }"
            >
                <span>Filters</span>
                <BaseIcon i="settings" />
            </button>
        </div>

        <div class="admin-layout__body">
            <div v-if="loading" class="admin-layout__loader">
                <AdminLoader />
            </div>
            <slot v-else name="table" />

            <div v-if="$slots.toolbar" class="admin-layout__toolbar">
                <slot name="toolbar" />
            </div>
        </div>
        <div v-if="$slots.footer" class="admin-layout__footer">
            <slot name="footer" />
        </div>

        <Transition name="admin-filter" v-if="$slots.filter">
            <div v-if="shownFilter" class="admin-layout__filter">
                <BaseLink
                    :link="{ to: '/' }"
                    class="admin-layout__filter--logo"
                >
                    <BaseIcon i="base-logo" />
                </BaseLink>
                <div class="admin-layout__filter--header">
                    <h3 class="admin-layout__filter--title">Filters</h3>
                    <button
                        @click="toggleFilter"
                        class="admin-layout__filter--close"
                    />
                </div>
                <div class="admin-layout__filter--body">
                    <slot name="filter" />
                </div>
                <div class="admin-layout__filter--footer">
                    <slot name="filter-footer" />
                </div>
            </div>
        </Transition>
    </div>
</template>

<style lang="scss" scoped>
.admin-layout {
    .subtitle {
        margin-bottom: 40px;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__tabs {
        display: flex;
        gap: 8px;
        &--tab.link {
            font-size: 18px;
            line-height: 28px;
            padding: 10px;
            min-width: 144px;
            border-radius: 8px 8px 0px 0px;
            background: rgba(126, 170, 255, 0.15);
            color: #8aa0c6;
            transition: 0.3s ease-in-out;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-radius: 20px 8px 0px 0px;
            }

            &:last-child {
                border-radius: 8px 20px 0px 0px;
            }

            &:hover,
            &.router-link-active {
                color: #f74e00;
                background: #ffffff;
            }
            @include media_lg {
                min-width: 120px;
            }
        }
    }
    &__filter-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 23px;
        border: 1px solid #2f76ff;
        background: #ffffff;
        color: #2f76ff;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.416px;
        padding: 6px 12px;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        .icon {
            width: 18px;
            height: 18px;
        }
        &_active {
            background: #2f76ff;
            color: #ffffff;
        }
        &:hover {
            background: rgba(126, 170, 255, 0.15);
            color: #2f76ff;
        }
    }
    &__body {
        border-radius: 0px 20px 20px 20px;
        background: #ffffff;
        padding: 32px;
    }
    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
    }
    &__toolbar {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        padding: 12px;
        border-radius: 43px;
        background: #f4f5fb;
        margin-top: 24px;
    }
    &__footer {
        margin-top: 22px;
    }

    &__filter {
        display: flex;
        flex-direction: column;
        padding: 44px 32px 44px 52px;
        background: #ffffff;
        box-shadow: 0px 4px 34px #adbfff4d;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 384px;
        &--logo {
            width: 202px;
            height: 87px;
            margin-bottom: 86px;
            .icon {
                width: 100%;
                height: 100%;
            }
            @include media_lg {
                margin-bottom: 32px;
            }
        }
        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 28px;
        }
        &--title {
            color: #636363;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 0;
        }
        &--close {
            width: 24px;
            height: 24px;
            border: 1px solid #f74e00;
            background: #ffffff;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            transition: 0.2s ease-in;
            outline: none;
            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 15px;
                height: 1px;
                background-color: #f74e00;
                border-radius: 2px;
                top: 50%;
                left: 50%;
                transition: 0.2s ease-in;
            }
            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:hover {
                background: #ffe7e7;
            }
            &:focus {
                background: #f74e00;
                &:before,
                &:after {
                    background-color: #ffffff;
                }
            }
        }
        &--body {
            flex: 1;
            overflow-y: auto;
            padding-right: 8px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-track {
                background: #f4f5fb;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background: #c9cff2;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #bec4e4;
            }
        }
        &--footer {
            :deep(.button) {
                width: 100%;
            }
        }
    }
}
.admin-filter-enter-active {
    transition: all 0.3s ease-out;
}

.admin-filter-leave-active {
    transition: all 0.5s ease-in;
}

.admin-filter-enter-from,
.admin-filter-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}
</style>
