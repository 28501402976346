<script setup>
import { useHead } from 'unhead'
import signinMeta from '@/constants/meta/account/signin'
import SigninForm from '@/components/forms/SigninForm'
import VerificationFormForSignin from '@/components/forms/VerificationFormForSignin'
import { computed, ref } from 'vue'

useHead(signinMeta)

const resetPage = {
    to: '/account/forgot-password',
    text: 'Reset'
}

const totp = ref(false)
const signinForm = ref({})

const onVerification = (verification, siForm) => {
    totp.value = verification
    signinForm.value = siForm
}

const title = computed(() => {
    return totp.value ? 'Verification' : 'Sign In'
})
const text = computed(() => {
    return totp.value
        ? 'Verification code was sent to your email, please enter it below:'
        : 'Welcome to the MSP360 Partner Portal.'
})
</script>
<script>
export default {
    name: 'SigninPage'
}
</script>

<template>
    <div class="signin">
        <h2 class="lg-title">{{ title }}</h2>
        <p class="text text_center">{{ text }}</p>
        <template v-if="!totp">
            <SigninForm class="signin__form" @verification="onVerification" />
            <p class="text-note text-note_center">
                Forgot Your Password?
                <BaseLink :link="resetPage" />
            </p>
        </template>
        <template v-else>
            <VerificationFormForSignin
                :signin-form="signinForm"
                class="signin__form"
            />
        </template>
    </div>
</template>

<style lang="scss" scoped>
.signin {
    width: 100%;
    &__form {
        padding: 0 98px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 12px;
        width: 100%;

        &:deep(.button) {
            margin-top: 24px;
            align-self: center;
        }
    }
    .lg-title {
        margin-bottom: 12px;
    }
    .text {
        margin-bottom: 24px;
    }
    .text-note {
        .link {
            display: block;
            font-weight: 600;
            text-decoration: none;
            width: fit-content;
            margin: auto;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
