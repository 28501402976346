<script setup>
import LeadsPanel from '@/components/blocks/LeadsPanel'
import LeadsListHeader from '@/components/blocks/LeadsListHeader'
import LeadsListRow from '@/components/blocks/LeadsListRow'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { computed } from 'vue'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    leads: { type: [Boolean, Array], default: false },
    keys: { type: Array, required: true },
    withPanel: { type: Boolean, default: true },
    filterStatusOptions: { type: [Boolean, Array], default: false },
    loading: { type: Boolean, default: false },
    intersection: { type: Boolean, default: false },
    message: { type: String, default: '' }
})
const classes = computed(() => {
    const classes = ['leads-list']

    if (props.mode) {
        classes.push(`leads-list_${props.mode}`)
    }
    if (props.withPanel) {
        classes.push('leads-list_with-panel')
    }

    return classes
})
</script>
<script>
export default {
    name: 'LeadsList'
}
</script>

<template>
    <div :class="classes">
        <LeadsPanel v-if="withPanel" />
        <BaseBlock>
            <LeadsListHeader
                :titles="keys"
                :filter-status-options="filterStatusOptions"
            />
            <template v-if="leads.length">
                <TransitionGroup
                    tag="div"
                    name="fade-leads"
                    class="leads-list__wrap"
                >
                    <LeadsListRow
                        v-for="lead in leads"
                        :key="lead._id"
                        :lead="lead"
                        :values="keys"
                    />
                </TransitionGroup>
                <div v-if="intersection" v-intersection />
            </template>
            <div v-if="loading" class="leads-list__loading">
                <LoadingSpinner />
            </div>
            <div v-if="!loading && message" class="text leads-list__message">
                {{ message }}
            </div>
        </BaseBlock>
    </div>
</template>

<style lang="scss" scoped>
.leads-list {
    &_with-panel {
        .base-block {
            border-top-right-radius: 0px;
        }
    }
    &__wrap {
        position: relative;
    }
    &__loading {
        text-align: center;
        padding: 24px 0;
    }
    &__message {
        padding: 24px 0;
    }

    .fade-leads-move,
    .fade-leads-enter-active,
    .fade-leads-leave-active {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    }

    .fade-leads-enter-from,
    .fade-leads-leave-to {
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
    }

    .fade-leads-leave-active {
        position: absolute;
        width: 100%;
    }
}
</style>
