<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useHead } from 'unhead'
import _ from 'lodash'
import meta from '@/constants/meta/admin/partners'
import { partnerTierOptions } from '@/constants/partners'

import useFilters from '@/composables/useFilters'
import useConfirm from '@/composables/useConfirm'

import AdminLayout from '@/components/blocks/admin/AdminLayout'
import AdminTable from '@/components/blocks/admin/AdminTable'
import AdminAction from '@/components/blocks/admin/AdminAction'
import ConfirmationModal from '@/components/common/ConfirmationModal'
import FilterWrapper from '@/components/filters/FilterWrapper'

useHead(meta)
const store = useStore()

const deleteActionId = Symbol('delete-confirmafion')

const tableColumns = [
    {
        target: 'companyName',
        title: 'Company Name'
    },
    {
        target: 'email',
        title: 'Email'
    },
    {
        target: 'companyId',
        title: 'Company Id'
    },
    {
        target: 'partnerTier',
        title: 'Partner Tier'
    }
    // {
    //     slug: 'actions',
    //     sticky: true
    // }
]
// пока без удаления партнера
const deletePartner = async (partner) => {
    editablePartner.value = partner
    const [getConfirmation] = useConfirm(deleteActionId)
    const confirmation = await getConfirmation()

    if (confirmation) {
        store.dispatch('admin/deletePartners')
    }
}
const getActions = (partner) => {
    return [
        {
            icon: 'delete',
            hint: {
                content: 'Delete'
            },
            cb: deletePartner.bind(this, partner)
        }
    ]
}

const partners = computed(() => store.getters['admin/getPartners'](limit.value))
const limit = ref(10)
const currentPage = ref(1)
const editablePartner = ref({})

/* filters */
const filtersContent = {
    companyName: {
        label: {
            id: 'companyName',
            text: 'By Company Name'
        },
        filter: {
            type: 'search',
            id: 'companyName',
            name: 'companyName',
            placeholder: 'Enter Company Name'
        }
    },
    email: {
        label: {
            id: 'email',
            text: 'By Email'
        },
        filter: {
            type: 'search',
            id: 'email',
            name: 'email',
            placeholder: 'Enter an Email'
        }
    },
    companyId: {
        label: {
            id: 'companyId',
            text: 'By Company Id'
        },
        filter: {
            type: 'search',
            id: 'companyId',
            name: 'companyId',
            placeholder: 'Enter Company Id'
        }
    },
    partnerTier: {
        filter: {
            title: 'By Partner Tier',
            options: partnerTierOptions
        }
    }
}

const { filters, isFilterSet, resetFilters } = useFilters({
    companyName: '',
    email: '',
    companyId: '',
    partnerTier: []
})
/* filters */

const params = computed(() => {
    return {
        limit: limit.value,
        offset: partners.value.list.length * (currentPage.value - 1),
        isFilterSet: isFilterSet.value,
        ...filters.value
    }
})

onMounted(() => {
    store.dispatch('admin/fetchPartners', params.value)
})

const debouncedFetchPartners = _.debounce(() => {
    store.dispatch('admin/fetchPartners', params.value)
}, 1000)

watch(filters.value, debouncedFetchPartners)
</script>
<script>
export default {
    name: 'AdminPartnersPage'
}
</script>

<template>
    <AdminLayout :loading="partners.loading">
        <template #filter>
            <FilterWrapper :label="filtersContent.companyName.label">
                <BaseInput
                    v-bind="filtersContent.companyName.filter"
                    v-model="filters.companyName"
                />
            </FilterWrapper>
            <FilterWrapper :label="filtersContent.email.label">
                <BaseInput
                    v-bind="filtersContent.email.filter"
                    v-model="filters.email"
                />
            </FilterWrapper>
            <FilterWrapper :label="filtersContent.companyId.label">
                <BaseInput
                    v-bind="filtersContent.companyId.filter"
                    v-model="filters.companyId"
                />
            </FilterWrapper>
            <FilterWrapper>
                <BaseCheckboxBox
                    v-model="filters.partnerTier"
                    v-bind="filtersContent.partnerTier.filter"
                />
            </FilterWrapper>
        </template>
        <template #filter-footer>
            <BaseButton design="outline-blue" @click="resetFilters">
                Reset
            </BaseButton>
        </template>
        <template #table>
            <AdminTable :table="tableColumns" :items="partners.list">
                <template #actions="item">
                    <AdminAction
                        v-for="(action, index) in getActions(item)"
                        :key="index"
                        :action="action"
                    />
                </template>
            </AdminTable>
        </template>
        <template #toolbar>
            <BaseButton @click="store.dispatch('admin/parsePartners')">
                Parse Partners
            </BaseButton>
        </template>
        <template #footer>
            <BasePagination
                v-model="currentPage"
                :total-items="partners.total"
                @click="() => store.dispatch('admin/fetchPartners', params)"
            />
        </template>
    </AdminLayout>

    <ConfirmationModal :id="deleteActionId" text-on-resolve="Delete">
        Are you sure you want to delete the Partner
        <!-- eslint-disable -->
        <span>{{ editablePartner.companyName }}</span>?
    </ConfirmationModal>
</template>
