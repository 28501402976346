import { leadValues } from '@/constants/leadValues'

export const archivedLeadsKeys = [
    {
        title: 'Name',
        value: leadValues.NAME
    },
    {
        title: 'Status',
        value: leadValues.STATUS
    },
    {
        title: 'Company',
        value: leadValues.COMPANY
    },
    {
        title: 'Email',
        value: leadValues.EMAIL
    },
    {
        title: 'Phone',
        value: leadValues.PHONE
    }
]
