import { instance } from './index'
import { handlerAPIErrors } from '@/api/utils/handlerAPIErrors'

export const authAPI = {
    async signup(data) {
        /**
         * data
         * { email: string, token: string, password: string }
         */
        return instance
            .patch(`/api/ipp/users/confirm`, data)
            .then((res) => {
                console.log('signup API res: ', res)
                return res.status === 200 && res.data
                    ? { data: res.data }
                    : { data: null }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'signup' })
            })
    },
    async signin(data) {
        /**
         * data
         * { username: email, password: string }
         */
        return instance
            .post('/api/ipp/users/signin', data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                return { data: res.data, status: res.status }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'signin' })
            })
    },
    async forgotPassword(data) {
        return instance
            .post('/api/ipp/users/password/request', data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                console.log('forgotPassword API res: ', res)
                return { data: res.data }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'forgotPassword' })
            })
    },
    async resetPassword(data) {
        /**
         * data
         * { email: string, token: string, password: string }
         */
        return instance
            .post('/api/ipp/users/password/change', data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                console.log('resetPassword API res: ', res)
                return { data: res.data }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'resetPassword' })
            })
    },
    async registerUser(data) {
        /**
         * data
         * <name>: { type: String, required: true }
         * <email>: { type: String, required: true }
         * <message>: { type: String, default: '' }
         * <assignedPartner>: { type: String, required: true }
         */
        return instance
            .post(`/api/ipp/users`, data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                console.log('res: ', res)
                if (res.status === 201) {
                    return { data: res.data }
                }

                throw new Error('Something went wrong')
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'registerUser' })
            })
    },
    fetchUsers(params) {
        return instance
            .get('/api/ipp/users', { params })
            .then((res) => {
                return res.status === 200 ? res.data : null
            })
            .catch((err) => {
                handlerAPIErrors(err, { info: 'fetchUsers' })
            })
    },
    fetchCurrentUser() {
        return instance
            .get('/api/ipp/users/me')
            .then((res) => res)
            .catch((err) => err)
    },
    setUserCredentials(params) {
        return instance
            .patch(`/api/ipp/users/${params.id}/${params.action}`)
            .then((res) => {
                console.log('setUserCredentials API: ', res)
                return res.status === 200
                    ? res.data
                    : res.status === 204
                    ? { success: true }
                    : null
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'setUserCredentials' })
            })
    },
    fetchCurrentSession() {
        return instance
            .get('/api/ipp/users/session')
            .then((res) => {
                console.log('fetchCurrentSession res: ', res)
                return res.status === 200 && res.data
                    ? { data: res.data }
                    : { data: null }
            })
            .catch((err) => {
                return { err }
            })
    },
    async inviteUser(params) {
        /**
         * <_id>: { type: String }
         */

        return instance
            .get(`/api/ipp/users/${params._id}/reset`)
            .then((res) => {
                console.log('inviteUser res: ', res)
                return res.status === 200 ? { data: true } : { data: false }
            })
            .catch((err) => {
                console.log('inviteUser err: ', err)
                return { data: false }
            })
    },
    async deleteUser(params) {
        /**
         * <email>: { type: String }
         */

        return instance
            .delete('/api/ipp/users/delete', { params })
            .then((res) => {
                return { data: res.status === 204 }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'deleteUser' })
            })
    },
    logout() {
        return instance
            .get('/api/ipp/users/logout')
            .then((res) => {
                console.log('logout res: ', res)
                return {
                    isLogout: res.status === 200
                }
            })
            .catch((err) => {
                console.log('API ERROR in logout: ', err)
                return {
                    isLogout: false,
                    err
                }
            })
    }
}
