import { useVfm } from 'vue-final-modal'

export default function useConfirm(modalId) {
    const vfm = useVfm()

    async function getConfirmation() {
        await vfm.open(modalId)

        const confirmationBtn = document.getElementById('confirmation-btn')
        const cancelBtn = document.getElementById('cancel-btn')

        const confirmationPromise = new Promise((resolve) => {
            confirmationBtn.addEventListener('click', () => resolve(true))
        })
        const cancelPromise = new Promise((resolve) => {
            cancelBtn.addEventListener('click', () => resolve(false))
        })

        const confirmation = await Promise.any([
            confirmationPromise,
            cancelPromise
        ])

        vfm.close(modalId)

        return confirmation
    }

    return [getConfirmation]
}
