<script setup>
import { computed } from 'vue'
const props = defineProps({
    modelValue: { type: Boolean },
    id: { type: String, required: true },
    label: { type: String, required: true },
    name: { type: String, default: '' },
    value: { type: String, default: '' }
})

const emit = defineEmits(['update:value'])

// function onInput(event) {
//     emit('update:modelValue', props.value)
// }

const innerValue = computed({
    get() {
        return props.value
    },
    set() {
        emit('update:value', props.value)
    }
})
</script>

<script>
export default {
    name: 'BaseRadio'
}
</script>

<template>
    <label :for="id">
        <span>{{ label }}</span>
        <input
            :id="id"
            type="radio"
            :name="name"
            :checked="modelValue"
            v-model="innerValue"
        />
        <!-- @input="onInput" -->
        <span />
    </label>
</template>
