import { instance } from './index'
import { handlerAPIErrors } from '@/api/utils/handlerAPIErrors'

export const leadsAPI = {
    async fetchLeads(params) {
        /**
         * params
         * limit=${limit}
         * offset=${offset}
         * archive=true
         * query=''
         * status=[]
         */

        return instance
            .get(`/api/ipp/leads`, { params })
            .then((res) => {
                console.log('leadsAPI res: ', res)
                return res.status === 200 && res.data.results ? res.data : null
            })
            .catch((err) => {
                handlerAPIErrors(err, { info: 'fetchLeads' })
            })
    },
    async registerLead(data) {
        return instance
            .post(`/api/ipp/leads`, data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                console.log('registerLead API res: ', res)
                if (res.status === 201) {
                    return { data: res.data }
                }

                throw new Error('Something went wrong')
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'registerLead' })
            })
    },
    async updateLeadStatus({ id, status }) {
        return instance
            .patch(`/api/ipp/leads/${id}`, { status })
            .then((res) => {
                return res.status === 200 && res.data ? res.data : null
            })
            .catch((err) => {
                handlerAPIErrors(err, { info: 'updateLeadStatus' })
            })
    }
}
