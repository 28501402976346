import { ref, computed } from 'vue'
import { partnersAPI } from '@/api/partners-api'

export default function useSelectPartners(settings = {}) {
    const partners = ref([])
    const totalAmount = ref(null)
    const partnerOptionsLoading = ref(false)
    const searchPartnersResultText = ref('')
    const isPartnersSearching = ref(false)
    const resetOption = {
        value: '',
        text: 'Please choose an option'
    }

    function preparePartnerOptions(partners) {
        return partners.map((partner) => {
            return {
                value: partner._id,
                text: partner.companyName
            }
        })
    }

    function getPartners(params) {
        partnerOptionsLoading.value = true
        searchPartnersResultText.value = ''

        partnersAPI
            .fetchPartners(params)
            .then((res) => {
                if (res.data) {
                    partners.value = [...partners.value, ...res.data.results]

                    if (!totalAmount.value) {
                        totalAmount.value = res.data._meta[0].count
                    }
                } else {
                    searchPartnersResultText.value =
                        'Sorry, no matches were found'
                }
            })
            .finally(() => {
                partnerOptionsLoading.value = false
            })
    }

    function onSearchPartners(searchText) {
        if (!partnerOptionsLoading.value) {
            partners.value = []
            isPartnersSearching.value = true
            getPartners({ query: searchText })
        }
    }

    function onClearSearchPartners() {
        partners.value = []
        isPartnersSearching.value = false
        getPartners()
    }

    function onIntersection() {
        const offset = partners.value.length
        if (totalAmount.value > offset && !isPartnersSearching.value) {
            getPartners({ offset })
        }
    }

    const partnerOptions = computed(() => {
        const partnerOptions = preparePartnerOptions(partners.value)

        if (settings.withResetOption) {
            return [resetOption, ...partnerOptions]
        }

        return partnerOptions
    })

    return {
        partners,
        partnerOptions,
        getPartners,
        partnerOptionsLoading,
        searchPartnersResultText,
        onIntersection,
        onSearchPartners,
        onClearSearchPartners,
        resetOption
    }
}
