export const partners = {
    state: () => ({
        substitutePartnerID: ''
    }),
    getters: {
        substitutePartnerID: (state) => state.substitutePartnerID
    },
    mutations: {
        SET_SUBSTITUTE_PARTNER_ID(state, payload) {
            state.substitutePartnerID = payload
        }
    }
}
