<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useHead } from 'unhead'
import _ from 'lodash'
import meta from '@/constants/meta/admin/leads'
import { SEARCH_MODE_DYNAMIC } from '@/constants/baseSelect'
import { leadStatusOptions } from '@/constants/leadStatuses'
import useFilters from '@/composables/useFilters'
import useSelectPartners from '@/composables/useSelectPartners'

import AdminLayout from '@/components/blocks/admin/AdminLayout'
import AdminTable from '@/components/blocks/admin/AdminTable'
import FilterWrapper from '@/components/filters/FilterWrapper'
import LeadRegistrationFormForAdmin from '@/components/forms/admin/LeadRegistrationFormForAdmin'

useHead(meta)
const store = useStore()

const tableColumns = [
    {
        target: 'name',
        title: 'Name'
    },
    {
        target: 'status',
        title: 'Status'
    },
    {
        target: 'company',
        title: 'Company'
    },
    {
        target: 'assignedPartner',
        title: 'Assigned Partner'
    },
    {
        target: 'email',
        title: 'Email'
    },
    {
        target: 'phone',
        title: 'Phone'
    }
]

const leads = computed(() => store.getters['admin/getLeads'](limit.value))
const limit = ref(10)
const currentPage = ref(1)

/* for filters */
const {
    partnerOptions,
    getPartners,
    partnerOptionsLoading,
    searchPartnersResultText,
    onIntersection,
    onSearchPartners,
    onClearSearchPartners
} = useSelectPartners({ withResetOption: true })

getPartners() // init partners for select

const filtersContent = {
    assignedPartner: {
        label: {
            id: 'assignedPartner',
            text: 'By Partner'
        },
        filter: {
            name: 'Search for Partner',
            placeholder: 'Search for Partner',
            id: 'assignedPartner',
            multiple: true,
            multipleWithTags: true,
            intersection: true,
            searchable: true,
            searchMode: SEARCH_MODE_DYNAMIC,
            optionsPlaceholder: "Start typing Partner's name"
        }
    },
    name: {
        label: {
            id: 'name',
            text: 'By Name'
        },
        filter: {
            type: 'search',
            id: 'name',
            name: 'name',
            placeholder: 'Enter Name'
        }
    },
    email: {
        label: {
            id: 'email',
            text: 'By Email'
        },
        filter: {
            type: 'search',
            id: 'email',
            name: 'email',
            placeholder: 'Enter Email'
        }
    },
    status: {
        filter: {
            title: 'By Status',
            options: leadStatusOptions
        }
    }
}

const { filters, resetFilters, isFilterSet } = useFilters({
    assignedPartner: '',
    name: '',
    email: '',
    status: []
})
/* for filters */

const params = computed(() => {
    return {
        withPrices: true,
        limit: limit.value,
        offset: leads.value.list.length * (currentPage.value - 1),
        isFilterSet: isFilterSet.value,
        ...filters.value
    }
})

onMounted(() => {
    store.dispatch('admin/fetchLeads', params.value)
})

const debouncedFetchLeads = _.debounce(() => {
    store.dispatch('admin/fetchLeads', params.value)
}, 1000)

watch(filters.value, debouncedFetchLeads)
</script>
<script>
export default {
    name: 'AdminLeadsPage'
}
</script>

<template>
    <AdminLayout :loading="leads.loading">
        <template #filter>
            <FilterWrapper :label="filtersContent.assignedPartner.label">
                <BaseSelect
                    :options="partnerOptions"
                    v-model="filters.assignedPartner"
                    @intersection="onIntersection"
                    :options-loading="partnerOptionsLoading"
                    @search="onSearchPartners"
                    @clearSearch="onClearSearchPartners"
                    :search-result-text="searchPartnersResultText"
                    v-bind="filtersContent.assignedPartner.filter"
                />
            </FilterWrapper>
            <FilterWrapper :label="filtersContent.name.label">
                <BaseInput
                    v-bind="filtersContent.name.filter"
                    v-model="filters.name"
                />
            </FilterWrapper>
            <FilterWrapper :label="filtersContent.email.label">
                <BaseInput
                    v-bind="filtersContent.email.filter"
                    v-model="filters.email"
                />
            </FilterWrapper>
            <FilterWrapper>
                <BaseCheckboxBox
                    v-model="filters.status"
                    v-bind="filtersContent.status.filter"
                />
            </FilterWrapper>
        </template>
        <template #filter-footer>
            <BaseButton design="outline-blue" @click="resetFilters">
                Reset
            </BaseButton>
        </template>
        <template #table>
            <AdminTable :table="tableColumns" :items="leads.list" />
        </template>
        <template #toolbar>
            <BaseButton icon="plus">Add Lead</BaseButton>
        </template>
        <template #footer>
            <BasePagination
                v-model="currentPage"
                :total-items="leads.total"
                @click="() => store.dispatch('admin/fetchLeads', params)"
            />
        </template>
    </AdminLayout>
    <div style="padding: 20px; background: #ffffff">
        <LeadRegistrationFormForAdmin />
    </div>
</template>
