<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()

const goBack = () => {
    window.history.state.back ? router.go(-1) : router.push({ path: '/' })
}
</script>

<script>
export default {
    name: 'ErrorBlock'
}
</script>

<template>
    <BaseBlock class="error-block">
        <div class="error-block__content">
            <slot />
        </div>
        <div class="error-block__back" @click="goBack">
            <BaseIcon class="error-block__back--icon" i="big-arrow" />
        </div>
    </BaseBlock>
</template>

<style lang="scss" scoped>
.error-block {
    &.base-block {
        padding: 150px 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;

        @include media_max_xl {
            padding: 60px 36px 36px 36px;
        }
    }

    &__back {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 107px;
        height: 107px;
        background: #2f76ff;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 90%;
        color: #ffffff;
        font-size: 36px;
        cursor: pointer;
        transition: 0.2s ease-in;

        @include media_max_xl {
            width: 80px;
            height: 80px;
        }

        &--icon {
            position: absolute;
            top: 25px;
            left: 29px;
            transition: 0.2s ease-in;
            @include media_max_xl {
                top: 15px;
                left: 15px;
            }
        }

        &:hover {
            .error-block__back--icon {
                transform: translate(-5px, 0);
            }
        }
    }
    :deep(.error-block__title) {
        font-weight: 600;
        font-size: 64px;
        line-height: 74px;
        text-align: center;
        letter-spacing: -0.019em;
        color: $secondary-title-color;
        margin-bottom: 52px;
    }
    :deep(.error-block__subtitle) {
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.019em;
        color: #646464;
        margin-bottom: 34px;
    }
    :deep(.error-block__text) {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #646464;
        margin-bottom: 30px;
    }
    :deep(.text) {
        text-align: center;
        margin: 0 auto 34px auto;
        max-width: 400px;
    }
    :deep(.error-block__info) {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #646464;
        &:last-child {
            margin-bottom: 0;
        }
        .link {
            text-decoration: none;
            color: $secondary-link-color;
            cursor: pointer;
        }
    }
}
</style>
