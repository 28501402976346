<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import validation from '@/utils/validation'
import { getExternalErrors } from '@/utils/formatting'
import { leadStatusOptions } from '@/constants/leadStatuses'
import { SEARCH_MODE_DYNAMIC } from '@/constants/baseSelect'

import useSelectPartners from '@/composables/useSelectPartners'
import useSelectUsers from '@/composables/useSelectUsers'

const emit = defineEmits(['success'])

const store = useStore()
const {
    partnerOptions,
    getPartners,
    partnerOptionsLoading,
    searchPartnersResultText,
    onIntersection,
    onSearchPartners,
    onClearSearchPartners
} = useSelectPartners()
getPartners()

const {
    userOptions,
    getUsers,
    userOptionsLoading,
    searchUsersResultText,
    onUsersIntersection,
    onSearchUsers,
    onClearSearchUsers
} = useSelectUsers({ withResetOption: true })
getUsers()

const formLabels = {
    name: 'Name',
    email: 'Email',
    company: 'Company',
    status: 'Status',
    phone: 'Phone Number',
    assignedPartner: 'Partner',
    assignedUser: 'Assigned User'
}
const formPlaceholders = {
    name: 'Name',
    email: 'Email',
    company: 'Company',
    status: 'Status',
    phone: 'Phone Number',
    assignedPartner: 'Partner',
    assignedUser: 'Assigned User'
}
const getInitForm = () => {
    return {
        name: '',
        email: '',
        company: '',
        status: '',
        phone: '',
        assignedPartner: '',
        assignedUser: ''
    }
}
const form = ref(getInitForm())
const error = ref({ isError: false, errorText: '' })
const autoDirty = ref(false)
const externalErrors = ref({})

const rules = computed(() => {
    return {
        name: {
            required: validation.required(formLabels.name)
        },
        email: {
            required: validation.required(formLabels.email),
            validEmail: validation.validEmail
        },
        company: {
            required: validation.required(formLabels.company)
        },
        status: {
            required: validation.required(formLabels.status)
        },
        phone: {
            required: validation.required(formLabels.phone),
            phone: validation.phone
        },
        assignedPartner: {
            required: validation.required(formLabels.assignedPartner)
        },
        assignedUser: {
            externalServerValidation: validation.externalServerValidation
        }
    }
})

const handlerForm = async (formData) => {
    try {
        console.log('formData: ', formData)
        const res = await store.dispatch('admin/registerLead', formData)
        console.log('registerLead FORM res: ', res)

        if (res.data) {
            emit('success', formData)
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true
            externalErrors.value = getExternalErrors(res.errInfo.errors)
        } else {
            throw new Error()
        }
    } catch (err) {
        error.value.isError = true
        error.value.errorText = 'An error occurred while adding a new lead'
    }
}

const resetForm = () => Object.assign(form.value, getInitForm())
</script>

<script>
export default {
    name: 'LeadRegistrationFormForAdmin'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        submit-text="Add New Lead"
        :on-submit="handlerForm"
        :error="error.isError"
        :error-text="error.errorText"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        reset-form
        :on-reset="resetForm"
    >
        <template #default="{ validator }">
            <BaseInput
                v-model.trim="form.name"
                :validator="validator.name"
                :name="formLabels.name"
                :placeholder="formPlaceholders.name"
                required
            />
            <BaseInput
                v-model="form.email"
                :validator="validator.email"
                :name="formLabels.email"
                :placeholder="formPlaceholders.email"
                required
            />
            <BaseInput
                v-model.trim="form.company"
                :validator="validator.company"
                :name="formLabels.company"
                :placeholder="formPlaceholders.company"
                required
            />
            <BaseSelect
                :options="leadStatusOptions"
                v-model="form.status"
                :validator="validator.status"
                :name="formLabels.status"
                :placeholder="formPlaceholders.status"
                required
            />
            <BaseInput
                type="tel"
                v-model="form.phone"
                :validator="validator.phone"
                :name="formLabels.phone"
                :placeholder="formPlaceholders.phone"
                required
            />
            <BaseSelect
                :options="partnerOptions"
                v-model="form.assignedPartner"
                :validator="validator.assignedPartner"
                :name="formLabels.assignedPartner"
                :placeholder="formPlaceholders.assignedPartner"
                required
                intersection
                @intersection="onIntersection"
                searchable
                :search-mode="SEARCH_MODE_DYNAMIC"
                :options-loading="partnerOptionsLoading"
                @search="onSearchPartners"
                @clearSearch="onClearSearchPartners"
                :search-result-text="searchPartnersResultText"
                options-placeholder="Start typing Partner's name"
            />
            <BaseSelect
                :options="userOptions"
                v-model="form.assignedUser"
                :validator="validator.assignedUser"
                :name="formLabels.assignedUser"
                :placeholder="formPlaceholders.assignedUser"
                intersection
                @intersection="onUsersIntersection"
                searchable
                :search-mode="SEARCH_MODE_DYNAMIC"
                :options-loading="userOptionsLoading"
                @search="onSearchUsers"
                @clearSearch="onClearSearchUsers"
                :search-result-text="searchUsersResultText"
                options-placeholder="Start typing User's name"
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
