<script setup>
import { computed } from 'vue'
const props = defineProps({
    modelValue: { type: Boolean },
    validator: { type: [Object, Boolean], default: false },
    id: { type: String, required: true },
    text: { type: String, default: '' }
})
const emit = defineEmits(['update:modelValue'])
const model = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const errorText = computed(() => {
    return (
        (props.validator.$error && props.validator.$errors[0]?.$message) ||
        (props.validator.$externalResults &&
            props.validator.$externalResults[0]?.$message)
    )
})
</script>

<script>
export default {
    name: 'BaseCheckbox'
}
</script>

<template>
    <div class="checkbox-block">
        <input type="checkbox" :id="id" v-model="model" />
        <label :for="id">
            <slot>
                {{ text }}
            </slot>
        </label>
    </div>
    <span
        v-if="validator.$error || validator.$externalResults"
        class="checkbox-block__helper"
    >
        {{ errorText }}
    </span>
</template>

<style scoped lang="scss">
// TODO old version, need to change
.checkbox-block {
    min-height: 24px;
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        &:checked + label:before {
            background-size: 14px 11px;
            border-color: #f74e00;
            background-color: #f74e00;
            background-image: url('@/assets/images/common/checkbox-icon.svg');
        }
        &:not(:disabled):not(:checked) + label:hover:before {
            border-color: #f74e00;
        }
        &:not(:disabled):active + label:before {
            background-color: #f74e004d;
            border-color: #f74e00;
        }
        &:focus + label:before {
            box-shadow: 0 0 0 0.2rem #f74e0040;
        }
        &:focus:not(:checked) + label:before {
            border-color: #f74e00;
        }
        &:disabled + label:before {
            background-color: #a2a3a4;
            background-size: 14px 11px;
            background-image: url('@/assets/images/common/checkbox-icon.svg');
        }
    }
    input + label {
        display: block;
        padding-left: 38px;
        position: relative;
        user-select: none;
        color: #424242;
        font-size: 16px;
        line-height: 20px;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            display: inline-block;
            min-width: 24px;
            height: 24px;
            border: 1px solid #ced4da;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 0 0;
            transition: 0.2s;
        }
        a {
            color: #f74e00;
        }
    }
    &__helper {
        font-family: 'Roboto', sans-serif;
        background-color: $primary-label-bg;
        color: $warning-text-color;
        font-size: 12px;
        line-height: 16px;
    }
}
</style>
