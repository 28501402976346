export const countryList = require('country-list')
const states = require('states-us')

export const getCountryOptions = (params) => {
    const allCountryNames = countryList.getNames()
    let preferredCountries = []
    let filteredCountries = []

    if (params?.preferredCountries?.length) {
        preferredCountries = params.preferredCountries.map((iso) => {
            return countryList.getName(iso)
        })
        filteredCountries = allCountryNames.filter(
            (c) => !preferredCountries.includes(c)
        )
    }

    const preparedCountryNames = params?.preferredCountries?.length
        ? [...preferredCountries, ...filteredCountries]
        : allCountryNames

    const countryOptions = preparedCountryNames.map((country) => ({
        text: country,
        value: country
    }))

    return countryOptions
}

export const getStateOptions = () => {
    const stateOptions = states.default.map((state) => ({
        text: state.name,
        value: state.name
    }))
    return stateOptions
}
