<script setup>
import DashboardBanner from '@/components/blocks/DashboardBanner'
import DealsBlock from '@/components/blocks/DealsBlock'
import ResourcesBlock from '@/components/blocks/ResourcesBlock'
import LeadsBlock from '@/components/blocks/LeadsBlock'
import { newDealLink } from '@/constants/content'
import { leadsKeys } from '@/constants/content/leads'
import { useHead } from 'unhead'
import dashboardMeta from '@/constants/meta/dashboard'
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import { roles } from '@/constants/rbac/roles'

useHead(dashboardMeta)
const store = useStore()
const substitutePartnerID = computed(() => store.getters.substitutePartnerID)

const leads = computed(() => store.getters.leads.slice(0, 10))
const message = computed(() => {
    if (!leads.value.length) {
        return 'Here will be list of recent leads'
    }

    return ''
})
const currentUser = computed(() => store.getters.currentUser)
const isValidCurrentRole = computed(() => {
    return store.getters.isValidCurrentRole([roles.ADMIN, roles.VIEWER])
})

const basicLeadsRequest = () => {
    const localParams = {
        archive: false
    }
    if (substitutePartnerID.value) {
        localParams.assignedPartner = substitutePartnerID.value
    } else if (isValidCurrentRole.value) {
        localParams.assignedPartner = currentUser.value.assignedPartner
    }

    store.dispatch('getLeads', localParams)
}

if (!leads.value.length) {
    basicLeadsRequest()
}

const linkToLeads = {
    text: 'all lead list >',
    to: '/leads'
}
const leadsStatistic = {
    count: computed(() => store.getters.amountActiveLeads),
    text: 'active leads'
}

watch(substitutePartnerID, () => {
    basicLeadsRequest()
})
</script>

<script>
export default {
    name: 'DashboardPage'
}
</script>

<template>
    <div class="dashboard">
        <DashboardBanner />
        <div class="dashboard__info-table">
            <router-link
                v-if="checkHasPermissions([permissionsEnum.READ_DEAD])"
                to="/deals"
                class="dashboard__deals-link"
            >
                <DealsBlock with-title />
            </router-link>
            <ResourcesBlock
                v-if="checkHasPermissions([permissionsEnum.READ_RESOURCES])"
            />
        </div>
        <LeadsBlock
            v-if="checkHasPermissions([permissionsEnum.READ_LEAD])"
            title="Recent leads in work"
            :with-panel="false"
            :leads="leads"
            :keys="leadsKeys"
            :link="linkToLeads"
            :statistic="leadsStatistic"
            :button="newDealLink"
            :message="message"
        />
    </div>
</template>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__info-table {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 36px;

        @include media_lg {
            grid-template-columns: 1.06fr 0.94fr;
        }
    }
    &__deals-link {
        text-decoration: none;
    }
}
</style>
