<script setup>
import { computed } from 'vue'
import DealInfo from '@/components/blocks/DealInfo'
import LoadingSpinner from '@/components/common/LoadingSpinner'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    list: { type: [Boolean, Array], default: false },
    isEmptyList: { type: Boolean, required: true },
    intersection: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
})
const classes = computed(() => {
    const classes = ['deals-list']

    if (props.mode) {
        classes.push(`deals-list_${props.mode}`)
    }

    return classes
})
const dealRegistrationLink = {
    to: '/deal-registration',
    text: 'Create a Deal'
}
</script>
<script>
export default {
    name: 'DealsList'
}
</script>

<template>
    <div :class="classes">
        <div v-if="isEmptyList" class="text">
            At the moment there are no registered deals. To do this, go to the
            <BaseLink :link="dealRegistrationLink" />
        </div>
        <div v-if="list.length" class="deals-list__wrap">
            <DealInfo v-for="(deal, i) in list" :key="i" :deal-info="deal" />
            <div v-if="intersection" v-intersection />
        </div>
        <div v-if="loading" class="deals-list__loading">
            <LoadingSpinner />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.deals-list {
    &__wrap {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &__loading {
        text-align: center;
        padding: 8px;
    }
}
</style>
