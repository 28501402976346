export default {
    title: 'Admin Page — Resources',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'Admin Page — Resources'
        }
    ]
}
