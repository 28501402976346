import { isAfter } from '@/utils/vue-moment'
let id = 1
const IPP_NOTIFICATIONS = 'ipp-notifications'
const IPP_HAS_NEW_NOTIFICATION = 'ipp-has-new-notification'

export const notification = {
    state: () => ({
        hasNewMessage: null,
        notificationStack: [],
        notificationHistory: [],
        maxCountHistory: 50,
        maxDays: 7
    }),
    getters: {
        hasNewMessage: (state) => state.hasNewMessage,
        notificationStack: (state) => state.notificationStack,
        notificationHistory: (state) => state.notificationHistory
    },
    mutations: {
        INIT_HAS_NEW_NOTIFICATION(state) {
            localStorage.getItem(IPP_HAS_NEW_NOTIFICATION) ??
                localStorage.setItem(
                    IPP_HAS_NEW_NOTIFICATION,
                    JSON.stringify(false)
                )
            state.hasNewMessage = JSON.parse(
                localStorage.getItem(IPP_HAS_NEW_NOTIFICATION)
            )
        },
        SET_HAS_NEW_NOTIFICATION(state, bool) {
            localStorage.setItem(IPP_HAS_NEW_NOTIFICATION, JSON.stringify(bool))
            state.hasNewMessage = JSON.parse(
                localStorage.getItem(IPP_HAS_NEW_NOTIFICATION)
            )
        },
        SET_ACTUAL_NOTIFICATION_HISTORY(state) {
            const localStorageNotifications = JSON.parse(
                localStorage.getItem(IPP_NOTIFICATIONS)
            )

            if (
                Array.isArray(localStorageNotifications) &&
                localStorageNotifications.length
            ) {
                const comparableDate =
                    Date.now() - 1000 * 60 * 60 * 24 * state.maxDays

                const filteredNotificationsByTime =
                    localStorageNotifications.filter((notification) => {
                        return (
                            isAfter(notification.timestamp, comparableDate) &&
                            notification
                        )
                    })

                const filteredNotificationsByCount =
                    filteredNotificationsByTime.slice(0, state.maxCountHistory)

                localStorage.setItem(
                    IPP_NOTIFICATIONS,
                    JSON.stringify(filteredNotificationsByCount)
                )
                state.notificationHistory = filteredNotificationsByCount
            }
        },
        SET_NOTIFICATION(state, { message, type }) {
            state.notificationStack.splice(0, 0, {
                id,
                message,
                type
            })

            const localStorageNotifications = JSON.parse(
                localStorage.getItem(IPP_NOTIFICATIONS)
            )
            const preparedNotifications = Array.isArray(
                localStorageNotifications
            )
                ? localStorageNotifications
                : []

            preparedNotifications.splice(0, 0, {
                id,
                message,
                type,
                timestamp: new Date()
            })

            localStorage.setItem(
                IPP_NOTIFICATIONS,
                JSON.stringify(preparedNotifications)
            )

            this.commit('SET_ACTUAL_NOTIFICATION_HISTORY')
            this.commit('RESET_NOTIFICATION', id)
            id++
        },
        RESET_NOTIFICATION(state, id) {
            setTimeout(() => {
                state.notificationStack = [
                    ...state.notificationStack.filter((el) => el.id !== id)
                ]
            }, 3000)
        }
    }
}
