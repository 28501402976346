import { ref, computed } from 'vue'
import _ from 'lodash'

export default function useFilters(initValues) {
    function initFilters() {
        return { ...initValues }
    }
    const filters = ref(initFilters())
    const isFilterSet = computed(() => {
        return Object.values(filters.value).some((filter) => !_.isEmpty(filter))
    })

    function resetFilters() {
        if (isFilterSet.value) {
            Object.assign(filters.value, initFilters())
        }
    }
    return {
        filters,
        isFilterSet,
        resetFilters
    }
}
