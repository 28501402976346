import { leadsAPI } from '@/api/leads-api'
import { responseTypes } from '@/constants/responseTypes'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { leadStatuses } from '@/constants/leadStatuses'

export const leads = {
    state: () => ({
        leads: [],
        amountActiveLeads: 0,
        archivedLeads: [],
        amountArchivedLeads: 0,
        leadsLoading: false,
        commonLeadsLimit: 10
    }),
    getters: {
        leads: (state) => state.leads,
        amountActiveLeads: (state) => state.amountActiveLeads,
        archivedLeads: (state) => state.archivedLeads,
        amountArchivedLeads: (state) => state.amountArchivedLeads,
        leadsLoading: (state) => state.leadsLoading,
        commonLeadsLimit: (state) => state.commonLeadsLimit
    },
    mutations: {
        SET_LEADS_LOADING(state, isLoading) {
            state.leadsLoading = isLoading
        },
        SET_LEADS(state, payload) {
            state.leads = [...state.leads, ...payload]
        },
        RESET_ACTIVE_LEADS(state) {
            state.leads = []
        },
        SET_AMOUNT_ACTIVE_LEADS(state, count) {
            state.amountActiveLeads = count
        },
        DECREMENT_AMOUNT_ACTIVE_LEADS(state) {
            state.amountActiveLeads -= 1
        },
        SET_ARCHIVED_LEADS(state, payload) {
            state.archivedLeads = [...state.archivedLeads, ...payload]
        },
        RESET_ARCHIVED_LEADS(state) {
            state.archivedLeads = []
        },
        SET_AMOUNT_ARCHIVED_LEADS(state, count) {
            state.amountArchivedLeads = count
        },
        INCREMENT_AMOUNT_ARCHIVED_LEADS(state) {
            state.amountArchivedLeads += 1
        },
        UPDATE_LEAD_STATUS(state, updatedLead) {
            try {
                const newLeads = state.leads.map((lead) => {
                    if (lead._id === updatedLead._id) {
                        if (lead.status !== updatedLead.status) {
                            console.log('UPDATE_LEAD_STATUS lead2: ', lead)
                            return updatedLead
                        } else {
                            throw new Error(
                                "Status hasn't changed. Please try once more"
                            )
                        }
                    } else {
                        return lead
                    }
                })
                state.leads = [...newLeads]

                pushNotification({
                    message: `<span>${responseTypes.STATUS_UPDATED.status}</span> ${responseTypes.STATUS_UPDATED.message}`,
                    type: notificationTypes.SUCCESS
                })
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span> ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        MOVE_LEAD_IN_ARCHIVE(state, updatedLead) {
            try {
                console.log('MOVE_LEAD_IN_ARCHIVE lead: ', updatedLead)
                // remove from the list of active leads
                state.leads = state.leads.filter(
                    (lead) => lead._id !== updatedLead._id
                )

                // add to the list of archived leads
                if (state.archivedLeads.length) {
                    state.archivedLeads.push(updatedLead)
                }

                // change amount of leads
                this.commit('DECREMENT_AMOUNT_ACTIVE_LEADS')
                this.commit('INCREMENT_AMOUNT_ARCHIVED_LEADS')

                pushNotification({
                    message: `The lead <span>${updatedLead.name}</span> has been archived`,
                    type: notificationTypes.SUCCESS
                })
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span> ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        }
    },
    actions: {
        async getLeads(cxt, params) {
            try {
                cxt.commit('SET_LEADS_LOADING', true)

                const data = await leadsAPI.fetchLeads(params)
                console.log('getLeads: ', data)

                if (data?.results?.length) {
                    if (params?.archive) {
                        cxt.commit('SET_ARCHIVED_LEADS', data.results)
                        cxt.commit(
                            'SET_AMOUNT_ARCHIVED_LEADS',
                            data._meta[0].count
                        )
                    } else {
                        cxt.commit('SET_LEADS', data.results)
                        cxt.commit(
                            'SET_AMOUNT_ACTIVE_LEADS',
                            data._meta[0].count
                        )
                    }
                }

                cxt.commit('SET_LEADS_LOADING', false)
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span> ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async updateLeadStatus(cxt, payload) {
            try {
                const updatedLead = await leadsAPI.updateLeadStatus(payload)

                if (updatedLead?.status === leadStatuses.APPROACHING) {
                    cxt.commit('UPDATE_LEAD_STATUS', updatedLead)
                } else {
                    cxt.commit('MOVE_LEAD_IN_ARCHIVE', updatedLead)
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span> ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async getAmountLeads(cxt, params) {
            try {
                const data = await leadsAPI.fetchLeads(params)

                if (params?.archive) {
                    if (data?._meta[0]?.count) {
                        cxt.commit(
                            'SET_AMOUNT_ARCHIVED_LEADS',
                            data._meta[0].count
                        )
                    }
                } else {
                    if (data?._meta[0]?.count) {
                        cxt.commit(
                            'SET_AMOUNT_ACTIVE_LEADS',
                            data._meta[0].count
                        )
                    }
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span> ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        }
    }
}
