<script setup>
import { computed } from 'vue'
const props = defineProps({
    list: { type: Array, required: true },
    theme: { type: String, default: 'light' }
})
const classes = computed(() => {
    const classes = ['base-list']

    if (props.theme) {
        classes.push(`base-list_${props.theme}`)
    }

    return classes
})
</script>

<script>
export default {
    name: 'BaseList'
}
</script>

<template>
    <ul :class="classes">
        <li
            v-for="(item, index) in list"
            :key="index"
            class="base-list__item"
            v-html="item"
        />
    </ul>
</template>

<style lang="scss" scoped>
.base-list {
    font-family: 'Roboto', sans-serif;
    list-style: none;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 2rem;

    @include media_md {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 1.5rem;
    }
    @include media_sm {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 1rem;
    }

    &__item {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 11px;
            width: 10px;
            height: 12px;
            background-image: url('@/assets/images/common/bullet.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }

        @include media_md {
            top: 9px;
        }
        @include media_sm {
            top: 4px;
        }
    }

    &_light {
        color: #424242;
    }
}
</style>
