'use strict'

const clickEventType = function () {
    return document.ontouchstart !== null ? 'click' : 'touchstart'
}

const UNIQUE_ID = '__vue_msp_click_outside__'

const onMounted = function (el, binding, vnode) {
    onUnmounted(el)
    let vm = vnode.context
    let callback = binding.value
    let nextTick = false
    setTimeout(function () {
        nextTick = true
    }, 0)

    el[UNIQUE_ID] = function (event) {
        if (
            (!el || !el.contains(event.target)) &&
            callback &&
            nextTick &&
            typeof callback === 'function'
        ) {
            return callback.call(vm, event)
        }
    }

    document.addEventListener(clickEventType(), el[UNIQUE_ID], true)
}

const onUnmounted = function (el) {
    document.removeEventListener(clickEventType(), el[UNIQUE_ID], true)
    delete el[UNIQUE_ID]
}

const onUpdated = function (el, binding, vnode) {
    if (binding.value === binding.oldValue) {
        return
    }

    onMounted(el, binding, vnode)
}

export default {
    mounted: onMounted,
    updated: onUpdated,
    unmounted: onUnmounted
}
