import { ref, computed } from 'vue'
import { authAPI } from '@/api/auth-api'

export default function useSelectUsers(settings = {}) {
    const users = ref([])
    const totalAmount = ref(null)
    const userOptionsLoading = ref(false)
    const searchUsersResultText = ref('')
    const isUsersSearching = ref(false)

    function prepareUserOptions(users) {
        return users.map((user) => {
            return {
                value: user._id,
                text: user.name
            }
        })
    }

    function getUsers(params) {
        userOptionsLoading.value = true
        searchUsersResultText.value = ''

        authAPI
            .fetchUsers(params)
            .then((res) => {
                if (res?.results?.length) {
                    users.value = [...users.value, ...res.results]

                    if (!totalAmount.value) {
                        totalAmount.value = res._meta[0].count
                    }
                } else {
                    searchUsersResultText.value = 'Sorry, no matches were found'
                }
            })
            .finally(() => {
                userOptionsLoading.value = false
            })
    }

    function onSearchUsers(searchText) {
        if (!userOptionsLoading.value) {
            users.value = []
            isUsersSearching.value = true
            getUsers({ query: searchText })
        }
    }

    function onClearSearchUsers() {
        users.value = []
        isUsersSearching.value = false
        getUsers()
    }

    function onUsersIntersection() {
        const offset = users.value.length
        if (totalAmount.value > offset && !isUsersSearching.value) {
            getUsers({ offset })
        }
    }

    const userOptions = computed(() => {
        const resetOption = {
            value: '',
            text: 'Please choose an option'
        }
        const userOptions = prepareUserOptions(users.value)

        if (settings.withResetOption) {
            return [resetOption, ...userOptions]
        }

        return userOptions
    })

    return {
        users,
        userOptions,
        getUsers,
        userOptionsLoading,
        searchUsersResultText,
        onUsersIntersection,
        onSearchUsers,
        onClearSearchUsers
    }
}
