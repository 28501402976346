import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'
import { loadLayoutMiddleware } from '@/router/middleware/loadLayout'
import { checkAuthMiddleware } from '@/router/middleware/checkAuth'

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(loadLayoutMiddleware)
if (process.env.NODE_ENV === 'production') {
    router.beforeEach(checkAuthMiddleware)
}

export default router
