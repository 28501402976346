<script setup>
import LoadingSpinner from '@/components/common/LoadingSpinner'

import { computed, useAttrs } from 'vue'

const $attrs = useAttrs()

const props = defineProps({
    to: { type: [Object, String], default: '' },
    href: { type: String, default: '' },
    type: { type: String, default: '' }, // why primary ?

    theme: { type: String, default: 'primary' }, // primary
    design: { type: String, default: '' }, //outline
    size: { type: String, default: 'lg' }, // lg, md, sm
    icon: { type: String, default: '' },

    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    successText: { type: String, default: 'Success' },
    errorText: { type: String, default: 'Error' },
    success: { type: Boolean, default: false },
    error: { type: Boolean, default: false }
})

const component = computed(() => {
    if (props.to) {
        return 'router-link'
    } else if (props.href) {
        return 'a'
    } else if (['button', 'submit'].includes(props.type)) {
        return 'button'
    }

    return 'div'
})

/* eslint-disable */
const attrs = computed(() => {
    const attrs = {}

    if (props.to) {
        attrs.to = props.to
    } else if (props.href) {
        attrs.href = props.href
    }

    return {
        ...$attrs,
        ...attrs
    }
})

const classes = computed(() => {
    const classes = ['button']

    classes.push(`button_theme-${props.theme}`)
    classes.push(`button_size-${props.size}`)

    if (props.design) {
        classes.push(`button_design-${props.design}`)
    }

    if (props.icon) {
        classes.push('button_with-icon')
    }

    if (props.disabled) {
        classes.push(`button_disabled`)
    }

    // if (props.loading) {
    //     classes.push(`button_loading`)
    // }

    return classes
})
</script>

<script>
export default {
    name: 'BaseButton'
}
</script>

<template>
    <component :is="component" :class="classes" v-bind="attrs" :type="type">
        <LoadingSpinner v-if="loading" />
        <template v-else>
            <BaseIcon v-if="icon" :i="icon" />
            <span>
                <template v-if="success && successText">
                    {{ successText }}
                </template>
                <template v-else-if="error && errorText">
                    {{ errorText }}
                </template>
                <slot v-else />
            </span>
        </template>
    </component>
</template>

<style scoped lang="scss">
$border-size: 2px;
.button {
    font-family: ReadexPro, sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s linear;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;
    gap: 16px;


    &_size-lg {
        font-size: 24px;
        line-height: 32px;
        border-radius: 30px;
        padding: #{8px - $border-size} #{28px - $border-size};

        &.button_with-icon {
            padding: #{8px - $border-size} #{18px - $border-size} #{8px - $border-size} #{12px - $border-size};
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }
    &_size-md {
        font-size: 18px;
        line-height: 24px;
        border-radius: 30px;
        padding: #{8px - $border-size} #{35px - $border-size};
    }

    &_size-sm {
        font-size: 16px;
        line-height: 18px;
        border-radius: 30px;
        padding: #{8px - $border-size} #{24px - $border-size};
    }
    &_size-xs {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        border-radius: 4px;
        padding: #{4px - $border-size} #{8px - $border-size};
    }

    &:hover {
        text-decoration: none !important;
    }

    &_theme-primary {
        background: $primary-btn-bg;
        border: $border-size solid $primary-btn-border-color;
        color: $primary-btn-text-color;

        &:not(.button_disabled):hover {
            background-color: $hover-btn-bg;
            border-color: $hover-btn-border-color;
            color: $primary-btn-text-color;
        }

        &:not(.button_disabled):focus,
        &:not(.button_disabled):active {
            background: $focus-btn-bg;
            border-color: $focus-btn-border-color;
        }

        &.button_disabled {
            background: $disabled-btn-bg;
            border-color: $disabled-btn-border-color;
            color: $primary-btn-text-color;
        }

        &.button_design-outline-blue {
            background: transparent;
            border: 1px solid #2f76ff;
            color: #2f76ff;

            &:not(.button_disabled):hover {
                background: rgba(126, 170, 255, 0.15);
                border: 1px solid #2f76ff;
                color: #2f76ff;
            }

            &:not(.button_disabled):focus,
            &:not(.button_disabled):active {
                border: 1px solid #2f76ff;
                color: #ffffff;
                background: #2f76ff;
            }
        }
        &.button_design-outline,
        &.button_design-outline-white,
        &.button_design-outline-grey {
            background: transparent;
            border: $border-size solid $primary-btn-border-color;

            &:not(.button_disabled):hover {
                background: $primary-btn-bg;
                color: $primary-btn-text-color;
            }

            &:not(.button_disabled):focus,
            &:not(.button_disabled):active {
                background: $hover-btn-bg;
                border-color: $hover-btn-border-color;
                color: $primary-btn-text-color;
            }

            &.button_disabled {
                border-color: $disabled-btn-border-color;
                color: $disabled-btn-text-color;
            }
        }
        &.button_design-outline {
            color: $secondary-btn-text-color;
        }
        &.button_design-outline-white {
            color: $primary-btn-text-color;
        }
        &.button_design-outline-grey {
            border: 1px solid #e3e3e3;
            color: #636363;

            &.button_disabled {
                border-color: #e3e3e3;
                color: #969696;
            }
        }
    }
    // &_theme-light {
    //     &.button_design-outline {
    //         background: transparent;
    //         border: $border-size solid $secondary-btn-border-color;
    //         color: $primary-btn-text-color;

    //         &:not(.button_disabled):hover {
    //             background: #ffffff14;
    //             border: $border-size solid $secondary-btn-border-color;
    //             color: $primary-btn-text-color;
    //         }

    //         &:not(.button_disabled):focus,
    //         &:not(.button_disabled):active {
    //             background: #ffffff3d;
    //             border: $border-size solid $secondary-btn-border-color;
    //             color: $primary-btn-text-color;
    //         }
    //     }
    // }
}
</style>
