export const permissionsEnum = {
    READ_USER: 'READ_USER',
    CREATE_USER: 'CREATE_USER',
    DELETE_USER: 'DELETE_USER',
    BLOCK_USER: 'BLOCK_USER',

    READ_LEAD: 'READ_LEAD',
    UPDATE_LEAD_STATUS: 'UPDATE_LEAD_STATUS',

    READ_DEAD: 'READ_DEAD',
    CREATE_DEAL: 'CREATE_DEAL',
    UPDATE_DEAL_STAGE: 'UPDATE_DEAL_STAGE',

    CREATE_QUOTE: 'CREATE_QUOTE',

    READ_RESOURCES: 'READ_RESOURCES'
}
