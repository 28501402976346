<script setup>
defineProps({
    content: { type: Object, required: true }
})
</script>
<script>
export default {
    name: 'SimpleCTA'
}
</script>

<template>
    <div class="simple-cta">
        <div class="simple-cta__header">
            {{ content.title }}
        </div>
        <div class="simple-cta__content">
            <p class="simple-cta__text">{{ content.text }}</p>
            <div
                v-if="content.buttons.length || $slots.default"
                class="simple-cta__buttons"
            >
                <slot>
                    <BaseButton
                        v-for="(button, index) in content.buttons"
                        :key="'button-' + index"
                        v-bind="button"
                    >
                        {{ button.text }}
                    </BaseButton>
                </slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.simple-cta {
    border-radius: 20px;
    border: 1px solid #2f76ff;
    background: #d3e2ff;
    display: flex;
    &__header {
        border-right: 1px solid #2f76ff;
        flex: 0 0 auto;
        align-content: center;
        color: #2f76ff;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
    }
    &__content {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &__content,
    &__header {
        padding: 24px;
    }
    &__text {
        color: #2f76ff;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        max-width: 500px;
    }
    &__buttons {
        display: flex;
        gap: 8px;
    }
}
</style>
