export const productGroup = {
    MBS: 'mbs',
    RMM: 'rmm',
    CONNECT: 'connect'
}
export const productGroupOptions = [
    {
        value: productGroup.MBS,
        text: 'MBS'
    },
    {
        value: productGroup.RMM,
        text: 'RMM'
    },
    {
        value: productGroup.CONNECT,
        text: 'Connect'
    }
]
