<script setup>
import { useHead } from 'unhead'
import error403Meta from '@/constants/meta/errors/error403'
import ErrorVideo from '@/components/blocks/ErrorVideo'
import ErrorBlock from '@/components/blocks/ErrorBlock'

useHead(error403Meta)

const supportLink = {
    href: 'mailto:partner-support@msp360.com',
    text: 'partner-support@msp360.com'
}
</script>

<script>
export default {
    name: 'Error403Page'
}
</script>

<template>
    <div class="layout-error__page error-page">
        <ErrorBlock>
            <h1 class="error-block__title">Forbidden</h1>
            <p class="error-block__text">
                You’re not allowed to see that page, if you think this is a
                mistake - contact us
                <BaseLink :link="supportLink" />
            </p>
        </ErrorBlock>
        <ErrorVideo class="error-page__video" video="403page-bg" />
    </div>
</template>

<style lang="scss" scoped>
.error-page {
    &__video {
        background-color: #566dec;
        background-image: url('@/assets/images/errors/403page-bg.webp');
    }
    .link {
        white-space: nowrap;
    }
}
</style>
