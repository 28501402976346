import store from '@/store'
import { permissionsByRole } from '@/constants/rbac/permissionsByRole'

export const checkHasPermissions = (permissions = []) => {
    const role = store.getters.userRole?.toLowerCase()

    if (!permissions.length || !role) return false

    return permissions.some(
        (permission) => permissionsByRole[role][permission] ?? false
    )
}
