<script setup>
import { computed } from 'vue'
import { getCurrency } from '@/utils/formatting'

const props = defineProps({
    title: { type: String, default: 'Summary' },
    productTitle: { type: String, default: 'Product license' },
    additionalTitle: { type: String, default: 'Additional services' },
    productList: { type: Array, default: () => [] },
    additionalServices: { type: Array, default: () => [] }
})

const prices = computed(() => {
    const products = [...props.productList, ...props.additionalServices]

    return products.reduce(
        (acc, a) => {
            acc.regularPrice += a.price * a.amount

            if (a.discountPrice) {
                acc.bargainPrice += a.discountPrice * a.amount
            } else {
                acc.bargainPrice += a.price * a.amount
            }

            return acc
        },
        {
            regularPrice: 0,
            bargainPrice: 0
        }
    )
})

const totalAmount = computed(() => {
    const totalAmount = prices.value.bargainPrice
        ? prices.value.bargainPrice
        : prices.value.regularPrice

    return getCurrency(totalAmount).slice(1)
})

const benefit = computed(() => {
    const benefit = prices.value.bargainPrice
        ? prices.value.regularPrice - prices.value.bargainPrice
        : 0

    return +getCurrency(benefit).slice(1)
})
</script>

<script>
export default {
    name: 'CalculatorSummaryNew'
}
</script>

<template>
    <div class="summary">
        <h3 class="summary__title">{{ title }}</h3>
        <div class="summary__block">
            <div class="summary__header">
                <h4 class="summary__subtitle">{{ productTitle }}</h4>
                <div>Qty</div>
                <div>Price</div>
            </div>
            <div class="summary__body">
                <div
                    v-for="(license, index) in productList"
                    :key="index"
                    class="summary__item"
                    :class="`summary__item_${license.group}`"
                >
                    <div>{{ license.commonName }}</div>
                    <div>{{ license.amount }}</div>
                    <div>
                        {{
                            getCurrency(
                                license.discountPrice
                                    ? license.discountPrice * license.amount
                                    : license.price * license.amount
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="summary__block">
            <h4 class="summary__subtitle summary__subtitle_mb">
                {{ additionalTitle }}
            </h4>
            <div class="summary__body">
                <div
                    v-for="(service, index) in additionalServices"
                    :key="index"
                    class="summary__item"
                >
                    <div>{{ service.commonName }}</div>
                    <div>{{ service.amount }}</div>
                    <div>
                        {{
                            getCurrency(
                                service.discountPrice
                                    ? service.discountPrice * service.amount
                                    : service.price * service.amount
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="summary__info" :class="{ summary__info_mb: !benefit }">
            <div>Total</div>
            <div>
                <!-- eslint-disable-next-line -->
                <span class="summary__info--currency">$</span>
                {{ totalAmount }}
            </div>
        </div>
        <div
            v-if="benefit"
            class="summary__info summary__info_mb summary__info_light"
        >
            <div>Margin</div>
            <div>
                <!-- eslint-disable-next-line -->
                <span
                    class="summary__info--currency summary__info--currency_sm"
                >
                    $
                </span>
                {{ benefit }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.summary {
    font-family: Roboto, sans-serif;

    &__block {
        margin-bottom: 24px;
    }
    &__header,
    &__item {
        display: flex;
        align-items: center;

        &:deep(div:nth-child(1)),
        &:deep(h4:nth-child(1)) {
            flex: 0 0 65%;
            overflow-wrap: anywhere;
            overflow: hidden;
            @include media_max_xl {
                flex: 0 0 59%;
            }
        }

        &:deep(div:nth-child(2)),
        &:deep(div:nth-child(2)) {
            flex: 0 0 13%;
            overflow: hidden;
            padding: 0 2px;
            @include media_max_xl {
                flex: 0 0 11%;
            }
        }

        &:deep(div:nth-child(3)),
        &:deep(div:nth-child(3)) {
            flex: 0 0 22%;
            overflow: hidden;
            text-align: end;
            @include media_max_xl {
                flex: 0 0 30%;
            }
        }
    }
    &__header {
        padding-right: 12px;
        margin-bottom: 12px;
    }

    &__item {
        padding: 6px 12px 6px 16px;
        background-color: $summary-block-default-bg;
        border-radius: 20px;
        font-size: 14px;
        line-height: 16px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &_mbs {
            background-color: $summary-block-primary-bg;
        }
        &_rmm {
            background-color: $summary-block-secondary-bg;
        }
        &_connect {
            background-color: $summary-block-tertiary-bg;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        color: $summary-block-title-color;
        margin-bottom: 24px;
    }
    &__subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: $summary-block-subtitle-color;
        margin-bottom: 0;
        &_mb {
            margin-bottom: 12px;
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        font-weight: 600;
        font-size: 22px;
        line-height: 24px;
        color: $summary-block-title-color;
        &--currency {
            font-size: 14px;
            line-height: 24px;
            vertical-align: text-top;
            &_sm {
                font-size: 12px;
                line-height: 16px;
            }
        }
        &_mb {
            margin-bottom: 32px;
        }
        &_light {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }
}
</style>
