import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'

export const getDiscount = (price, discount) => {
    return discount ? price - (price / 100) * discount : 0
}

export const copyInClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text)

        pushNotification({
            message: `Text <span>${text}</span> copied to clipboard`,
            type: notificationTypes.SUCCESS
        })
    } catch (err) {
        pushNotification({
            message: `Error in copying text`,
            type: notificationTypes.ERROR
        })
    }
}
