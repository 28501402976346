<script setup>
import { useVuelidate } from '@vuelidate/core'
import { ref, watch } from 'vue'

const props = defineProps({
    form: { type: Object, default: () => ({}) },
    rules: { type: Object, default: () => ({}) },
    onSubmit: { type: Function, default: () => ({}) },
    submitText: { type: String, default: 'Submit' },
    success: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    successText: { type: String, default: '' },
    errorText: { type: String, default: 'Error happened' },
    externalErrors: { type: Object, default: () => ({}) },
    autoDirty: { type: Boolean, default: false },
    resetForm: { type: Boolean, default: false },
    onReset: { type: Function, default: () => ({}) }
})

const emit = defineEmits(['error', 'submit', 'success'])

const loading = ref(false)
const errors = ref({})
const requestError = ref('')
const initialForm = ref({})
const $externalResults = ref({})

let $v = useVuelidate(props.rules, props.form, {
    $externalResults,
    $autoDirty: props.autoDirty
})

// watch(
//     () => props.rules,
//     (val) =>
//         ($v = useVuelidate(val, props.form, {
//             $externalResults,
//             $autoDirty: props.autoDirty
//         })), // TODO monitor behavior without nextTick
//     { deep: true }
// )

watch(
    [() => props.rules, () => props.autoDirty],
    ([newRules, newAutoDirty]) => {
        $v = useVuelidate(newRules, props.form, {
            $externalResults,
            $autoDirty: newAutoDirty
        })
    }
)

const submit = async () => {
    if (loading.value) {
        return
    }
    $v.value.$touch()

    if ($v.value.$invalid) {
        emit('error', errors.value)
        return
    }

    emit('submit')

    loading.value = true

    try {
        await props.onSubmit(props.form)

        const isExternalErrors = Object.values(props.externalErrors).filter(
            (err) => err !== undefined
        ).length

        if (isExternalErrors) {
            $externalResults.value = props.externalErrors
        }

        if (!props.error && !isExternalErrors) {
            if (props.resetForm) {
                $v.value.$reset()
                props.onReset(initialForm.value)
            }

            emit('success')
        }
    } catch (err) {
        const response = err?.response
        const data = response?.data
        console.log('err: ', err)

        if (response && response.status === 422) {
            ;(data || response || []).forEach(
                (error) => (errors.value[error.field] = error.message)
            )
        } else if (response && response.status === 500) {
            requestError.value = data
        } else if (err.message) {
            requestError.value = err.message
        }

        emit('error', errors.value)
    }
    loading.value = false
}

const setInitialForm = () => {
    initialForm.value = { ...props.form }
}
if (props.resetForm) {
    setInitialForm()
}
</script>

<script>
export default {
    name: 'BaseForm'
}
</script>

<template>
    <form class="base-form" @submit.prevent="submit">
        <slot v-bind="{ validator: $v }" />
        <slot
            v-if="!success"
            name="button"
            v-bind="{
                loading,
                validator: $v,
                invalid: $v.$invalid,
                submitText
            }"
        >
            <BaseButton
                type="submit"
                :disabled="$v.$invalid || loading"
                :loading="loading"
                :success="success"
                :error="error"
            >
                {{ submitText }}
            </BaseButton>
        </slot>
        <div
            v-if="success || error || requestError"
            class="form-base__result"
            :class="{
                'form-base__result_success': success,
                'form-base__result_error': error || requestError
            }"
        >
            <div class="form-base__result--icon">
                <BaseIcon :i="success ? 'simple-check' : 'cross'" />
            </div>
            <div
                class="form-base__result--text"
                v-html="success ? successText : requestError || errorText"
            />
        </div>
    </form>
</template>

<style lang="scss" scoped>
.form-base {
    &__result {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 1rem;

        &--icon {
            width: 29px;
            height: 29px;
            flex: 0 0 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            border-radius: 50%;
            overflow: hidden;

            .icon {
                font-size: 14px;
                color: #fff;
            }
        }

        &--text {
            font-size: 18px;
            line-height: 24px;
            color: #424242;
        }

        &_success {
            .form-base__result--icon {
                background: #179c2d;
            }
        }

        &_error {
            .form-base__result--icon {
                background: #ff0000;
            }
        }
    }
}
</style>
