<script setup>
import { computed } from 'vue'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    bg: { type: String, default: 'white' },
    size: { type: String, default: '' }
})
const classes = computed(() => {
    const classes = ['base-block']

    if (props.mode) {
        classes.push(`base-block_${props.mode}`)
    }
    if (props.bg) {
        classes.push(`base-block_bg-${props.bg}`)
    }
    if (props.size) {
        classes.push(`base-block_size-${props.size}`)
    }

    return classes
})
</script>
<script>
export default {
    name: 'BaseBlock'
}
</script>

<template>
    <div :class="classes"><slot /></div>
</template>

<style lang="scss" scoped>
.base-block {
    border-radius: 20px;
    padding: 16px 24px;

    &_size-lg {
        padding: 40px 32px;
    }

    &_light {
        &.base-block_bg-white {
            background: #ffffff;
        }
        &.base-block_bg-blue {
            background: #7eaaff26;
        }
    }
}
</style>
