<script setup>
import { computed } from 'vue'
const props = defineProps({
    video: { type: String, required: true }
})
const src = computed(() =>
    require(`@/assets/images/errors/${props.video}.webm`)
)
</script>

<script>
export default {
    name: 'ErrorVideo'
}
</script>

<template>
    <video
        v-bind="$attrs"
        class="error-video"
        width="1920"
        height="1080"
        autoplay
        loop
        muted
    >
        <source :src="src" type="video/webm" />
    </video>
</template>

<style lang="scss" scoped>
.error-video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}
</style>
