<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import validation from '@/utils/validation'
import { getExternalErrors } from '@/utils/formatting'

const store = useStore()
const router = useRouter()

const emit = defineEmits(['verification'])

const form = ref({
    username: '',
    password: ''
})

const externalErrors = ref({})
const autoDirty = ref(false)

const success = ref({
    isSuccess: false,
    successText: 'Please wait for redirection to home page'
})
const error = ref({
    isError: false,
    errorText: ''
})

const formLabels = {
    username: 'Email',
    password: 'Password'
}
const formPlaceholders = {
    username: 'Email',
    password: 'Password'
}

const rules = computed(() => {
    return {
        username: {
            required: validation.required(formLabels.username),
            validEmail: validation.validEmail
        },
        password: {
            required: validation.required(formLabels.password),
            minLength: validation.minLength(formLabels.password, 8)
        }
    }
})

const handlerForm = async (form) => {
    try {
        const res = await store.dispatch('signin', form)
        console.log('Signin form res: ', res)

        if (res.status === 200) {
            if (res.data?.success) {
                success.value.isSuccess = true
                router.push({ path: '/' })
            } else if (res.data?.totp) {
                emit('verification', res.data.totp, form)
            }
        } else if (res?.errInfo.status === 401) {
            const [firstError] = res.errInfo.errors
            error.value.isError = true
            error.value.errorText = firstError.msg
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true

            const errors = res.errInfo.errors
            externalErrors.value = getExternalErrors(errors)
            console.log('externalErrors.value: ', externalErrors.value)
        } else {
            throw new Error()
        }
    } catch (err) {
        console.log('Signin form err: ', err)
        error.value.isError = true
        error.value.errorText = 'Log in failed'
    }
}
</script>

<script>
export default {
    name: 'SigninForm'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        :error="error.isError"
        :error-text="error.errorText"
        :success="success.isSuccess"
        :success-text="success.successText"
        :on-submit="handlerForm"
        submit-text="Sign In"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
    >
        <template #default="{ validator }">
            <BaseInput
                type="text"
                v-model="form.username"
                :validator="validator.username"
                :name="formLabels.username"
                :placeholder="formPlaceholders.username"
                required
            />
            <BaseInput
                type="password"
                v-model="form.password"
                :validator="validator.password"
                :name="formLabels.password"
                :placeholder="formPlaceholders.password"
                required
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
