export default {
    mounted(el, binding, vnode) {
        const options = {
            rootMargin: '0px',
            threshold: 0 // TODO monitor behavior
        }

        const callback = (entries) => {
            if (entries[0].isIntersecting) {
                vnode.ctx.emit('intersection')
            }
        }

        const observer = new IntersectionObserver(callback, options)
        observer.observe(el)
    }
}
