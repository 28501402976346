export default {
    title: '403 Forbidden',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: '403 Forbidden description'
        }
    ]
}
