<script>
export default {
    name: 'AdminLoader'
}
</script>

<template>
    <div class="admin-loader">
        <svg viewBox="0 0 64 70">
            <path
                d="M55.4256 16.6906L35.7128 5.3094C33.2376 3.88034 30.188 3.88034 27.7128 5.3094L8 16.6906C5.52479 18.1197 4 20.7607 4 23.6188V46.3812C4 49.2393 5.52479 51.8803 8 53.3094L27.7128 64.6906C30.188 66.1197 33.2376 66.1197 35.7128 64.6906L55.4256 53.3094C57.9008 51.8803 59.4256 49.2393 59.4256 46.3812V23.6188C59.4256 20.7607 57.9008 18.1197 55.4256 16.6906Z"
                stroke="#46546F"
            />
        </svg>
    </div>
</template>

<style lang="scss" scoped>
.admin-loader {
    display: inline-block;
    width: 44px;
    height: 44px;
    position: relative;
    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        display: block;
        background: #2f76ff;
        top: 38px;
        left: 18px;

        transform: translate(18px, -21px);
        animation: hexagonDot 4s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
    svg {
        display: block;
        width: 100%;
        height: 100%;
        path {
            fill: none;
            stroke: #46546f;
            stroke-width: 8px;
            stroke-linejoin: round;
            stroke-linecap: round;
            stroke-dasharray: calc(186 / 6 * 5) calc(186 / 6) calc(186 / 6 * 5)
                calc(186 / 6);
            stroke-dashoffset: 0;
            animation: hexagonPath 4s cubic-bezier(0.785, 0.135, 0.15, 0.86)
                infinite;
        }
    }
}
@keyframes hexagonPath {
    16.7% {
        stroke-dashoffset: 31;
    }
    33.4% {
        stroke-dashoffset: 62;
    }
    50.1% {
        stroke-dashoffset: 93;
    }
    66.8% {
        stroke-dashoffset: 124;
    }
    83.5% {
        stroke-dashoffset: 155;
    }
    100% {
        stroke-dashoffset: 186;
    }
}

@keyframes hexagonDot {
    16.7% {
        transform: translate(11px, -5px);
    }
    33.4% {
        transform: translate(-7px, -2px);
    }
    50.1% {
        transform: translate(-17px, -17px);
    }
    66.8% {
        transform: translate(-10px, -33px);
    }
    83.5% {
        transform: translate(8px, -34px);
    }
    100% {
        transform: translate(18px, -21px);
    }
}
</style>
