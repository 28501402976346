<script setup>
/* use together with useConfirm composable */
const props = defineProps({
    id: { type: Symbol, required: true },
    textOnResolve: { type: String, default: 'Confirm' },
    textOnReject: { type: String, default: 'Cancel' }
})

const modalSettings = {
    modalId: props.id,
    withCloseBtn: false,
    widthContent: 658,
    options: {
        clickToClose: false,
        escToClose: false
    }
}
</script>
<script>
export default {
    name: 'ConfirmationModal'
}
</script>

<template>
    <BaseModal v-bind="modalSettings" class="confirmation-modal">
        <div class="confirmation-modal__body subtitle">
            <slot name="default" />
        </div>
        <div class="confirmation-modal__actions">
            <BaseButton size="md" design="outline" id="confirmation-btn">
                {{ textOnResolve }}
            </BaseButton>
            <button id="cancel-btn" class="confirmation-modal__actions--cancel">
                {{ textOnReject }}
            </button>
        </div>
    </BaseModal>
</template>

<style scoped lang="scss">
.confirmation-modal {
    .subtitle {
        text-align: center;
        margin-bottom: 33px;
        span,
        :deep(span) {
            color: #12429d;
        }
    }
    &__actions {
        display: flex;
        gap: 28px;
        justify-content: center;
        align-items: center;
        &--cancel {
            background: transparent;
            border: none;
            outline: none;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.019em;
            color: #969696;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}
</style>
