import { instance } from './index'
import { handlerAPIErrors } from '@/api/utils/handlerAPIErrors'

const PRODUCTS_ENDPOINT = '/api/ipp/products'

export const productsAPI = {
    async fetchProducts(params = { limit: 30, withPrices: true }) {
        return instance
            .get(PRODUCTS_ENDPOINT, { params })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { data: res.data }
                } else {
                    return { data: false }
                }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'fetchProducts' })
            })
    },
    async registerProduct(data) {
        return instance
            .post(PRODUCTS_ENDPOINT, data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                console.log('registerProduct API res: ', res)
                if (res.status === 201) {
                    return { data: res.data }
                }

                throw new Error('Something went wrong')
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'registerProduct' })
            })
    },
    async deleteProduct(params) {
        return instance
            .delete(`${PRODUCTS_ENDPOINT}/${params._id}`)
            .then((res) => {
                console.log('deleteProduct API res: ', res)
                return { data: res.status === 200 }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'deleteProduct' })
            })
    },
    async fetchProductsInfo() {
        return instance
            .get(`${PRODUCTS_ENDPOINT}/discount`)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return { data: res.data }
                } else {
                    return { data: false }
                }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'fetchProductsInfo' })
            })
    },
    async submitQuote(data) {
        /**
         * data:
         * <products>: { type: Array, required: true }
         * <additionalServices>: { type: Array, default: () => [] }
         * <comment>: { type: String, default: '' }
         */

        return instance
            .post(`/api/ipp/quotes`, data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                if (res.status === 201) {
                    return { data: res.data }
                } else {
                    return { data: false }
                }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'submitQuote' })
            })
    }
}
