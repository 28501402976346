import { instance } from './index'
import { handlerAPIErrors } from '@/api/utils/handlerAPIErrors'

export const partnersAPI = {
    async fetchPartners(params) {
        /**
         * params
         * <limit>: { type: Number, default: 10 }
         * <offset>: { type: Number, default: 0 }
         * <query>: { type: String, default: '' }
         */

        return instance
            .get(`/api/ipp/partners`, { params })
            .then((res) => {
                if (
                    res.status === 200 &&
                    res.data.results &&
                    res.data.results.length
                ) {
                    return { data: res.data }
                } else {
                    return { data: false }
                }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'fetchPartners' })
            })
    },
    async parsePartners() {
        return instance
            .get(`/api/ipp/partners/parse`)
            .then((res) => {
                console.log('parsePartners res: ', res)
                return { data: res.status === 200 }
            })
            .catch((err) => {
                console.log('parsePartners err: ', err)
                return { data: err?.response.status === 200 }
            })
    }
}
