<script setup>
import { computed, ref } from 'vue'
import validation from '@/utils/validation'
import useEventsBus from '@/composables/useEventsBus'

const props = defineProps({
    filterOptions: { type: [Boolean, Array], default: false },
    emitNameOnApply: { type: String, default: 'onFilterApply' },
    emitNameOnReset: { type: String, default: 'onFilterReset' }
})

const { emit } = useEventsBus()
const form = ref({ filter: [] })
const isSetFilter = ref(false)

const rules = computed(() => {
    return {
        filter: {
            required: validation.required('Filter')
        }
    }
})

const handlerForm = (form, hide) => {
    emit(props.emitNameOnApply, form)
    isSetFilter.value = true
    hide()
}
const resetFilter = (hide, validator) => {
    if (isSetFilter.value) {
        emit(props.emitNameOnReset)
        form.value.filter = []
        validator.$reset()
        isSetFilter.value = false
        hide()
    }
}

const filterIcon = computed(() => {
    return isSetFilter.value ? 'active-filter' : 'filter'
})
</script>

<script>
export default {
    name: 'BaseFilter'
}
</script>

<template>
    <div class="filter">
        <VDropdown placement="bottom-start" container=".filter">
            <div class="filter__header">
                <BaseIcon class="filter__filter-icon" :i="filterIcon" />
            </div>
            <template #popper="{ hide }">
                <div class="filter__body">
                    <div class="filter__body--header">
                        <div class="filter__body--title">Sort</div>
                        <BaseIcon
                            class="filter__body--close"
                            @click="hide"
                            i="cross"
                        />
                    </div>
                    <div class="filter__body--body">
                        <BaseForm
                            :form="form"
                            :rules="rules"
                            :on-submit="(form) => handlerForm(form, hide)"
                        >
                            <template #default="{ validator }">
                                <BaseCheckboxBox
                                    v-model="form.filter"
                                    :options="filterOptions"
                                    :validator="validator.filter"
                                    class="filter__body--checkbox-box"
                                />
                            </template>
                            <template #button="{ loading, invalid, validator }">
                                <div class="filter__body--footer">
                                    <BaseButton
                                        size="xs"
                                        :type="
                                            !isSetFilter ? 'button' : 'submit'
                                        "
                                        design="outline-grey"
                                        :disabled="!isSetFilter"
                                        @click="resetFilter(hide, validator)"
                                    >
                                        Clear
                                    </BaseButton>
                                    <BaseButton
                                        size="xs"
                                        :type="invalid ? 'button' : 'submit'"
                                        :loading="loading"
                                        :disabled="invalid"
                                        @click="
                                            invalid ? validator.$touch() : null
                                        "
                                    >
                                        Apply
                                    </BaseButton>
                                </div>
                            </template>
                        </BaseForm>
                    </div>
                </div>
            </template>
        </VDropdown>
    </div>
</template>

<style lang="scss" scoped>
.filter {
    &__filter-icon.icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    &__body {
        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 14px 16px;
        }
        &--title {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.019em;
            color: #b5b5b5;
        }
        &--close.icon {
            width: 12px;
            height: 12px;
            color: #636363;
            cursor: pointer;
        }
        &--checkbox-box {
            padding: 0 16px 16px 16px;
            &:deep(.checkbox-box__title) {
                margin-bottom: 0;
            }
            &:deep(.checkbox-block) {
                min-height: 16px;
                input + label {
                    color: #636363;
                    font-size: 14px;
                    line-height: 16px;
                    padding-left: 24px;
                    &:before {
                        min-width: 16px;
                        height: 16px;
                    }
                }
                input:checked + label:before {
                    background-size: 10px 9px;
                }
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
        &--footer {
            border-top: 1px solid #e3e3e3;
            padding: 12px 16px;
            display: flex;
            justify-content: space-between;
            gap: 8px;
        }
    }
}
</style>

<style lang="scss">
.filter .v-popper--theme-dropdown .v-popper__inner {
    border: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
</style>
