import { allRoutes } from '@/utils/routes'
import { routeNames } from '@/constants/routes'

const getPrivatePages = (allRoutes) => {
    const privatePagesList = allRoutes.filter((route) => {
        return route?.meta?.role
    })
    const privatePages = {}
    privatePagesList.forEach((route) => {
        privatePages[route.path] = route.meta.role
    })

    return privatePages
}

const privatePages = getPrivatePages(allRoutes)

const checkAccess = (role, path) => {
    if (!role || !privatePages[path]) {
        return false
    }

    return privatePages[path].includes(role)
}

export const privatePagesList = Object.keys(privatePages)

export const pagesOnlyForUnauthorizedUser = [
    // TODO change to constatns
    '/account/signup',
    '/account/signin',
    '/account/forgot-password',
    '/account/reset-password'
]

export const authStatuses = {
    UNAUTHORIZED: 'UNAUTHORIZED',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    HAS_ACCESS: 'HAS_ACCESS',
    UNDEFINED: 'UNDEFINED'
}

export const authRequiredResponse = {
    [authStatuses.UNAUTHORIZED]: { name: routeNames.signIn },
    [authStatuses.HAS_ACCESS]: true
}

export const errorResponse = { name: routeNames.error404 }

export const getAuthStatus = (responseStatus, userRole, path) => {
    switch (responseStatus) {
        case 401: // if user is not signed in
            return authStatuses.UNAUTHORIZED
        case 404: // if user is deleted or not signed up
            return authStatuses.USER_NOT_FOUND
        case 200:
            return checkAccess(userRole, path)
                ? authStatuses.HAS_ACCESS
                : authStatuses.UNDEFINED
        default:
            return authStatuses.UNDEFINED
    }
}
