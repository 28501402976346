import { instance } from './index'
import { handlerAPIErrors } from '@/api/utils/handlerAPIErrors'

export const dealsAPI = {
    async submitDeal(data) {
        /**
         * data:
         * <name>: { type: String, required: true }
         * <closeDate>: { type: Date, required: true } DD-MM-YYYY
         * <stage>: { type: String, required: true }
         * <amount>: { type: Number, required: true }
         * <type>: { type: String, required: true }
         * <customer>: { type: String, required: true }
         * <customerEmail>: { type: String, required: true }
         * <productFamily>: { type: Array, required: true }
         * <paymentMethod>: { type: String, required: true }
         * <description>: { type: String, default: '' }
         * <nextStep>: { type: String, default: '' }
         * <POId>: { type: String, default: '' } Purchase order ID
         * <leadId>: { type: String, default: '' }
         */

        return instance
            .post(`/api/ipp/opportunities`, data, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => {
                console.log('submitDeal res: ', res)
                if (res.status === 200 && res.data) {
                    return { data: res.data }
                }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'submitDeal' })
            })
    },
    async fetchDeals(params) {
        /**
         * params
         * <limit>: { type: Number, default: 10 }
         * <offset>: { type: Number, default: 0 }
         * <query>: { type: String, default: '' }
         */
        return instance
            .get('/api/ipp/opportunities', { params })
            .then((res) => {
                console.log('fetchDeals res: ', res)
                if (res.status === 200 && res.data.results) {
                    return { data: res.data }
                } else {
                    return { data: null }
                }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'fetchDeals' })
            })
    },
    async fetchCustomers(params) {
        /**
         * params
         * <limit>: { type: Number, default: 10 }
         * <offset>: { type: Number, default: 0 }
         * <query>: { type: String, default: '' }
         */
        return instance
            .get(`/api/ipp/opportunities/customers`, { params })
            .then((res) => {
                if (
                    res.status === 200 &&
                    res.data.result &&
                    res.data.result.length
                ) {
                    return { data: res.data }
                }
            })
            .catch((err) => {
                handlerAPIErrors(err, { info: 'fetchCustomers' })
            })
    },
    async updateDealStage({ id, stage }) {
        return instance
            .patch(`/api/ipp/opportunities/${id}`, { stage })
            .then((res) => {
                console.log('API updateDealStage res: ', res)
                return res.status === 200 && res.data ? res.data : null
            })
            .catch((err) => {
                handlerAPIErrors(err, { info: 'updateDealStage' })
            })
    },
    async fetchDealStatistics(params) {
        return instance
            .get(`/api/ipp/opportunities/statistic`, { params })
            .then((res) => {
                console.log('res: ', res)
                return res.status === 200 ? { data: res.data } : { data: null }
            })
            .catch((err) => {
                handlerAPIErrors(err, { info: 'fetchDealStatistics' })
            })
    },
    async deleteDeal(params) {
        /**
         * удаление сделки пока не будет, мы не можем удалить сделку из СФ.
         * поведение удаления не тестировалось.
         */
        return instance
            .delete(`/api/ipp/opportunities/${params._id}/delete`)
            .then((res) => {
                console.log('deleteDeal API res: ', res)
                return { data: res.status === 200 }
            })
            .catch((err) => {
                return handlerAPIErrors(err, { info: 'deleteDeal' })
            })
    }
}
