<script setup>
import { useHead } from 'unhead'
import forgotPasswordMeta from '@/constants/meta/account/forgot-password'
import EmailForm from '@/components/forms/password/EmailForm'

useHead(forgotPasswordMeta)
</script>

<script>
export default {
    name: 'ForgotPasswordPage'
}
</script>

<template>
    <div class="forgot-page">
        <h2 class="lg-title">Forgot Password</h2>
        <EmailForm class="forgot-page__form" />
    </div>
</template>
<style lang="scss" scoped>
.forgot-page {
    .lg-title {
        margin-bottom: 32px;
    }
    &__form {
        padding: 0 98px;
        &:deep(.button) {
            display: flex;
            margin: 30px auto 0;
        }
    }
}
</style>
