import BaseImage from '@/components/UI/BaseImage'
import BaseInput from '@/components/UI/BaseInput'
import BaseCheckbox from '@/components/UI/BaseCheckbox'
import BaseCheckboxBox from '@/components/UI/BaseCheckboxBox'
import BaseRadio from '@/components/UI/BaseRadio'
import BaseRadioBox from '@/components/UI/BaseRadioBox'
import BaseSelect from '@/components/UI/BaseSelect'
import BaseButton from '@/components/UI/BaseButton'
import BaseForm from '@/components/UI/BaseForm'
import BaseIcon from '@/components/UI/BaseIcon'
import BaseLink from '@/components/UI/BaseLink'
import BaseBlock from '@/components/UI/BaseBlock'
import BaseTooltip from '@/components/UI/BaseTooltip'
import BaseList from '@/components/UI/BaseList'
import BaseModal from '@/components/UI/BaseModal'
import BaseDate from '@/components/UI/BaseDate'
import BaseTag from '@/components/UI/BaseTag'
import BaseDropdown from '@/components/UI/BaseDropdown'
import BaseFilter from '@/components/UI/BaseFilter'
import BaseBanner from '@/components/UI/BaseBanner'
import BaseTabs from '@/components/UI/BaseTabs'
import BasePagination from '@/components/UI/BasePagination'

export default [
    BaseImage,
    BaseInput,
    BaseCheckbox,
    BaseCheckboxBox,
    BaseRadio,
    BaseRadioBox,
    BaseSelect,
    BaseButton,
    BaseForm,
    BaseIcon,
    BaseLink,
    BaseBlock,
    BaseTooltip,
    BaseList,
    BaseModal,
    BaseDate,
    BaseTag,
    BaseDropdown,
    BaseFilter,
    BaseBanner,
    BaseTabs,
    BasePagination
]
