export const partnerTierOptions = [
    {
        text: 'Standard',
        value: 'Standard'
    },
    {
        text: 'Silver',
        value: 'Silver'
    },
    {
        text: 'Gold',
        value: 'Gold'
    },
    {
        text: 'Platinum',
        value: 'Platinum'
    }
]
