<script setup>
import { ref, computed } from 'vue'
import { useVfm } from 'vue-final-modal'
import { authAPI } from '@/api/auth-api'
import validation from '@/utils/validation'
import { getExternalErrors } from '@/utils/formatting'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'

const emit = defineEmits(['success'])

const vfm = useVfm()
const modalIdSymbol = Symbol('partner-deletion')
const modalSettings = {
    modalId: modalIdSymbol,
    withCloseBtn: false,
    widthContent: 658,
    options: {
        clickToClose: false,
        escToClose: false
    }
}

const form = ref({ email: '' })
const formLabels = { email: 'Email' }
const externalErrors = ref({})
const autoDirty = ref(false)
const rules = computed(() => {
    return {
        email: {
            required: validation.required(formLabels.email),
            validEmail: validation.validEmail
        }
    }
})

const error = ref({ isError: false, errorText: '' })

const openModal = async () => vfm.open(modalIdSymbol)
const cancelModal = () => vfm.close(modalIdSymbol)

const confirmRemoving = async () => {
    await openModal()

    const confirmationBtn = document.getElementById('confirmation-btn')
    const cancelBtn = document.getElementById('cancel-btn')

    const confirmationPromise = new Promise((resolve) => {
        confirmationBtn.addEventListener('click', () => resolve(true))
    })
    const cancelPromise = new Promise((resolve) => {
        cancelBtn.addEventListener('click', () => resolve(false))
    })

    const confirmation = await Promise.any([confirmationPromise, cancelPromise])

    cancelModal()

    return confirmation
}

const handlerForm = async () => {
    try {
        const confirmation = await confirmRemoving()
        console.log('confirmation: ', confirmation)

        if (confirmation) {
            const res = await authAPI.deleteUser(form.value)
            console.log('handlerForm res: ', res)

            if (res.data) {
                emit('success', form.value)
            } else if (res?.errInfo.status === 400) {
                autoDirty.value = true
                externalErrors.value = getExternalErrors(res.errInfo.errors)
            } else {
                throw new Error()
            }
        }
    } catch (err) {
        console.log('User deletion err: ', err)

        error.value.isError = true
        error.value.errorText = 'Failed to delete the Partner'

        pushNotification({
            message: `Failed to delete the Partner with following email: ${form.value.email}. Try again`,
            type: notificationTypes.ERROR
        })
    }
}
</script>

<script>
export default {
    name: 'PartnerDeletionForm'
}
</script>

<template>
    <div class="partner-deletion" ref="wrapper">
        <BaseForm
            :form="form"
            :rules="rules"
            :on-submit="handlerForm"
            :external-errors="externalErrors"
            :auto-dirty="autoDirty"
            :error="error.isError"
            :error-text="error.errorText"
            submit-text="Delete"
            class="partner-deletion__form"
        >
            <template #default="{ validator }">
                <BaseInput
                    type="text"
                    v-model="form.email"
                    :validator="validator.email"
                    :name="formLabels.email"
                    :placeholder="formLabels.email"
                    required
                />
            </template>
            <template #button="{ loading, invalid, submitText, validator }">
                <BaseButton
                    :type="invalid ? 'button' : 'submit'"
                    :loading="loading"
                    :disabled="invalid"
                    @click="invalid ? validator.$touch() : null"
                >
                    {{ submitText }}
                </BaseButton>
            </template>
        </BaseForm>
        <BaseModal v-bind="modalSettings">
            <div class="partner-deletion__modal">
                <h3 class="subtitle">
                    Are you sure you want to delete the Partner with following
                    email:
                    <!-- eslint-disable-next-line -->
                    <span>{{ form.email }}</span>?
                </h3>
                <div class="partner-deletion__modal--actions">
                    <BaseButton
                        size="md"
                        design="outline"
                        id="confirmation-btn"
                    >
                        Delete
                    </BaseButton>
                    <BaseLink id="cancel-btn">Cancel</BaseLink>
                </div>
            </div>
        </BaseModal>
    </div>
</template>

<style lang="scss" scoped>
.partner-deletion {
    &__modal {
        .subtitle {
            text-align: center;
            margin-bottom: 33px;
            span {
                color: #12429d;
            }
        }
        &--actions {
            display: flex;
            gap: 28px;
            justify-content: center;
            align-items: center;
            .link {
                cursor: pointer;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.019em;
                color: #969696;
                text-decoration: none;
                &:hover {
                    color: #969696;
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
