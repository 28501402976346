<script setup>
import { useHead } from 'unhead'
import signupMeta from '@/constants/meta/account/signup'
import SignupForm from '@/components/forms/SignupForm'

useHead(signupMeta)

const signinLink = { text: 'Sign In', to: '/account/signin' }
</script>

<script>
export default {
    name: 'SignupPage'
}
</script>

<template>
    <div class="signup">
        <h2 class="lg-title">Sign Up</h2>
        <p class="text text_center">
            Please fill in the information below to complete your registration
            for the Partner Portal
        </p>
        <SignupForm class="signup__form" />
        <div class="text-note text-note_center">
            Already have account at MSP360?
            <BaseLink :link="signinLink" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.signup {
    width: 100%;
    &__form {
        padding: 0 98px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 22px;

        &:deep(.button) {
            margin-top: 24px;
            align-self: center;
        }
    }

    .lg-title {
        margin-bottom: 12px;
    }
    .text {
        margin-bottom: 24px;
    }
}
</style>
