export default {
    title: 'Admin Page — Deals',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'Admin Page — Deals'
        }
    ]
}
