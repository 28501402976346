import { routes } from '@/router/routes'

const getChildren = (route, parentPath = []) => {
    if (!route.children) {
        if (parentPath) {
            const path = [...parentPath, route.path].join('/')
            return { ...route, path }
        }
        return route
    }

    const locParentPath = [...parentPath, route.path].join('/')
    const locRoute = parentPath ? { ...route, path: locParentPath } : route
    const children = route.children.map((child) =>
        getChildren(child, [...parentPath, route.path])
    )
    return [locRoute, ...children.flat(Infinity)]
}

const getSingleLevelRoutes = (routes) => {
    return routes.map((r) => getChildren(r)).flat(Infinity)
}

export const allRoutes = getSingleLevelRoutes(routes)
