<script setup>
import { computed, ref } from 'vue'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { useStore } from 'vuex'
import { leadStatuses } from '@/constants/leadStatuses'
import LeadStatusAction from '@/components/blocks/LeadStatusAction'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { leadValues } from '@/constants/leadValues'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'

const store = useStore()

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    lead: { type: Object, required: true },
    values: { type: Array, required: true }
})
const classes = computed(() => {
    const classes = ['leads-row']

    classes.push(`leads-row_col-${props.values.length}`)

    if (props.mode) {
        classes.push(`leads-row_${props.mode}`)
    }

    return classes
})
const enumClasses = {
    [leadStatuses.COLD]: 'pale-blue',
    [leadStatuses.APPROACHING]: 'orange',
    [leadStatuses.UNRESPONSIVE]: 'purple',
    [leadStatuses.BAD_CONTACT_INFO]: 'maroon',
    [leadStatuses.INTERESTED]: 'green',
    [leadStatuses.NOT_INTERESTED]: 'grey'
}

const isStatusLoading = ref(false)
const updateLeadStatus = async (newStatus) => {
    isStatusLoading.value = true
    try {
        await store.dispatch('updateLeadStatus', {
            id: props.lead._id,
            status: newStatus
        })
    } catch (err) {
        pushNotification({
            message: `<span>${err.name}</span> ${err.message}`,
            type: notificationTypes.ERROR
        })
    }
    isStatusLoading.value = false
}
</script>
<script>
export default {
    name: 'LeadsListRow'
}
</script>

<template>
    <div :class="classes">
        <div
            class="leads-row__item"
            v-for="(item, index) in values"
            :key="index"
            :class="
                item.value === leadValues.ACTION_STATUS
                    ? 'leads-row__item_jce'
                    : null
            "
        >
            <template v-if="item.value === leadValues.ACTION_STATUS">
                <LeadStatusAction
                    v-if="
                        checkHasPermissions([
                            permissionsEnum.UPDATE_LEAD_STATUS
                        ])
                    "
                    :modal-id="lead._id"
                    :status="lead.status"
                    @onStatusChange="updateLeadStatus"
                />
                <template v-else>—</template>
            </template>
            <template v-if="item.value === leadValues.STATUS">
                <BaseTag v-if="isStatusLoading" styling="loading">
                    Loading
                    <LoadingSpinner />
                </BaseTag>
                <BaseTag
                    v-else
                    :styling="enumClasses[lead[item.value]]"
                    :text="lead[item.value]"
                />
            </template>
            <template v-else>
                {{ lead[item.value] }}
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.leads-row {
    display: grid;
    padding: 10px 0;
    &_col-5 {
        grid-template-columns: repeat(5, 1fr);
    }
    &_col-6 {
        grid-template-columns: repeat(6, 1fr);
    }

    &:not(:last-child) {
        border-bottom: 1px solid #f4f6fa;
    }
    &__item {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.026em;
        color: #757575;
        display: flex;
        align-items: center;

        &_jce {
            justify-content: flex-end;
        }
    }
}
</style>
