<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useHead } from 'unhead'
import _ from 'lodash'
import meta from '@/constants/meta/admin/products'

import useFilters from '@/composables/useFilters'
import useConfirm from '@/composables/useConfirm'

import AdminLayout from '@/components/blocks/admin/AdminLayout'
import AdminTable from '@/components/blocks/admin/AdminTable'
import AdminAction from '@/components/blocks/admin/AdminAction'
import FilterWrapper from '@/components/filters/FilterWrapper'
import ConfirmationModal from '@/components/common/ConfirmationModal'
import ProductRegistrationFormForAdmin from '@/components/forms/admin/ProductRegistrationFormForAdmin'

useHead(meta)
const store = useStore()

const deleteActionId = Symbol('delete-confirmafion')

const tableColumns = [
    {
        target: 'name',
        title: 'Name'
    },
    {
        target: 'commonName',
        title: 'Common Name'
    },
    {
        target: 'alias',
        title: 'Alias'
    },
    {
        slug: 'prices',
        title: 'Price'
    },
    {
        target: 'group',
        title: 'Group'
    },
    {
        target: 'os',
        title: 'OS'
    },
    {
        target: 'description',
        title: 'Description',
        longText: true
    },
    {
        slug: 'actions',
        sticky: true
    }
]
const deleteProduct = async (product) => {
    editableProduct.value = product
    const [getConfirmation] = useConfirm(deleteActionId)
    const confirmation = await getConfirmation()

    if (confirmation) {
        store.dispatch('admin/deleteProduct', product)
    }
}

const getActions = (product) => {
    return [
        {
            icon: 'delete',
            hint: {
                content: 'Delete'
            },
            cb: deleteProduct.bind(this, product)
        }
    ]
}

const products = computed(() => store.getters['admin/getProducts'](limit.value))
const limit = ref(10)
const currentPage = ref(1)
const editableProduct = ref({})

const filtersContent = {
    name: {
        label: {
            id: 'name',
            text: 'By Name'
        },
        filter: {
            type: 'search',
            id: 'name',
            name: 'name',
            placeholder: 'Enter Name'
        }
    },
    alias: {
        label: {
            id: 'alias',
            text: 'By Alias'
        },
        filter: {
            type: 'search',
            id: 'alias',
            name: 'alias',
            placeholder: 'Enter Alias'
        }
    },
    group: {
        label: {
            id: 'group',
            text: 'By Group'
        },
        filter: {
            type: 'search',
            id: 'group',
            name: 'group',
            placeholder: 'Enter Group'
        }
    },
    os: {
        label: {
            id: 'os',
            text: 'By OS'
        },
        filter: {
            type: 'search',
            id: 'os',
            name: 'os',
            placeholder: 'Enter OS'
        }
    }
}

const { filters, isFilterSet, resetFilters } = useFilters({
    name: '',
    alias: '',
    group: '',
    os: ''
})

const params = computed(() => {
    return {
        withPrices: true,
        limit: limit.value,
        offset: products.value.list.length * (currentPage.value - 1),
        isFilterSet: isFilterSet.value,
        ...filters.value
    }
})

onMounted(() => {
    store.dispatch('admin/fetchProducts', params.value)
})

const debouncedFetchProducts = _.debounce(() => {
    store.dispatch('admin/fetchProducts', params.value)
}, 1000)

watch(filters.value, debouncedFetchProducts)
</script>
<script>
export default {
    name: 'AdminProductsPage'
}
</script>

<template>
    <AdminLayout :loading="products.loading">
        <template #filter>
            <FilterWrapper
                v-for="(item, index) in filtersContent"
                :key="index"
                :label="filtersContent[index].label"
            >
                <BaseInput
                    v-bind="filtersContent[index].filter"
                    v-model="filters[index]"
                />
            </FilterWrapper>
        </template>
        <template #filter-footer>
            <BaseButton design="outline-blue" @click="resetFilters">
                Reset
            </BaseButton>
        </template>
        <template #table>
            <AdminTable :table="tableColumns" :items="products.list">
                <template #prices="item">${{ item.prices[0].price }}</template>
                <template #actions="item">
                    <AdminAction
                        v-for="(action, index) in getActions(item)"
                        :key="index"
                        :action="action"
                    />
                </template>
            </AdminTable>
        </template>
        <template #toolbar>
            <BaseButton icon="plus">Add Product</BaseButton>
        </template>
        <template #footer>
            <BasePagination
                v-model="currentPage"
                :total-items="products.total"
                @click="() => store.dispatch('admin/fetchProducts', params)"
            />
        </template>
    </AdminLayout>
    <ConfirmationModal :id="deleteActionId" text-on-resolve="Delete">
        Are you sure you want to delete the Product
        <!-- eslint-disable -->
        <span>{{ editableProduct.name }}</span>?
    </ConfirmationModal>
    <div style="padding: 20px; background: #ffffff">
        <ProductRegistrationFormForAdmin />
    </div>
</template>
