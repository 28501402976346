import store from '@/store'
import { notificationTypes } from '@/constants/notificationTypes'

export const pushNotification = ({
    message,
    type = notificationTypes.INFO
}) => {
    store.commit('SET_NOTIFICATION', {
        message,
        type
    })
}
