import { createStore } from 'vuex'
import { auth } from './modules/auth'
import { leads } from './modules/leads'
import { deals } from './modules/deals'
import { notification } from './modules/notification'
import { partners } from './modules/partners'
import { admin } from './modules/admin'

const store = createStore({
    modules: {
        auth,
        leads,
        deals,
        notification,
        partners,
        admin
    }
})

export default store
