<script setup>
import { computed } from 'vue'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    text: { type: String, required: true },
    options: { type: Array, required: true },
    placement: { type: String, default: 'bottom-start' },
    container: { type: String, default: 'body' },
    onClickItem: { type: Function, default: () => ({}) }
})
const classes = computed(() => {
    const classes = ['base-dropdown']

    classes.push(`base-dropdown_${props.mode}`)

    return classes
})
</script>
<script>
export default {
    name: 'BaseDropdown'
}
</script>

<template>
    <VDropdown :placement="placement" :class="classes" :container="container">
        <!-- placement="bottom-end" -->
        <!-- container=".base-dropdown" -->
        <div class="base-dropdown__header">
            <slot name="text">{{ text }}</slot>
            <BaseIcon class="base-dropdown__after" i="chevron" />
        </div>
        <template #popper="{ hide }">
            <div class="base-dropdown__body">
                <slot name="options" v-bind="{ options, closeDropdown: hide }">
                    <div
                        v-for="(option, index) in options"
                        :key="index"
                        @click="onClickItem(option, hide)"
                    >
                        {{ option }}
                    </div>
                </slot>
            </div>
        </template>
    </VDropdown>
</template>

<style lang="scss" scoped>
.base-dropdown {
    &__header {
        display: flex;
        align-items: center;
        margin-right: 8px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.019em;
        color: #b5b5b5;
    }
    &__after {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        transition: 0.2s ease-in-out;
        color: #f74e00;
    }
    &__body {
        background: #ffffff;
        box-shadow: 0px 4px 16px #0000001a;
        border-radius: 8px;
        color: #636363;
        padding: 16px;
        font-size: 14px;
        line-height: 16px;

        &:deep(*) {
            cursor: pointer;

            &:hover {
                color: #f74e00;
            }
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}
</style>
<style lang="scss">
.base-dropdown.v-popper--shown {
    .base-dropdown__after {
        transform: rotate(180deg);
    }
}

body .v-popper--theme-dropdown .v-popper__arrow-container {
    display: none !important;
}
</style>
