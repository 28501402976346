<script setup>
defineProps({
    tier: { type: String, default: '' },
    discount: { type: Number, default: 0 }
})
</script>

<script>
export default {
    name: 'CalculatorLevel'
}
</script>

<template>
    <div
        v-if="tier && discount"
        class="calculator-level"
        :class="[`calculator-level_${tier.toLowerCase()}`]"
    >
        <div class="calculator-level__wrap">
            <div class="calculator-level__discount">{{ discount }}%</div>
            <div class="calculator-level__text">Margin Level</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.calculator-level {
    width: 186px;
    height: 162px;
    background-size: contain;
    background-repeat: no-repeat;
    font-family: ReadexPro, sans-serif;
    font-weight: 600;
    color: #ffffff;
    margin: 44px auto 0;
    position: relative;
    user-select: none;

    @include media_max_xl {
        width: 150px;
        height: 131px;
    }
    &__wrap {
        position: absolute;
        top: 6px;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 0);
        text-align: center;
        @include media_max_xl {
            top: 3px;
        }
    }
    &__discount {
        font-size: 34px;
        line-height: 46px;
        @include media_max_xl {
            font-style: 28px;
            line-height: 38px;
        }
    }
    &__text {
        margin-top: -8px;
        font-size: 13px;
        line-height: 17px;
        @include media_max_xl {
            margin-top: -4px;
            font-size: 11px;
            line-height: 14px;
        }
    }

    &_silver {
        background-image: url('@/assets/images/quote-calculator/level-silver.svg');
    }
    &_gold {
        background-image: url('@/assets/images/quote-calculator/level-gold.svg');
    }
    &_platinum {
        background-image: url('@/assets/images/quote-calculator/level-platinum.svg');
    }
}
</style>
