<script setup>
import { watch } from 'vue'
import CalculatorProductBlock from '@/components/blocks/CalculatorProductBlock'
import CalculatorProductInfo from '@/components/blocks/CalculatorProductInfo'
import { rmmWatchers } from '@/constants/quote-calculator-new'

/* eslint-disable */
const props = defineProps({
    mainBlocks: { type: Array, required: true },
    chosenProducts: { type: Object, required: true }
})

const getProduct = ([os, alias]) => {
    return +props.chosenProducts[alias][os]
}
const checkRmmAndDeepInstinct = ([rmmKeys, diKeys]) => {
    const rmm = getProduct(rmmKeys)
    const di = getProduct(diKeys)

    if (rmm === 0 && di > 0) {
        const [os, alias] = rmmKeys
        props.chosenProducts[alias][os] = 1
    }
}

watch(
    [
        ...Object.entries(rmmWatchers).reduce((acc, [os, alias]) => {
            acc.push(() => props.chosenProducts[alias][os])
            return acc
        }, [])
    ],
    () => checkRmmAndDeepInstinct(Object.entries(rmmWatchers))
)
</script>
<script>
export default {
    name: 'CalculatorMainProducts'
}
</script>

<template>
    <CalculatorProductBlock
        v-for="(block, blockIndex) in mainBlocks"
        :key="blockIndex"
        v-bind="block.header"
        class="calculator-products"
    >
        <div
            v-for="(subgroup, subgroupIndex) in block.body"
            :key="subgroupIndex"
            class="calculator-products__subgroup"
        >
            <div class="calculator-products__header">
                <div class="calculator-products__icon-wrap">
                    <BaseIcon :i="`calc-${subgroup.icon}`" />
                </div>
                <h4
                    class="calculator-products__subtitle"
                    v-html="subgroup.title"
                />
            </div>
            <div class="calculator-products__body">
                <div
                    v-for="(product, productIndex) in subgroup.products"
                    :key="productIndex"
                    class="calculator-products__product"
                >
                    <BaseInput
                        v-model="chosenProducts[product.alias][subgroup.os]"
                        type="number"
                        :theme="product.group"
                        type-number-with-btns
                        sm-padding
                        class="calculator-products__input"
                        :class="{
                            'calculator-products__input_val':
                                chosenProducts[product.alias][subgroup.os]
                        }"
                    />
                    <CalculatorProductInfo
                        :theme="block.theme"
                        :product-info="product"
                    />
                </div>
            </div>
        </div>
    </CalculatorProductBlock>
</template>

<style scoped lang="scss">
.calculator-products {
    &__subgroup {
        display: grid;
        grid-template-columns: 180px 1fr;

        &:not(:last-child) {
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        align-self: baseline;
    }
    &__icon-wrap {
        display: flex;
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 5px;
        margin-right: 8px;

        @include media_md {
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
        }

        .icon {
            width: 100%;
            height: 100%;
        }
    }
    &__subtitle {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        margin-bottom: 0;
        user-select: none;

        &:deep(.small) {
            display: block;
            font-size: 10px;
            line-height: 16px;
        }
    }
    &__body {
        display: grid;
        align-items: start;
        grid-template-columns: 250px repeat(3, auto);
        @include media_max_xl {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &__product {
        display: flex;
        align-items: center;
    }
    &__input {
        margin-right: 8px;
        &:deep(.input-block__input_num-block input[type='number']) {
            width: 30px;
        }
    }

    &.product-block_orange {
        .calculator-products {
            &__subgroup {
                border-color: $calc-product-primary-subgroup-border-color;
            }
            &__icon-wrap {
                background-color: $calc-product-primary-icon-bg;
                box-shadow: 0px 4px 4px $calc-product-primary-icon-shadow-color;
            }
            &__subtitle {
                color: $mbs-color;
            }
            &__input_val {
                &:deep(.input-block__input_num-block) {
                    border: 1px solid $mbs-color !important;
                }
            }
        }
    }
    &.product-block_green {
        .calculator-products {
            &__subgroup {
                border-color: $calc-product-secondary-subgroup-border-color;
            }
            &__icon-wrap {
                background-color: $calc-product-secondary-icon-bg;
                box-shadow: 0px 4px 4px
                    $calc-product-secondary-icon-shadow-color;
            }
            &__subtitle {
                color: $rmm-color;
            }
            &__input_val {
                &:deep(.input-block__input_num-block) {
                    border: 1px solid $rmm-color !important;
                }
            }
        }
    }
    &.product-block_blue {
        .calculator-products {
            &__subgroup {
                border-color: $calc-product-tertiary-subgroup-border-color;
            }
            &__icon-wrap {
                background-color: $calc-product-tertiary-icon-bg;
                box-shadow: 0px 4px 4px $calc-product-tertiary-icon-shadow-color;
            }
            &__subtitle {
                color: $connect-color;
            }
            &__input_val {
                &:deep(.input-block__input_num-block) {
                    border: 1px solid $connect-color !important;
                }
            }
        }
    }
}
</style>
