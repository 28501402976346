import { layouts, layoutComponents } from '@/constants/layoutTypes'

export async function loadLayoutMiddleware(route) {
    const { layout } = route.meta
    const normalizedLayoutName = layout || layouts.default
    const fileName = layoutComponents[normalizedLayoutName]
    const fileNameWithoutExtention = fileName.split('.vue')[0]

    const component = await import(`@/layouts/${fileNameWithoutExtention}.vue`)
    route.meta.layoutComponent = component.default
}
