<script setup>
import { computed } from 'vue'

const props = defineProps({
    modelValue: { type: Array, required: true },
    validator: { type: [Object, Boolean], default: false },
    required: { type: Boolean, default: false },
    title: { type: String, default: '' },
    options: { type: Array, required: true }
})
const classes = computed(() => {
    const classes = ['checkbox-box']

    if (props.required) {
        classes.push(`checkbox-box_required`)
    }

    return classes
})

const emit = defineEmits(['update:modelValue'])

const handlerValue = (newValue) => {
    if (props.modelValue.includes(newValue)) {
        emit(
            'update:modelValue',
            props.modelValue.filter((value) => value !== newValue)
        )
    } else {
        emit('update:modelValue', [...props.modelValue, newValue])
    }
}

const errorText = computed(() => {
    return (
        (props.validator.$error && props.validator.$errors[0]?.$message) ||
        (props.validator.$externalResults &&
            props.validator.$externalResults[0]?.$message)
    )
})
</script>

<script>
export default {
    name: 'BaseCheckboxBox'
}
</script>

<template>
    <div :class="classes">
        <div class="checkbox-box__title">
            {{ title }}
        </div>
        <div class="checkbox-box__wrap">
            <BaseCheckbox
                v-for="option in options"
                :key="option.value"
                :id="option.value"
                :text="option.text"
                :model-value="modelValue.includes(option.value)"
                @update:modelValue="handlerValue(option.value)"
            />
        </div>
        <span
            v-if="validator.$error || validator.$externalResults"
            class="checkbox-box__helper"
        >
            {{ errorText }}
        </span>
    </div>
</template>

<style scoped lang="scss">
.checkbox-box {
    &__title {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.026em;
        color: #757575;
        margin-bottom: 20px;
    }
    &__helper {
        font-family: 'Roboto', sans-serif;
        background-color: $primary-label-bg;
        color: $warning-text-color;
        font-size: 12px;
        line-height: 16px;
    }
    &_required {
        .checkbox-box__title {
            &:after {
                content: '*';
                color: $warning-text-color;
                padding-left: 5px;
            }
        }
    }
}
</style>
