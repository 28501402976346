<script setup>
import CalculatorProductPrice from '@/components/blocks/CalculatorProductPrice'
import { computed } from 'vue'
const props = defineProps({
    theme: { type: String, default: '' },
    productInfo: { type: Object, required: true }
})

const tooltipBgColor = computed(() => {
    return props.theme ? `${props.theme}-light` : 'gray'
})
const priceTheme = computed(() => props.theme || 'orange')
</script>

<script>
export default {
    name: 'CalculatorProductInfo'
}
</script>

<template>
    <div class="product-info">
        <span class="product-info__title">
            {{ productInfo.name }}
        </span>
        <BaseTooltip
            :content="productInfo.description"
            :bg-color="tooltipBgColor"
        />
        <CalculatorProductPrice
            :theme="priceTheme"
            :price="productInfo.price"
            :discount-price="productInfo.discountPrice"
        />
    </div>
</template>

<style scoped lang="scss">
.product-info {
    font-size: 14px;
    line-height: 16px;
    color: $default-text-color;

    &__title {
        font-family: Roboto, sans-serif;
        user-select: none;
        margin-right: 4px;
    }
}
</style>
