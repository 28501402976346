<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { roles } from '@/constants/rbac/roles'
const store = useStore()

const substitutePartnerID = computed(() => store.getters.substitutePartnerID)
const amountActiveLeads = computed(() => store.getters.amountActiveLeads)
const amountArchivedLeads = computed(() => store.getters.amountArchivedLeads)
const currentUser = computed(() => store.getters.currentUser)
const isValidCurrentRole = computed(() => {
    return store.getters.isValidCurrentRole([roles.ADMIN, roles.VIEWER])
})

const basicRequest = (params) => {
    const localParams = {
        ...params,
        limit: 1
    }

    if (substitutePartnerID.value) {
        localParams.assignedPartner = substitutePartnerID.value
    } else if (isValidCurrentRole.value) {
        localParams.assignedPartner = currentUser.value.assignedPartner
    }

    store.dispatch('getAmountLeads', localParams)
}
if (!amountActiveLeads.value) {
    basicRequest({ archive: false })
}
if (!amountArchivedLeads.value) {
    basicRequest({ archive: true })
}

watch(substitutePartnerID, () => {
    basicRequest({ archive: false })
    basicRequest({ archive: true })
})

const activeLeads = {
    text: 'Active',
    to: '/leads'
}
const archivedLeads = {
    text: 'Closed',
    to: '/leads/archived'
}
</script>

<script>
export default {
    name: 'LeadsPanel'
}
</script>

<template>
    <div class="leads-panel">
        <BaseLink class="leads-panel__link" :link="archivedLeads">
            {{ archivedLeads.text }}
            <Transition>
                <span v-if="amountArchivedLeads" class="leads-panel__count">
                    {{ amountArchivedLeads }}
                </span>
            </Transition>
        </BaseLink>
        <BaseLink class="leads-panel__link" :link="activeLeads">
            {{ activeLeads.text }}
            <Transition>
                <span v-if="amountActiveLeads" class="leads-panel__count">
                    {{ amountActiveLeads }}
                </span>
            </Transition>
        </BaseLink>
    </div>
</template>

<style lang="scss" scoped>
.leads-panel {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    &__link.link {
        text-decoration: none;
        background-color: #e7e9f2;
        min-width: 180px;
        padding: 10px;
        color: #969696;
        text-align: center;
        position: relative;
        transition: 0.2s linear;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        &:first-child {
            border-top-left-radius: 20px;
        }
        &:last-child {
            border-top-right-radius: 20px;
        }

        &:hover,
        &.router-link-active {
            background-color: #ffffff;
            color: #f74e00;
        }
    }
}
.v-enter-active,
.v-leave-active {
    transition: all 0.2s ease-out;
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
    transform: scale(0);
}
</style>
