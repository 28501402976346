<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import validation from '@/utils/validation'
import { getExternalErrors } from '@/utils/formatting'
import { productGroupOptions } from '@/constants/products'

const emit = defineEmits(['success'])

const store = useStore()

const formLabels = {
    name: 'Name',
    commonName: 'Common Name',
    alias: 'Alias',
    price: 'Price',
    group: 'Group',
    os: 'OS',
    description: 'Description'
}
const formPlaceholders = {
    name: 'Name',
    commonName: 'Common Name',
    alias: 'Alias',
    price: 'Price',
    group: 'Group',
    os: 'OS',
    description: 'Description'
}
const getInitForm = () => {
    return {
        name: '',
        commonName: '',
        alias: '',
        price: '',
        group: '',
        os: '',
        description: ''
    }
}
const form = ref(getInitForm())
const error = ref({ isError: false, errorText: '' })
const autoDirty = ref(false)
const externalErrors = ref({})

const rules = computed(() => {
    return {
        name: {
            required: validation.required(formLabels.name)
        },
        commonName: {
            required: validation.required(formLabels.commonName)
        },
        alias: {
            required: validation.required(formLabels.alias)
        },
        price: {
            required: validation.required(formLabels.price)
        },
        group: {
            required: validation.required(formLabels.group)
        },
        os: {
            required: validation.required(formLabels.os)
        },
        description: {
            required: validation.required(formLabels.description)
        }
    }
})

const handlerForm = async ({ price, ...formData }) => {
    const preparedForm = {
        prices: [
            {
                from: 1,
                to: 2147483647,
                price: +price
            }
        ],
        ...formData
    }
    try {
        console.log('preparedForm: ', preparedForm)
        const res = await store.dispatch('admin/registerProduct', preparedForm)
        console.log('registerProduct FORM res: ', res)

        if (res.data) {
            emit('success', preparedForm)
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true
            externalErrors.value = getExternalErrors(res.errInfo.errors)
        } else {
            throw new Error()
        }
    } catch (err) {
        error.value.isError = true
        error.value.errorText = 'An error occurred while adding a new product'
    }
}

const resetForm = () => Object.assign(form.value, getInitForm())
</script>

<script>
export default {
    name: 'ProductRegistrationFormForAdmin'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        submit-text="Add New Product"
        :on-submit="handlerForm"
        :error="error.isError"
        :error-text="error.errorText"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        reset-form
        :on-reset="resetForm"
    >
        <template #default="{ validator }">
            <BaseInput
                v-model.trim="form.name"
                :validator="validator.name"
                :name="formLabels.name"
                :placeholder="formPlaceholders.name"
                required
            />
            <BaseInput
                v-model.trim="form.commonName"
                :validator="validator.commonName"
                :name="formLabels.commonName"
                :placeholder="formPlaceholders.commonName"
                required
            />
            <BaseInput
                v-model.trim="form.alias"
                :validator="validator.alias"
                :name="formLabels.alias"
                :placeholder="formPlaceholders.alias"
                required
            />
            <BaseInput
                type="currency"
                v-model="form.price"
                :validator="validator.price"
                :name="formLabels.price"
                :placeholder="formPlaceholders.price"
                required
            />
            <BaseSelect
                :options="productGroupOptions"
                v-model="form.group"
                :validator="validator.group"
                :name="formLabels.group"
                :placeholder="formPlaceholders.group"
                required
            />
            <BaseInput
                v-model.trim="form.os"
                :validator="validator.os"
                :name="formLabels.os"
                :placeholder="formPlaceholders.os"
                required
            />
            <BaseInput
                type="textarea"
                v-model.trim="form.description"
                :validator="validator.description"
                :name="formLabels.description"
                :placeholder="formPlaceholders.description"
                required
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
