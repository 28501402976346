<script setup>
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import UserSettingsInfo from '@/components/blocks/UserSettingsInfo.vue'

defineProps({
    users: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
    intersection: { type: Boolean, default: false }
})

const titles = ['Users / email', 'Role', 'Status', 'Action']
</script>
<script>
export default {
    name: 'UserSettingsBlock'
}
</script>

<template>
    <div class="users-settings">
        <div
            v-if="loading && !users.length"
            class="users-settings__init-loading"
        >
            <LoadingSpinner size="lg" />
        </div>
        <div v-else-if="!loading && !users.length" class="text">
            At the moment there are no registered users.
        </div>
        <div v-else>
            <div class="users-settings__header text">
                <div v-for="(title, index) in titles" :key="index">
                    {{ title }}
                </div>
            </div>
            <div class="users-settings__body">
                <UserSettingsInfo
                    v-for="(user, index) in users"
                    :key="index"
                    :user="user"
                />
                <div v-if="intersection" v-intersection />
            </div>
            <div v-if="loading" class="users-settings__loading">
                <LoadingSpinner />
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.users-settings {
    &__init-loading {
        position: absolute;
        top: 50%;
        left: calc((100% + 384px) / 2);
        transform: translate(-50%, 0);
    }
    &__loading {
        padding: 16px;
        text-align: center;
    }
    &__header {
        display: grid;
        grid-template-columns: 2.2fr 1.2fr 1fr 2.5fr;
        gap: 16px;
        margin-left: calc(64px + 16px);
        padding: 0 24px 16px 24px;
    }
    &__body {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
</style>
