<script setup>
import { computed, ref, useAttrs, watch } from 'vue'
import {
    phoneCallback,
    getMask,
    getInitialFormattedValue,
    getInitialCountryISO
} from '@/utils/countries-phone-masks'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    theme: { type: String, default: 'light' }, // light, blue, mbs, connect, rmm
    validator: { type: [Object, Boolean], default: false },
    placeholder: { type: String, default: '' },
    modelValue: { type: [String, Number], default: '' },
    required: { type: Boolean, default: false },
    smPadding: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    typeNumberWithBtns: { type: Boolean, default: false },
    numStep: { type: Number, default: 1 },
    minValue: { type: Number, default: 0 }
})

const $attrs = useAttrs()
const attrs = computed(() => {
    return {
        ...$attrs,
        onFocus() {
            if (props.type === 'number') {
                if (value.value === props.minValue) {
                    emit('update:modelValue', '')
                }
            } else if (props.type === 'tel') {
                isInputFocus.value = true
            }
            $attrs.onFocus && $attrs.onFocus() // TODO monitor behavior
        },
        onBlur() {
            if (props.type === 'number') {
                if (value.value === '' || value.value < props.minValue) {
                    emit('update:modelValue', props.minValue)
                }
            } else if (props.type === 'tel') {
                isInputFocus.value = false
            }
            $attrs.onBlur && $attrs.onBlur() // TODO monitor behavior
        },
        onOnInput(number, phoneObject) {
            if (props.type === 'tel') {
                const country = phoneObject.country?.iso2 || 'us'
                const phone = phoneCallback(number, country)
                maxLength.value = phone.maxLength
                formattedValue.value = phone.value
                emit('update:modelValue', phoneObject.number)
            }
        },
        onCountryChanged(countryObject) {
            if (props.type === 'tel') {
                const masks = getMask(countryObject.iso2)
                mask.value = Array.isArray(masks)
                    ? masks.find((el) => el.length >= value.value.length) // TODO check value.value fot tel (or modelValue ?)
                    : masks
            }
        }
    }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
    get() {
        if (props.type === 'currency' && props.modelValue) {
            return '$' + props.modelValue.replace(/[^0-9/.]/g, '')
        } else {
            return props.modelValue
        }
    },
    set(value) {
        if (props.type === 'currency') {
            emit('update:modelValue', value.replace('$', ''))
        } else {
            emit('update:modelValue', value)
        }
    }
})
const errorText = computed(() => {
    return (
        (props.validator.$error && props.validator.$errors[0]?.$message) ||
        (props.validator.$externalResults &&
            props.validator.$externalResults[0]?.$message)
    )
})

const classes = computed(() => {
    const classes = ['input-block']

    if (props.mode) {
        classes.push(`input-block_${props.mode}`)
    }
    if (props.theme) {
        classes.push(`input-block_theme-${props.theme}`)
    }
    if (props.smPadding) {
        classes.push(`input-block_sm-padding`)
    }
    if (props.required) {
        classes.push(`input-block_required`)
    }
    if (errorText.value) {
        classes.push(`input-block_error`)
    }
    if (props.modelValue.toString()) {
        classes.push(`input-block_has-value`)
    }

    return classes
})

// for password input
const passwordType = ref('password')
const passwordIcon = ref('crossed-eye')
const switchPassword = () => {
    if (props.type === 'password') {
        passwordType.value =
            passwordType.value === 'password' ? 'text' : 'password'
        passwordIcon.value =
            passwordType.value === 'password' ? 'crossed-eye' : 'open-eye'
    }
}

// for tel input
const initFormattedValue = () => {
    return props.type === 'tel' && value.value
        ? getInitialFormattedValue(value.value)
        : ''
}
const initDefaultCountry = () => {
    return props.type === 'tel' && value.value
        ? getInitialCountryISO(value.value).iso
        : ''
}

const formattedValue = ref(initFormattedValue())
const defaultCountry = ref(initDefaultCountry())
const isInputFocus = ref(false)
const mask = ref('')
const maxLength = ref(null)
const inputTelOptions = computed(() => {
    return {
        maxlength: maxLength.value,
        placeholder: isInputFocus.value ? mask.value : ''
    }
})

watch(value, () => {
    if (props.type === 'tel' && value.value === '') {
        formattedValue.value = ''
    }
})

// for number input
const decrease = () => {
    if (props.type === 'number' && +value.value > props.minValue) {
        emit('update:modelValue', +value.value - props.numStep)
    }
}
const increase = () => {
    if (props.type === 'number') {
        emit('update:modelValue', +value.value + props.numStep)
    }
}
</script>

<script>
export default {
    name: 'BaseInput'
}
</script>

<template>
    <label :class="classes">
        <vue-tel-input
            v-if="type === 'tel'"
            class="input-block__input input-block__input_tel"
            :default-country="defaultCountry"
            v-model="formattedValue"
            v-bind="attrs"
            :input-options="inputTelOptions"
        />
        <textarea
            v-else-if="type === 'textarea'"
            class="input-block__input input-block__input_textarea"
            v-model="value"
            v-bind="attrs"
        />
        <div
            v-else-if="type === 'number'"
            class="input-block__input input-block__input_num-block"
            :class="{
                'input-block__input_value-disable': +value === minValue
            }"
        >
            <div
                v-if="typeNumberWithBtns"
                tabindex="0"
                @click="decrease"
                @keyup.enter="decrease"
                class="input-block__num-btn"
            >
                <BaseIcon i="calc-minus" />
            </div>
            <input
                :type="type"
                v-model="value"
                v-bind="attrs"
                :min="minValue"
            />
            <div
                v-if="typeNumberWithBtns"
                tabindex="0"
                @click="increase"
                @keyup.enter="increase"
                class="input-block__num-btn"
            >
                <BaseIcon i="calc-plus" />
            </div>
        </div>
        <input
            v-else-if="type === 'currency'"
            type="text"
            class="input-block__input"
            v-model="value"
            v-bind="attrs"
        />
        <div
            v-else-if="type === 'password'"
            class="input-block__input input-block__input_password"
        >
            <input :type="passwordType" v-model="value" v-bind="attrs" />
            <BaseIcon
                tabindex="0"
                :i="passwordIcon"
                @click="switchPassword"
                @keyup.enter="switchPassword"
            />
        </div>
        <input
            v-else
            :type="type"
            class="input-block__input"
            v-model="value"
            v-bind="attrs"
        />

        <span class="input-block__label">{{ placeholder }}</span>
        <span
            v-if="validator.$error || validator.$externalResults"
            class="input-block__helper"
        >
            {{ errorText }}
        </span>
    </label>
</template>

<style scoped lang="scss">
.input-block {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: block;
    padding: 8px 0;
    font-family: 'Roboto', sans-serif;

    &__input {
        width: 100%;
        outline: none;
        padding: 10px 20px;
        background-color: transparent;
        border-radius: 32px;
        transition: 0.3s;
        position: relative;
        // z-index: 1;

        &_textarea ~ .input-block__label {
            top: 34px;
        }
        &_textarea ~ .input-block__helper {
            transform: translate(0, calc(-50% - 15px));
        }

        &_num-block {
            display: flex;
            justify-content: space-between;

            input[type='number'] {
                outline: none;
                border: none;
                width: 50px;
                text-align: center;
                -moz-appearance: textfield;
                background: transparent;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }
            ~ .input-block__helper {
                background-color: #ffffff;
                transform: translate(0, -16px);
                left: 12px;
                max-width: calc(100% - 24px);
                z-index: 2;
            }
        }

        &_password {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px 0 0;
            overflow: hidden;
            input {
                padding: 10px 0 10px 20px;
                outline: none;
                border: none;
                background: transparent;
                width: 100%;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }
            .icon {
                width: 24px;
                height: 24px;
                flex: 0 0 24px;
                cursor: pointer;
            }
        }

        &_value-disable {
            input[type='number'] {
                color: #a2a3a4;
            }
        }

        &_tel {
            &:deep(.vti__country-code) {
                font-size: 14px;
                line-height: 16px;
            }
            &:deep(.vti__dropdown) {
                padding: 0;
                background-color: transparent;
                &.open {
                    z-index: 3;
                }
            }
            &:deep(.vti__dropdown-list) {
                color: $secondary-field-text-color;
                strong {
                    font-weight: 500;
                }
            }
            &:deep(.vti__dropdown-item) {
                padding: 4px 0px;
            }
            &:deep(.vti__input) {
                background-color: transparent;
            }
            &.vue-tel-input:focus-within {
                box-shadow: none;
            }
        }
        &_tel ~ .input-block__label {
            left: 100px; // TODO change label position
        }
    }
    &__num-btn {
        cursor: pointer;
        .icon {
            width: 14px;
            height: 14px;
        }
    }
    &__label {
        position: absolute;
        cursor: text;
        // top: 32px;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
        transition: 0.3s;
        z-index: 0;
    }

    &:deep(.vue-tel-input:focus-within ~ .input-block__label),
    &__input:focus ~ .input-block__label,
    &__input_password:focus-within ~ .input-block__label,
    &_has-value .input-block__label {
        padding: 0 2px;
        // top: 10px;
        top: 9px;
        left: 20px;
        font-size: 12px;
        line-height: 16px;
        z-index: 1;
    }

    &__helper {
        position: absolute;
        top: 100%;
        transform: translate(0, calc(-50% - 10px));
        left: 20px;
        font-size: 12px;
        line-height: 16px;
        padding: 0 4px;
        display: inline-block;
        max-width: calc(100% - 32px);
        z-index: 1;
    }

    &_theme-light {
        .input-block__label {
            background-color: $primary-label-bg;
            color: $secondary-field-text-color;
        }
        .input-block__helper {
            background-color: $primary-label-bg;
            color: $warning-text-color;
        }

        .input-block__input {
            border: 1px solid $primary-border-color;
            color: $primary-field-text-color;
        }
        &:hover .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid $hover-border-color;
        }
        &:focus-within .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid $focus-border-color;
        }

        &.input-block_error {
            .input-block__input {
                border: 1px solid $warning-border-color;
            }
        }
    }
    &_theme-blue {
        .input-block__label {
            background-color: $secondary-label-bg;
            color: $secondary-field-text-color;
        }
        .input-block__helper {
            background-color: $secondary-label-bg;
            color: $warning-text-color;
        }

        .input-block__input {
            border: 1px solid $primary-border-color;
            color: $primary-field-text-color;
        }
        &:hover .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid $hover-border-color;
        }
        &:focus-within .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid $focus-border-color;
        }

        &.input-block_error {
            .input-block__input {
                border: 1px solid $warning-border-color;
            }
        }
    }

    &_theme-mbs,
    &_theme-rmm,
    &_theme-connect {
        .input-block__input_num-block {
            background-color: #ffffff;
            input {
                background-color: transparent;
            }
        }

        &.input-block_error {
            .input-block__input {
                border: 1px solid $warning-border-color;
            }
            .input-block__helper {
                color: $warning-text-color;
            }
        }
    }

    &_theme-mbs {
        // .input-block__helper {
        //     color: #5e6368;
        //     background-color: #ffffff;
        // }
        .input-block__input {
            border: 1px solid #ffd4c2;
        }
        .input-block__num-btn {
            color: #fa9566;
        }
        &:hover .input-block__input:not(.disabled):not(:disabled),
        &:focus-within .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid #f0a586;
        }
    }

    &_theme-connect {
        // .input-block__helper {
        //     color: #5e6368;
        //     background-color: #ffffff;
        // }
        .input-block__input {
            border: 1px solid #b8c6e8;
        }
        .input-block__num-btn {
            color: #7293d3;
        }
        &:hover .input-block__input:not(.disabled):not(:disabled),
        &:focus-within .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid #8aa0d7;
        }
    }

    &_theme-rmm {
        // .input-block__helper {
        //     color: #5e6368;
        //     background-color: #ffffff;
        // }
        .input-block__input {
            border: 1px solid #aed5b3;
        }
        .input-block__num-btn {
            color: #76be82;
        }
        &:hover .input-block__input:not(.disabled):not(:disabled),
        &:focus-within .input-block__input:not(.disabled):not(:disabled) {
            border: 1px solid #7db783;
        }
    }

    &_sm-padding {
        .input-block__input {
            padding: 3px 8px;
        }
    }

    &_required {
        .input-block__label {
            &:after {
                content: '*';
                color: $warning-text-color;
                padding-left: 5px;
            }
        }
    }
    input[type='search'] {
        padding-right: 40px;
        background: url('@/assets/images/common/search.svg') no-repeat right
            20px top 50%;
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
    }
    input:disabled {
        cursor: not-allowed;
        color: #b5b5b5;
    }
}
</style>
