<script setup>
import { computed } from 'vue'
const props = defineProps({
    theme: { type: String, default: 'orange' },
    price: { type: Number, required: true },
    discountPrice: { type: Number, required: true }
})

const preparedDiscountPrice = computed(() => {
    return Math.ceil(props.discountPrice) === props.discountPrice
        ? props.discountPrice
        : props.discountPrice.toFixed(2)
})
</script>

<script>
export default {
    name: 'CalculatorProductPrice'
}
</script>

<template>
    <div class="product-price">
        <span
            v-if="discountPrice"
            class="product-price__discount-price"
            :class="`product-price__discount-price_${theme}`"
        >
            ${{ preparedDiscountPrice }}/year
        </span>
        <span
            class="product-price__price"
            :class="
                discountPrice
                    ? 'product-price__price_with-discount'
                    : `product-price__price_${theme}`
            "
        >
            ${{ price }}/year
        </span>
    </div>
</template>

<style scoped lang="scss">
.product-price {
    font-family: ReadexPro, sans-serif;
    font-weight: 400;
    line-height: 13px;
    display: flex;
    flex-direction: column;

    &__price {
        font-size: 12px;

        &_orange {
            color: $mbs-color;
        }
        &_green {
            color: $rmm-color;
        }
        &_blue {
            color: $connect-color;
        }
        &_with-discount {
            color: #969696;
            text-decoration: line-through;
        }
    }
    &__discount-price {
        font-size: 13px;

        &_orange {
            color: $mbs-color;
        }
        &_green {
            color: $rmm-color;
        }
        &_blue {
            color: $connect-color;
        }
    }
}
</style>
