export const formLabels = {
    createdBy: 'Rep Registering Deal',
    customerCompany: 'End User Company Name',
    customerFirstName: 'End User Customer First Name',
    customerLastName: 'End User Customer Last Name',
    customerEmail: 'End Customer Email',
    closeDate: 'Estimated Close Date',
    country: 'Country',
    state: 'State ',
    amount: 'Estimated annual amount',
    type: 'Deal Type',
    productFamily: 'Product Family',
    description: 'Description'
}
export const formPlaceholders = {
    createdBy: 'Rep Registering Deal',
    customerCompany: 'End User Company Name',
    customerFirstName: 'End User Customer First Name',
    customerLastName: 'End User Customer Last Name',
    customerEmail: 'End Customer Email',
    closeDate: 'Estimated Close Date',
    country: 'Country',
    state: 'State',
    amount: 'Estimated annual amount',
    type: 'Deal Type',
    productFamily: 'Product Family',
    description: 'Description'
}

export const getFormLabels = (companyType) => {
    const defaultLabels = {
        createdBy: 'Rep Registering Deal',
        routeToMarket: 'Route to Market',
        closeDate: 'Estimated Close Date',
        country: 'Country',
        state: 'State ',
        amount: 'Estimated annual amount',
        type: 'Deal Type',
        productFamily: 'Product Family',
        description: 'Description'
    }

    if (companyType === companyTypes.MSP) {
        return {
            ...defaultLabels,
            customerCompany: 'MSP Company Name',
            customerFirstName: 'MSP Contact First Name',
            customerLastName: 'MSP Contact Last Name',
            customerEmail: 'MSP Contact Email',
            customerCompanyDomain: 'MSP Company Domain'
        }
    }

    return {
        ...defaultLabels,
        customerCompany: 'End User Company Name',
        customerFirstName: 'End User Customer First Name',
        customerLastName: 'End User Customer Last Name',
        customerEmail: 'End Customer Email',
        customerCompanyDomain: 'Enduser Company Domain'
    }
}

export const getFormPlaceholders = (companyType) => {
    const defaultLabels = {
        createdBy: 'Rep Registering Deal',
        routeToMarket: 'Route to Market',
        closeDate: 'Estimated Close Date',
        country: 'Country',
        state: 'State ',
        amount: 'Estimated annual amount',
        type: 'Deal Type',
        productFamily: 'Product Family',
        description: 'Description'
    }

    if (companyType === companyTypes.MSP) {
        return {
            ...defaultLabels,
            customerCompany: 'MSP Company Name',
            customerFirstName: 'MSP Contact First Name',
            customerLastName: 'MSP Contact Last Name',
            customerEmail: 'MSP Contact Email',
            customerCompanyDomain: 'MSP Company Domain (Url)'
        }
    }

    return {
        ...defaultLabels,
        customerCompany: 'End User Company Name',
        customerFirstName: 'End User Customer First Name',
        customerLastName: 'End User Customer Last Name',
        customerEmail: 'End Customer Email',
        customerCompanyDomain: 'Enduser Company Domain (Url)'
    }
}

export const productFamily = [
    {
        text: 'Managed Backup',
        value: 'MSP360 Managed Backup'
    },
    {
        text: 'Connect Managed',
        value: 'Managed Remote Desktop'
    },
    {
        text: 'RMM',
        value: 'RMM'
    },
    {
        text: 'Onboarding',
        value: 'Onboarding'
    },
    {
        text: 'Premium Support',
        value: 'Premium Support'
    },
    {
        text: 'Managed O365',
        value: 'Managed O365'
    },
    {
        text: 'Deep Instinct',
        value: 'Deep Instinct'
    }
]

export const companyTypes = {
    MSP: 'MSP (Managed Service Provider)',
    IT: 'Internal IT'
}

export const companyTypeOptions = [
    {
        text: companyTypes.MSP,
        value: 'MSP (Managed Service Provider)'
    },
    {
        text: companyTypes.IT,
        value: 'Internal IT'
    }
]
