<script setup>
import { useHead } from 'unhead'
import error404Meta from '@/constants/meta/errors/error404'
import ErrorVideo from '@/components/blocks/ErrorVideo'
import ErrorBlock from '@/components/blocks/ErrorBlock'

useHead(error404Meta)

const homeLink = {
    to: '/',
    text: 'Home page'
}
</script>

<script>
export default {
    name: 'Error404Page'
}
</script>

<template>
    <div class="layout-error__page error-page">
        <ErrorBlock>
            <h1 class="error-block__title">Page Not Found</h1>
            <p class="error-block__info">
                Didn't find what you were looking for?
                <br />
                Go to the
                <BaseLink :link="homeLink" />
            </p>
        </ErrorBlock>
        <ErrorVideo class="error-page__video" video="404page-bg" />
    </div>
</template>

<style lang="scss" scoped>
.error-page {
    &__video {
        background-color: #f3681e;
        background-image: url('@/assets/images/errors/404page-bg.webp');
    }
}
</style>
