<script setup>
import { leadStatuses, dropdownStatuses } from '@/constants/leadStatuses'
import { useVfm } from 'vue-final-modal'
const vfm = useVfm()

const props = defineProps({
    status: {
        type: String,
        required: true,
        validator: (status) => {
            return Object.values(leadStatuses).includes(status)
        }
    },
    modalId: {
        type: String,
        required: true
    }
})

const modalIdSymbol = Symbol(props.modalId)

const emit = defineEmits(['onStatusChange'])

const changeStatus = (status) => emit('onStatusChange', status)

const confirm = (status) => {
    changeStatus(status)
    vfm.close(modalIdSymbol)
}
const handlerDropdown = (status, closeDropdown) => {
    changeStatus(status)
    closeDropdown()
}
</script>
<script>
export default {
    name: 'LeadStatusAction'
}
</script>
<template>
    <div class="status-action">
        <div
            v-if="status === leadStatuses.COLD"
            @click="() => vfm.open(modalIdSymbol)"
            class="status-action__btn draw-border"
        >
            Approach
        </div>
        <VDropdown
            v-else
            placement="bottom-end"
            container=".status-action__dropdown"
            class="status-action__dropdown"
        >
            <div class="status-action__dropdown-header">
                Set status
                <BaseIcon class="status-action__after" i="chevron" />
            </div>
            <template #popper="{ hide }">
                <div class="status-action__dropdown-body">
                    <div
                        v-for="(status, index) in dropdownStatuses"
                        :key="index"
                        class="status-action__dropdown-item"
                        @click="handlerDropdown(status.value, hide)"
                    >
                        {{ status.text }}
                    </div>
                </div>
            </template>
        </VDropdown>

        <BaseModal
            v-if="status === leadStatuses.COLD"
            :modal-id="modalIdSymbol"
        >
            <div class="status-action__modal">
                <BaseImage image="common/girl-avatar" />
                <h3 class="subtitle">Hey there!</h3>
                <p class="sm-text">Please confirm the status change.</p>
                <p class="sm-text">
                    After this action, you won't be able to return to the
                    previous status.
                </p>
                <div
                    @click="confirm(leadStatuses.APPROACHING)"
                    class="status-action__btn"
                >
                    Confirm
                </div>
            </div>
        </BaseModal>
    </div>
</template>
<style lang="scss" scoped>
.status-action {
    color: #6c7ea0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    &__btn {
        letter-spacing: 0.016em;
        padding: 8px 12px;
        border: 1px solid #ffb38f;
        cursor: pointer;
        width: fit-content;
        // @include btn-border-drawing();
    }
    &__after {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        transition: 0.3s ease-in-out;
        color: #f74e00;
    }
    &__dropdown-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 4px;
        cursor: pointer;
    }
    &__dropdown-body {
        background: #ffffff;
        box-shadow: 0px 4px 16px #0000001a;
        border-radius: 8px;
        color: #636363;
        padding: 16px;
    }
    &__dropdown-item {
        font-size: 14px;
        line-height: 16px;
        color: #636363;
        cursor: pointer;

        &:hover {
            color: #f74e00;
        }
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
    &__modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;
        .base-image {
            width: 80px;
            height: 80px;
        }
        .sm-text,
        .subtitle {
            text-align: center;
            margin-bottom: 0;
        }
    }
}

@mixin btn-border-drawing() {
    color: #6c7ea0;
    transition: color 0.25s 0.25s/3;
    position: relative;

    &:before,
    &:after {
        content: '';
        border: 0 solid transparent;
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
        bottom: -1px;
        right: -1px;
        box-sizing: border-box;
    }

    &:before {
        border-bottom-width: 1px;
        border-left-width: 1px;
    }

    &:after {
        border-top-width: 1px;
        border-right-width: 1px;
    }

    &:hover {
        color: #2f76ff;

        &:before,
        &:after {
            border-color: #2f76ff;
            transition: border-color 0s, width 0.25s, height 0.25s;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }

        &:before {
            transition-delay: 0s, 0s, 0.25s;
        }

        &:after {
            transition-delay: 0s, 0.25s, 0s;
        }
    }
}

.status-action__btn {
    @include btn-border-drawing();
}
</style>
<style lang="scss">
.status-action .v-popper--shown {
    .status-action__after {
        transform: rotate(180deg);
    }
}
.status-action .v-popper__arrow-container {
    display: none !important;
}
</style>
