export const routeNames = {
    signUp: 'SignupPage',
    signIn: 'SigninPage',
    forgotPassword: 'ForgotPasswordPage',
    resetPassword: 'ResetPasswordPage',
    userSettings: 'UserSettingsPage',
    dashboard: 'DashboardPage',
    leads: 'LeadsPage',
    archivedLeads: 'ArchivedLeadsPage',
    dealRegistration: 'DealRegistrationPage',
    deals: 'DealsPage',
    resources: 'ResourcesPage',
    quoteCalculator: 'QuoteCalculatorPage',
    partnerRegistration: 'PartnerRegistrationPage', // TODO change to semantic name
    adminUsers: 'AdminUsersPage',
    adminProducts: 'AdminProductsPage',
    adminPartners: 'AdminPartnersPage',
    adminLeads: 'AdminLeadsPage',
    adminDeals: 'AdminDealsPage',
    adminResources: 'AdminResourcesPage',
    forbidden: 'ForbiddenPage',
    error404: 'Error404Page',
    error: 'ErrorPage'
}
