import { watch } from 'vue'

export default function useFetchData(
    totalCount,
    currentCount,
    substitutePartnerID,
    cb
) {
    function initDataFetching() {
        if (!currentCount.value) {
            if (substitutePartnerID.value) {
                cb({ assignedPartner: substitutePartnerID.value })
            } else {
                cb()
            }
        }
    }

    function dataFetchingOnIntersection() {
        if (currentCount.value && totalCount.value > currentCount.value) {
            const locParams = {
                offset: currentCount.value
            }

            if (Object.hasOwn(locParams, 'assignedPartner')) {
                delete locParams.assignedPartner
            }

            if (substitutePartnerID.value) {
                locParams.assignedPartner = substitutePartnerID.value
            }

            cb(locParams)
        }
    }

    watch(
        () => substitutePartnerID.value,
        () => {
            initDataFetching()
        }
    )

    return [initDataFetching, dataFetchingOnIntersection]
}
