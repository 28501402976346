<script setup>
import { ref } from 'vue'
import { useHead } from 'unhead'
import resetPasswordMeta from '@/constants/meta/account/reset-password'
import ResetForm from '@/components/forms/password/ResetForm'

useHead(resetPasswordMeta)

const success = ref(false)
const signinLink = { text: 'Sign In', to: '/account/signin' }
</script>

<script>
export default {
    name: 'ResetPasswordPage'
}
</script>

<template>
    <div class="reset-page">
        <div v-if="success" class="reset-page__response">
            <h2 class="lg-title">Success!</h2>
            <p class="text text_center">
                Your password has been changed successfully.
            </p>
            <p class="text text_center">
                <!-- eslint-disable-next-line -->
                Use your new password to <BaseLink :link="signinLink" />.
            </p>
        </div>
        <div v-else class="reset-page__content">
            <h2 class="lg-title">Reset Password</h2>
            <ResetForm class="reset-page__form" @success="success = true" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
.reset-page {
    width: 100%;
    &__form {
        padding: 0 98px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 12px;
        width: 100%;

        &:deep(.button) {
            margin-top: 24px;
            align-self: center;
        }
    }
    .lg-title {
        margin-bottom: 30px;
    }
}
</style>
