export const leadStatuses = {
    COLD: 'Cold / Not Started',
    APPROACHING: 'Approaching',
    DO_NOT_CONTACT: 'Do Not Contact',
    UNRESPONSIVE: 'Unresponsive',
    BAD_CONTACT_INFO: 'Bad Contact Info',
    INTERESTED: 'Interested',
    NOT_INTERESTED: 'Not Interested'
}

export const leadLabels = {
    COLD: 'Cold / Not Started',
    APPROACHING: 'Approaching',
    DO_NOT_CONTACT: 'Do Not Contact',
    UNRESPONSIVE: 'Unresponsive',
    BAD_CONTACT_INFO: 'Bad Contact Info',
    INTERESTED: 'Interested',
    NOT_INTERESTED: 'Not Interested'
}

export const dropdownStatuses = [
    {
        text: leadLabels.UNRESPONSIVE,
        value: leadStatuses.UNRESPONSIVE
    },
    {
        text: leadLabels.BAD_CONTACT_INFO,
        value: leadStatuses.BAD_CONTACT_INFO
    },
    {
        text: leadLabels.INTERESTED,
        value: leadStatuses.INTERESTED
    },
    {
        text: leadLabels.NOT_INTERESTED,
        value: leadStatuses.NOT_INTERESTED
    }
]

export const filterActiveStatuses = [
    {
        text: leadLabels.COLD,
        value: leadStatuses.COLD
    },
    {
        text: leadLabels.APPROACHING,
        value: leadStatuses.APPROACHING
    }
]

export const filterArchivedStatuses = [
    {
        text: leadLabels.UNRESPONSIVE,
        value: leadStatuses.UNRESPONSIVE
    },
    {
        text: leadLabels.BAD_CONTACT_INFO,
        value: leadStatuses.BAD_CONTACT_INFO
    },
    {
        text: leadLabels.INTERESTED,
        value: leadStatuses.INTERESTED
    },
    {
        text: leadLabels.NOT_INTERESTED,
        value: leadStatuses.NOT_INTERESTED
    }
]

export const leadStatusOptions = [
    {
        text: leadLabels.COLD,
        value: leadStatuses.COLD
    },
    {
        text: leadLabels.APPROACHING,
        value: leadStatuses.APPROACHING
    },
    {
        text: leadLabels.UNRESPONSIVE,
        value: leadStatuses.UNRESPONSIVE
    },
    {
        text: leadLabels.BAD_CONTACT_INFO,
        value: leadStatuses.BAD_CONTACT_INFO
    },
    {
        text: leadLabels.INTERESTED,
        value: leadStatuses.INTERESTED
    },
    {
        text: leadLabels.NOT_INTERESTED,
        value: leadStatuses.NOT_INTERESTED
    },
    {
        text: leadLabels.DO_NOT_CONTACT, // TODO do we need this option?
        value: leadStatuses.DO_NOT_CONTACT
    }
]
