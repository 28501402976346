<script setup>
import ResourceCard from '@/components/blocks/ResourceCard'
import SimpleCTA from '@/components/blocks/SimpleCTA'
import { useHead } from 'unhead'
import resourcesMeta from '@/constants/meta/resources'
import {
    universityBanner,
    tabs,
    universityCTA
} from '@/constants/content/resources'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
useHead(resourcesMeta)
</script>

<script>
export default {
    name: 'ResourcesPage'
}
</script>

<template>
    <div class="resources-page">
        <h1 class="subtitle subtitle_mb">Resources</h1>
        <BaseBanner v-bind="universityBanner" class="resources-page__banner">
            <template #background>
                <BaseImage v-bind="universityBanner.image" />
            </template>
        </BaseBanner>
        <SimpleCTA :content="universityCTA" class="resources-page__cta" />
        <BaseTabs
            theme="line"
            v-if="checkHasPermissions([permissionsEnum.READ_RESOURCES])"
        >
            <VueTab
                v-for="(tab, index) in tabs"
                :key="index"
                :id="tab.id"
                :name="tab.name"
                class="resources-page__wrap"
            >
                <ResourceCard
                    v-for="(resource, index) in tab.resources"
                    :key="index"
                    :card="resource"
                />
            </VueTab>
        </BaseTabs>
    </div>
</template>

<style lang="scss" scoped>
.resources-page {
    &__banner {
        margin-bottom: 24px;
    }
    &__cta {
        margin-bottom: 28px;
    }
    &__wrap {
        display: grid;
        gap: 30px 32px;
        grid-template-columns: repeat(5, 1fr);

        @include media_max_xl {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
</style>
