<script setup>
import CalculatorProductBlock from '@/components/blocks/CalculatorProductBlock'
import CalculatorProductInfo from '@/components/blocks/CalculatorProductInfo'
import { alias } from '@/constants/quote-calculator-new'

/* eslint-disable */
defineProps({
    additionalBlock: { type: Object, required: true },
    chosenProducts: { type: Object, required: true }
})
const getLink = (url) => ({ href: url, text: 'Learn more', target: '_blank' })
</script>

<script>
export default {
    name: 'CalculatorAdditionalProducts'
}
</script>

<template>
    <CalculatorProductBlock v-bind="additionalBlock.header">
        <div class="additional-products">
            <template
                v-for="(item, index) in additionalBlock.products"
                :key="index"
            >
                <div
                    v-if="item.alias === alias.REBRANDING"
                    :class="`additional-products__${item.alias}`"
                >
                    <BaseInput
                        v-model="chosenProducts[item.alias][item.os]"
                        type="number"
                        sm-padding
                    />
                    <div class="additional-products__info">
                        <CalculatorProductInfo :product-info="item" />
                        <BaseLink
                            v-if="item.info"
                            :link="getLink(item.info)"
                            class="additional-products__link"
                        />
                    </div>
                </div>
                <div v-else :class="`additional-products__${item.alias}`">
                    <BaseCheckbox
                        :id="item.alias"
                        v-model="chosenProducts[item.alias][item.os]"
                    >
                        <CalculatorProductInfo :product-info="item" />
                        <BaseLink
                            v-if="item.info"
                            :link="getLink(item.info)"
                            class="additional-products__link"
                        />
                    </BaseCheckbox>
                </div>
            </template>
        </div>
    </CalculatorProductBlock>
</template>

<style scoped lang="scss">
.additional-products {
    font-family: Roboto, sans-serif;

    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-template-areas:
        'rebranding support'
        'rebranding onboarding';

    .product-info {
        font-size: 16px;
        line-height: 20px;
    }
    &__link {
        display: block;
        width: fit-content;
        font-style: italic;
        font-size: 12px;
        line-height: 14px;
    }
    &__mbs_rebranding {
        grid-area: rebranding;
        display: flex;
        gap: 12px;
        margin-bottom: 12px;
    }
    &__info {
        padding-top: 14px;
    }
    &__mbs_premium_support {
        grid-area: support;
        margin-bottom: 12px;
    }
    &__mbs_onboarding {
        grid-area: onboarding;
    }
    &__mbs_premium_support,
    &__mbs_onboarding {
        width: fit-content;
        &:deep(.checkbox-block) {
            input + label:before {
                top: 0;
                transform: translate(0, 0);
            }
        }
    }
}
</style>
