<script setup>
import LoadingSpinner from '@/components/common/LoadingSpinner'
import FlagIcon from '@/components/common/FlagIcon'
import { dealStageOptions } from '@/constants/deals'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'

const store = useStore()

const dealStatuses = {
    ['Pending']: 'blue',
    ['Accepted']: 'green',
    ['Rejected']: 'grey'
}
const stageStatuses = {
    ['Qualification']: 'bright-blue',
    ['Value Delivery']: 'purple',
    ['Evaluation']: 'light-orange',
    ['Proposal/Price Quote']: 'orange',
    ['Closed Won']: 'green',
    ['Closed Lost']: 'grey'
}
const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    dealInfo: { type: [Boolean, Object], default: false }
})

const showDetails = ref(false)

const classes = computed(() => {
    const classes = ['deal-info']

    if (props.mode) {
        classes.push(`deal-info_${props.mode}`)
    }

    return classes
})

const amount = computed(() => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }).format(props.dealInfo.amount)
})

const closeDate = computed(() => {
    const closeDate = new Date(props.dealInfo.closeDate)
    const date = closeDate.getDate()
    const month = closeDate.toLocaleString('en-US', { month: 'long' })
    const year = closeDate.getFullYear()

    return `${date} ${month} ${year}`
})
const isAdditional = computed(() => {
    return (
        props.dealInfo?.customerCompanyDomain || props.dealInfo?.routeToMarket
    )
})

const prerparedDomain = computed(() => {
    const domain = props.dealInfo?.customerCompanyDomain ?? ''
    const isAbsoluteUrl = domain.match(/https?:\/\/\S+/g)

    return isAbsoluteUrl ? domain : `https://${domain}`
})

const filterOptions = (options) => {
    return options.filter((opt) => opt.text !== props.dealInfo.stage)
}

const closeDetails = () => (showDetails.value = false)

const isStageLoading = ref(false)
const updateStage = async (option, closeDropdown) => {
    closeDropdown()
    isStageLoading.value = true

    await store.dispatch('updateDealStage', {
        id: props.dealInfo._id,
        stage: option.value
    })

    isStageLoading.value = false
}
</script>
<script>
export default {
    name: 'DealInfo'
}
</script>

<template>
    <BaseBlock :class="classes" v-click-away="closeDetails">
        <div class="deal-info__header">
            <div class="deal-info__name">
                {{ dealInfo.name }}
            </div>
            <div v-if="isAdditional" class="deal-info__additional">
                <div
                    v-if="dealInfo.routeToMarket"
                    class="deal-info__field-title"
                >
                    Account Type:
                    <span class="deal-info__text">
                        {{ dealInfo.routeToMarket }}
                    </span>
                </div>
                <div
                    v-if="dealInfo.customerCompanyDomain"
                    class="deal-info__field-title"
                >
                    Domain:
                    <a
                        :href="prerparedDomain"
                        target="_blank"
                        class="deal-info__text"
                    >
                        {{ dealInfo.customerCompanyDomain }}
                    </a>
                </div>
            </div>
        </div>
        <div class="deal-info__body">
            <div class="deal-info__main-info">
                <div>
                    <div class="deal-info__field-title">Customer</div>
                    <div class="deal-info__customer">
                        <div class="deal-info__customer--name">
                            {{ dealInfo.customer }}
                        </div>
                        <a
                            :href="`mailto:${dealInfo.customerEmail}`"
                            class="deal-info__customer--email"
                        >
                            {{ dealInfo.customerEmail }}
                        </a>
                    </div>
                </div>
                <div class="deal-info__second-col">
                    <div>
                        <div class="deal-info__field-title">Amount</div>
                        <div class="deal-info__amount">{{ amount }}</div>
                    </div>
                    <div>
                        <div class="deal-info__field-title">Close Date</div>
                        <div class="deal-info__date">
                            {{ closeDate }}
                        </div>
                    </div>
                </div>
                <div class="deal-info__third-col">
                    <div>
                        <div class="deal-info__field-title">Deal Status</div>
                        <BaseTag
                            :styling="dealStatuses[dealInfo.dealStatus]"
                            :text="dealInfo.dealStatus"
                        />
                    </div>
                    <div>
                        <BaseDropdown
                            v-if="
                                checkHasPermissions([
                                    permissionsEnum.UPDATE_DEAL_STAGE
                                ])
                            "
                            text="Stage"
                            :options="dealStageOptions"
                            class="deal-info__dropdown"
                        >
                            <template #options="{ options, closeDropdown }">
                                <div
                                    v-for="(opt, index) in filterOptions(
                                        options
                                    )"
                                    :key="index"
                                    @click="updateStage(opt, closeDropdown)"
                                    class="base-dropdown__item"
                                >
                                    {{ opt.text }}
                                </div>
                            </template>
                        </BaseDropdown>
                        <div v-else class="deal-info__field-title">Stage</div>
                        <BaseTag v-if="isStageLoading" styling="loading">
                            Loading
                            <LoadingSpinner />
                        </BaseTag>
                        <BaseTag
                            v-else
                            :styling="stageStatuses[dealInfo.stage]"
                            :text="dealInfo.stage"
                        />
                    </div>
                </div>
            </div>
            <Transition>
                <div v-if="showDetails" class="deal-info__details">
                    <div>
                        <div class="deal-info__field-title">Product</div>
                        <div>
                            <div
                                v-for="(product, i) in dealInfo.productFamily"
                                :key="i"
                                class="deal-info__product"
                            >
                                {{ product }}
                            </div>
                        </div>
                    </div>
                    <div class="deal-info__second-col">
                        <div>
                            <div class="deal-info__field-title">Type</div>
                            <div class="deal-info__text">
                                {{ dealInfo.type }}
                            </div>
                        </div>
                        <div>
                            <div class="deal-info__field-title">
                                Country {{ dealInfo.state ? ' | State' : '' }}
                            </div>
                            <div class="deal-info__text deal-info__location">
                                <div class="deal-info__location--country">
                                    <FlagIcon :country="dealInfo.country" />
                                    <div>
                                        {{ dealInfo.country }}
                                    </div>
                                </div>
                                <div
                                    v-if="dealInfo.state"
                                    class="deal-info__location--state"
                                >
                                    {{ dealInfo.state }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="deal-info__third-col">
                        <div>
                            <div class="deal-info__field-title">
                                Description
                            </div>
                            <div class="deal-info__text deal-info__text_light">
                                {{ dealInfo.description || '—' }}
                            </div>
                        </div>
                        <div>
                            <div class="deal-info__field-title">
                                Salesforce Identifier
                            </div>
                            <div class="deal-info__text">
                                {{ dealInfo.uniqueSFIdentifier || '—' }}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
        <div class="deal-info__more-overflow">
            <div class="deal-info__more" @click="showDetails = !showDetails">
                <BaseIcon
                    class="deal-info__more--icon"
                    :class="{ 'deal-info__more--icon_rev': showDetails }"
                    i="chevron"
                />
            </div>
        </div>
    </BaseBlock>
</template>

<style lang="scss" scoped>
.deal-info {
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #7eaaff26;
    }
    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-title-color;
    }

    &__main-info,
    &__details {
        display: grid;
        grid-template-columns: 0.75fr 1.1fr 1.24fr;
        gap: 40px 80px;
        @include media_max_xl {
            gap: 40px;
        }
        @include media_lg {
            grid-template-columns: 0.6fr 1fr 1fr;
        }
    }
    &__main-info {
        padding-top: 20px;
    }
    &__details {
        padding-top: 38px;
        padding-bottom: 20px;
    }
    &__second-col {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        @include media_lg {
            grid-template-columns: 1.2fr 2fr;
        }
    }
    &__third-col {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    &__field-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.019em;
        color: #b5b5b5;
        margin-bottom: 8px;
    }
    &__customer {
        display: grid;

        &--name {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.026em;
            color: #757575;
            margin-bottom: 2px;
        }
        &--email {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.026em;
            color: #2f76ff;
            text-decoration: none;
            width: fit-content;
            overflow-wrap: anywhere;
            &:hover {
                color: #2f76ff;
                text-decoration: underline;
            }
        }
    }
    &__amount,
    &__date {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #757575;
    }
    &__dropdown {
        margin-bottom: 8px;
    }
    &__location {
        display: flex;
        flex-direction: column;
        &--country {
            display: flex;
            gap: 8px;
        }
        &--state {
            font-size: 12px;
            line-height: 18px;
        }
    }
    &__product {
        font-size: 14px;
        line-height: 16px;
        color: #757575;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    &__text {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.026em;
        color: #757575;
        &_light {
            font-weight: 300;
        }
    }
    &__more-overflow {
        position: absolute;
        overflow: hidden;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 39px;
        border-bottom-right-radius: 20px;
    }

    &__more {
        width: inherit;
        height: inherit;
        background: #2f76ff;
        border-top-left-radius: 90%;
        color: #ffffff;
        font-size: 13px;
        cursor: pointer;
        transition: 0.2s ease-in;
        transform: translate(100%, 0);

        &--icon {
            position: absolute;
            bottom: 10px;
            right: 10px;
            transition: 0.2s ease-in;

            &_rev {
                transform: rotate(180deg);
            }
        }
    }
    &__align-center {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &:hover {
        .deal-info__more {
            transform: translate(0, 0);
            &--icon {
                animation: moreMoveDown 0.3s 0.3s 1 linear;
                &_rev {
                    animation: moreMoveUp 0.3s 0.3s 1 linear;
                }
            }
        }
    }
}
@keyframes moreMoveDown {
    50% {
        transform: translate(0, -3px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes moreMoveUp {
    50% {
        transform: rotate(180deg) translate(0, -3px);
    }
    100% {
        transform: rotate(180deg) translate(0, 0);
    }
}
.v-enter-active,
.v-leave-active {
    transition: all 0.1s ease-in-out;
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
