<script setup>
import { computed } from 'vue'
const props = defineProps({
    action: { type: Object, default: () => ({}) }
})
const tooltip = computed(() => {
    return {
        theme: 'admin-tooltip',
        popperTriggers: ['hover'],
        ...props.action.hint
    }
})
const onClick = () => props.action.cb()
</script>

<script>
export default {
    name: 'AdminAction'
}
</script>

<template>
    <button
        v-tooltip="tooltip"
        :disabled="action.disabled"
        @click="onClick"
        class="action"
    >
        <BaseIcon :i="action.icon" />
    </button>
</template>

<style lang="scss">
.v-popper--theme-tooltip.v-popper--theme-admin-tooltip .v-popper {
    &__inner {
        font-family: ReadexPro, sans-serif;
        font-size: 12px;
        line-height: 16px;
        max-width: 232px;
        color: #636363;
        background: #e1e5fa;
        border-radius: 4px;
        padding: 4px 8px;
        border: none;
    }
    &__arrow-container {
        display: none;
    }
}
</style>

<style lang="scss" scoped>
.action {
    background: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    outline: none;
    &:not(:last-child) {
        margin-right: 12px;
    }
    .icon {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        color: #93b8ef;
    }
    &:disabled {
        cursor: not-allowed;
        .icon {
            color: #d0d2d6;
        }
    }
    &:not(:disabled):hover {
        .icon {
            color: #2f76ff;
        }
    }
}
</style>
