<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useHead } from 'unhead'
import _ from 'lodash'
import meta from '@/constants/meta/admin/deals'
import {
    dealStatusOptions,
    dealTypeOptions,
    dealStageOptions
} from '@/constants/deals'
import { SEARCH_MODE_DYNAMIC } from '@/constants/baseSelect'

import useFilters from '@/composables/useFilters'
import useSelectPartners from '@/composables/useSelectPartners'
import useConfirm from '@/composables/useConfirm'

import AdminLayout from '@/components/blocks/admin/AdminLayout'
import AdminTable from '@/components/blocks/admin/AdminTable'
import AdminAction from '@/components/blocks/admin/AdminAction'
import FilterWrapper from '@/components/filters/FilterWrapper'
import ConfirmationModal from '@/components/common/ConfirmationModal'
import DealRegistrationFormForAdmin from '@/components/forms/admin/DealRegistrationFormForAdmin'

useHead(meta)
const store = useStore()

const dealConfirmafionId = Symbol('deal-confirmafion')

const tableColumns = [
    {
        target: 'name',
        title: 'Name'
    },
    {
        target: 'createdBy',
        title: 'Created By'
    },
    {
        target: 'stage',
        title: 'Stage'
    },
    {
        target: 'closeDate',
        title: 'Close Date'
    },
    {
        target: 'amount',
        title: 'Amount'
    },
    {
        target: 'dealStatus',
        title: 'Deal Status'
    },
    {
        target: 'type',
        title: 'Type'
    },
    {
        target: 'customer',
        title: 'Customer'
    },
    {
        target: 'customerEmail',
        title: 'Customer Email'
    },
    {
        target: 'customerCompany',
        title: 'Customer Company'
    },
    {
        target: 'productFamily',
        title: 'Product Family'
    },
    {
        target: 'source',
        title: 'Source'
    },
    {
        target: 'description',
        title: 'Description'
    },
    {
        target: 'nextStep',
        title: 'Next Step'
    },
    {
        target: 'POId',
        title: 'PO Id'
    },
    {
        target: 'leadId',
        title: 'Lead Id'
    },
    {
        target: 'country',
        title: 'Country'
    },
    {
        target: 'state',
        title: 'State'
    }
    // {
    //     slug: 'actions',
    //     sticky: true
    // }
]
const confirmationContents = {
    delete: (deal) => ({
        textOnResolve: 'Delete',
        text: `Are you sure you want to delete the Deal <span>${deal.name}</span>?`
    })
}
const getDealConfirmation = async (deal, action) => {
    editableDeal.value = deal
    confirmationContent.value = confirmationContents[action](deal)

    const [getConfirmation] = useConfirm(dealConfirmafionId)
    const confirmation = await getConfirmation()
    return confirmation
}
const deleteDeal = async (deal) => {
    /**
     * удаление сделки пока не будет, мы не можем удалить сделку из СФ.
     * поведение удаления не тестировалось.
     */
    const confirmation = await getDealConfirmation(deal, 'delete')
    if (confirmation) {
        store.dispatch('admin/deleteDeal', deal)
    }
}
const getActions = (deal) => {
    return [
        {
            icon: 'delete',
            hint: {
                content: 'Delete'
            },
            cb: deleteDeal.bind(this, deal)
        }
    ]
}

const deals = computed(() => store.getters['admin/getDeals'](limit.value))
const limit = ref(10)
const currentPage = ref(1)
const editableDeal = ref({})
const confirmationContent = ref({
    textOnResolve: '',
    text: ''
})

/* for filters */
const {
    partnerOptions,
    getPartners,
    partnerOptionsLoading,
    searchPartnersResultText,
    onIntersection,
    onSearchPartners,
    onClearSearchPartners
} = useSelectPartners({ withResetOption: true })

getPartners() // init partners for select

const filtersContent = {
    assignedPartner: {
        label: {
            id: 'assignedPartner',
            text: 'By Partner'
        },
        filter: {
            name: 'Search for Partner',
            placeholder: 'Search for Partner',
            id: 'assignedPartner',
            multiple: true,
            multipleWithTags: true,
            intersection: true,
            searchable: true,
            searchMode: SEARCH_MODE_DYNAMIC,
            optionsPlaceholder: "Start typing Partner's name"
        }
    },
    name: {
        label: {
            id: 'name',
            text: 'By Name'
        },
        filter: {
            type: 'search',
            id: 'name',
            name: 'name',
            placeholder: 'Enter Name'
        }
    },
    stage: {
        filter: {
            title: 'By Stage',
            options: dealStageOptions
        }
    },
    dealStatus: {
        filter: {
            title: 'By Deal Status',
            options: dealStatusOptions
        }
    },
    type: {
        filter: {
            title: 'By Type',
            options: dealTypeOptions
        }
    }
}

const { filters, isFilterSet, resetFilters } = useFilters({
    assignedPartner: '',
    name: '',
    stage: [],
    dealStatus: [],
    type: []
})
/* for filters */

const params = computed(() => {
    return {
        withPrices: true,
        limit: limit.value,
        offset: deals.value.list.length * (currentPage.value - 1),
        ...filters.value,
        isFilterSet: isFilterSet.value
    }
})

onMounted(() => {
    store.dispatch('admin/fetchDeals', params.value)
})

const debouncedFetchDeals = _.debounce(() => {
    store.dispatch('admin/fetchDeals', params.value)
}, 1000)

watch(filters.value, debouncedFetchDeals)
</script>
<script>
export default {
    name: 'AdminDealsPage'
}
</script>

<template>
    <AdminLayout :loading="deals.loading">
        <template #filter>
            <FilterWrapper :label="filtersContent.assignedPartner.label">
                <BaseSelect
                    :options="partnerOptions"
                    v-model="filters.assignedPartner"
                    @intersection="onIntersection"
                    :options-loading="partnerOptionsLoading"
                    @search="onSearchPartners"
                    @clearSearch="onClearSearchPartners"
                    :search-result-text="searchPartnersResultText"
                    v-bind="filtersContent.assignedPartner.filter"
                />
            </FilterWrapper>
            <FilterWrapper :label="filtersContent.name.label">
                <BaseInput
                    v-bind="filtersContent.name.filter"
                    v-model="filters.name"
                />
            </FilterWrapper>
            <FilterWrapper>
                <BaseCheckboxBox
                    v-model="filters.stage"
                    v-bind="filtersContent.stage.filter"
                />
            </FilterWrapper>
            <FilterWrapper>
                <BaseCheckboxBox
                    v-model="filters.dealStatus"
                    v-bind="filtersContent.dealStatus.filter"
                />
            </FilterWrapper>
            <FilterWrapper>
                <BaseCheckboxBox
                    v-model="filters.type"
                    v-bind="filtersContent.type.filter"
                />
            </FilterWrapper>
        </template>
        <template #filter-footer>
            <BaseButton design="outline-blue" @click="resetFilters">
                Reset
            </BaseButton>
        </template>
        <template #table>
            <AdminTable :table="tableColumns" :items="deals.list">
                <template #actions="item">
                    <AdminAction
                        v-for="(action, index) in getActions(item)"
                        :key="index"
                        :action="action"
                    />
                </template>
            </AdminTable>
        </template>
        <template #toolbar>
            <BaseButton icon="plus">Register Deal</BaseButton>
        </template>
        <template #footer>
            <BasePagination
                v-model="currentPage"
                :total-items="deals.total"
                @click="() => store.dispatch('admin/fetchDeals', params)"
            />
        </template>
    </AdminLayout>
    <ConfirmationModal
        :id="dealConfirmafionId"
        :text-on-resolve="confirmationContent.textOnResolve"
    >
        <div v-html="confirmationContent.text" />
    </ConfirmationModal>
    <div style="padding: 20px; background: #ffffff">
        <DealRegistrationFormForAdmin />
    </div>
</template>
