<script setup>
import { RouterView } from 'vue-router'
import LayoutRoot from '@/layouts/LayoutRoot.vue'
</script>
<script>
export default {
    name: 'App'
}
</script>

<template>
    <LayoutRoot>
        <router-view />
    </LayoutRoot>
</template>
