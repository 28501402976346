<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { getProcessedURL, getExternalErrors } from '@/utils/formatting'
import validation from '@/utils/validation'
import { useRoute } from 'vue-router'

const emit = defineEmits(['success'])

const store = useStore()
const route = useRoute()

const token = ref(route.query.token)
const email = ref(
    route.query.email ? getProcessedURL(route.query.email) : undefined
)

const form = ref({
    password: '',
    confirm: ''
})

const externalErrors = ref({})
const autoDirty = ref(false)

const formLabels = {
    password: 'Password',
    confirm: 'Confirm password'
}
const formPlaceholders = {
    password: 'Password',
    confirm: 'Confirm password'
}
const response = ref({
    isError: false,
    errorText: 'Something went wrong. Try again'
})

const rules = computed(() => {
    return {
        password: {
            required: validation.required(formLabels.password),
            minLength: validation.minLength(formLabels.password, 8)
        },
        confirm: {
            required: validation.required(formLabels.confirm),
            sameAs: validation.sameAs(formLabels.password, form.value.password)
        }
    }
})

const handlerForm = async (form) => {
    try {
        const preparedForm = {
            token: token.value,
            email: email.value,
            password: form.password
        }

        const res = await store.dispatch('resetPassword', preparedForm)
        console.log('resetPassword res: ', res)
        if (res.data?.success) {
            emit('success')
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true

            const errors = res.errInfo.errors
            externalErrors.value = getExternalErrors(errors)
            console.log('externalErrors.value: ', externalErrors.value)
        } else {
            throw new Error()
        }
    } catch (error) {
        console.log('error: ', error)
        response.value.isError = true
    }
}
</script>

<script>
export default {
    name: 'ResetForm'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        :error="response.isError"
        :error-text="response.errorText"
        :on-submit="handlerForm"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        submit-text="Reset Password"
    >
        <template #default="{ validator }">
            <BaseInput
                type="password"
                v-model="form.password"
                :validator="validator.password"
                :name="formLabels.password"
                :placeholder="formPlaceholders.password"
                required
            />
            <BaseInput
                type="password"
                v-model="form.confirm"
                :validator="validator.confirm"
                :name="formLabels.confirm"
                :placeholder="formPlaceholders.confirm"
                required
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
