<script setup>
import { ref, onMounted } from 'vue'
import { useHead } from 'unhead'
import partnerRegistrationMeta from '@/constants/meta/admin/partner-registration'
import { partnersAPI } from '@/api/partners-api'
import PartnerRegistrationForm from '@/components/forms/PartnerRegistrationForm'
import PartnerDeletionForm from '@/components/forms/PartnerDeletionForm'
import { registrationMode, partnerRoles } from '@/constants/partnerRegistration'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'

useHead(partnerRegistrationMeta)

// get partners
const partners = ref([])
const totalAmount = ref(null)
const partnerOptionsLoading = ref(false)
const searchParam = ref('')
const searchPartnersResultText = ref('')
const isPartnersSearching = ref(false)

const preparePartnerOptions = (partners) => {
    return partners.map((partner) => {
        return {
            value: partner._id,
            text: partner.companyName
        }
    })
}

const getPartners = (params) => {
    console.log('getPartners params: ', params)
    partnerOptionsLoading.value = true
    searchPartnersResultText.value = ''
    partnersAPI
        .fetchPartners(params)
        .then((res) => {
            if (res.data) {
                partners.value = [
                    ...partners.value,
                    ...preparePartnerOptions(res.data.results)
                ]

                totalAmount.value = res.data._meta[0].count
            } else {
                searchPartnersResultText.value = 'Sorry, no matches were found'
            }
        })
        .finally(() => {
            partnerOptionsLoading.value = false
        })
}

const onSearchPartners = (searchText) => {
    if (!partnerOptionsLoading.value) {
        searchParam.value = searchText
        partners.value = []
        isPartnersSearching.value = true
        getPartners({ query: searchParam.value })
    }
}

const onClearSearchPartners = () => {
    searchParam.value = ''
    partners.value = []
    isPartnersSearching.value = false
    getPartners()
}

getPartners()

const onIntersection = () => {
    const offset = partners.value.length
    if (totalAmount.value > offset && !isPartnersSearching.value) {
        const params = { offset }

        if (searchParam.value) {
            params.query = searchParam.value
        }

        getPartners(params)
    }
}

const userName = ref('')
const userEmail = ref('')
const userRole = ref('')
const success = ref(false)

const onSuccess = ({ email, name, role }) => {
    userName.value = name
    userEmail.value = email
    userRole.value = partnerRoles[role]
    success.value = true
}

const successDeletion = ref(false)
const partnerEmail = ref('')
const onSuccessDeletion = (form) => {
    partnerEmail.value = form.email
    successDeletion.value = true

    pushNotification({
        message: `The partner with following email: <span>${form.email}</span> was deleted from the Partner Portal <span>successfully</span>.`,
        type: notificationTypes.SUCCESS
    })
}

const parsePartners = async (showNotification = true) => {
    partnersAPI
        .parsePartners()
        .then((res) => {
            console.log('parsePartners form res: ', res)
            if (res.data) {
                if (showNotification) {
                    pushNotification({
                        message:
                            'Partners were <span>successfully</span> parsed',
                        type: notificationTypes.SUCCESS
                    })
                }
            } else {
                throw new Error()
            }
        })
        .catch((err) => {
            console.log('parsePartners form err: ', err)
            pushNotification({
                message: 'Failed to parse Partners. Try again',
                type: notificationTypes.ERROR
            })
        })
}

onMounted(() => {
    parsePartners(false)
})
</script>

<script>
export default {
    name: 'PartnerRegistrationPage'
}
</script>

<template>
    <div class="partner-registration">
        <div class="partner-registration__header">
            <h1 class="subtitle">Admin Page</h1>
            <BaseButton @click="parsePartners">
                Partner Synchronization
            </BaseButton>
        </div>
        <BaseTabs>
            <VueTab id="registration" name="Add a Partner">
                <template v-if="success">
                    <p class="text text_center">
                        <span class="partner-registration__accent">
                            {{ userName }}
                        </span>
                        was granted access as an
                        <b>Partner {{ userRole }}</b>
                        to the Partner Portal successfully.
                    </p>
                    <p class="text text_center">
                        An email was sent to
                        <span class="partner-registration__accent">
                            {{ userEmail }}
                        </span>
                        with further details to complete their registration.
                    </p>
                    <p class="text text_center">
                        <BaseButton @click="success = false">
                            Add Next Partner
                        </BaseButton>
                    </p>
                </template>
                <template v-else>
                    <h1 class="block-title block-title_center">
                        Add a New Partner
                    </h1>
                    <PartnerRegistrationForm
                        :mode="registrationMode.ADMIN"
                        submit-text="Send invite"
                        :partners="partners"
                        @intersection="onIntersection"
                        :partner-options-loading="partnerOptionsLoading"
                        @success="onSuccess"
                        @searchPartners="onSearchPartners"
                        @clearSearchPartners="onClearSearchPartners"
                        :search-partners-result-text="searchPartnersResultText"
                        class="partner-registration__form"
                    />
                </template>
            </VueTab>
            <VueTab id="deletion" name="Delete a Partner">
                <template v-if="successDeletion">
                    <p class="text text_center">
                        The partner with following email
                        <span class="partner-registration__accent">
                            {{ partnerEmail }}
                        </span>
                        was deleted from the Partner Portal successfully.
                    </p>
                    <p class="text text_center">
                        <BaseButton @click="successDeletion = false">
                            Close
                        </BaseButton>
                    </p>
                </template>
                <template v-else>
                    <h1 class="block-title block-title_center">
                        Delete a Partner Admin
                    </h1>
                    <PartnerDeletionForm @success="onSuccessDeletion" />
                </template>
            </VueTab>
        </BaseTabs>
    </div>
</template>

<style lang="scss" scoped>
.partner-registration {
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        gap: 20px;
    }
    &:deep(.tabs-component-panels) {
        padding: 142px 100px;
        max-width: 750px;
    }
    .block-title {
        margin-bottom: 16px;
    }

    &:deep(.partner-deletion__form),
    &__form {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 0 auto;
        max-width: 350px;
    }

    &:deep(.partner-deletion__form) .button,
    &__form:deep(.button) {
        margin-top: 16px;
        align-self: center;
    }
    &__accent {
        color: #179c2d;
    }
    &:deep(.text) {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}
</style>
