<script setup>
import LeadsList from '@/components/blocks/LeadsList'
import { computed } from 'vue'
const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    title: { type: String, default: '' },
    leads: { type: Array, default: () => [] },
    keys: { type: Array, required: true },
    withPanel: { type: Boolean, default: true },
    link: { type: [Boolean, Object], default: false },
    statistic: { type: [Boolean, Object], default: false },
    button: { type: [Boolean, Object], default: false },
    filterStatusOptions: { type: [Boolean, Array], default: false },
    loading: { type: Boolean, default: false },
    intersection: { type: Boolean, default: false },
    message: { type: String, default: '' }
})
const classes = computed(() => {
    const classes = ['leads-block']

    if (props.mode) {
        classes.push(`leads-block_${props.mode}`)
    }

    return classes
})
</script>
<script>
export default {
    name: 'LeadsBlock'
}
</script>
<template>
    <div :class="classes">
        <div class="leads-block__header">
            <h1 class="subtitle">{{ title }}</h1>
            <div v-if="statistic || link" class="leads-block__header-inner">
                <div v-if="statistic" class="leads-block__info">
                    <div class="leads-block__info--count">
                        {{ statistic.count }}
                    </div>
                    <div class="leads-block__info--text">
                        {{ statistic.text }}
                    </div>
                </div>
                <BaseLink v-if="link" class="leads-block__link" :link="link" />
            </div>
            <BaseButton v-if="button" :to="button.to">
                {{ button.text }}
            </BaseButton>
        </div>
        <LeadsList
            :withPanel="withPanel"
            :leads="leads"
            :keys="keys"
            :filter-status-options="filterStatusOptions"
            :message="message"
            :loading="loading"
            :intersection="intersection"
            @intersection="$emit('intersection')"
        />
    </div>
</template>

<style lang="scss" scoped>
.leads-block {
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
    }
    .subtitle {
        margin-right: 20px;
        margin-bottom: 0;
    }
    &__header-inner {
        display: flex;
        align-items: center;
        gap: 20px;
        flex: 1;
    }
    &__info {
        display: flex;
        background: #7eaaff26;
        border-radius: 20px;

        &--count {
            display: flex;
            align-items: center;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.03em;
            color: #ffffffe6;
            background-color: #7eaaff99;
            padding: 0 24px 0 20px;
            border-radius: 20px 0px 20px 20px;
            margin-right: 14px;
        }
        &--text {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #7eaaff;
            padding: 10px 25px 10px 0;
        }
    }
    &__link.link {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.026em;
        text-transform: uppercase;
        text-decoration: none;
    }
}
</style>
