<script setup>
import { computed, useAttrs } from 'vue'
const $attrs = useAttrs()
const props = defineProps({
    link: { type: [Boolean, Object], default: false }
})

const text = computed(() => props.link.text)

const computedComponent = computed(() => {
    if (props.link.to) {
        return 'router-link'
    } else if (props.link.href) {
        return 'a'
    } else {
        return 'span'
    }
})
const attrs = computed(() => {
    const attrs = {}

    if (props.link.to) {
        attrs.to = props.link.to
    } else if (props.link.href) {
        attrs.href = props.link.href
    }

    return {
        ...$attrs,
        ...attrs
    }
})
</script>

<script>
export default {
    name: 'BaseLink'
}
</script>

<template>
    <component :is="computedComponent" class="link" v-bind="attrs">
        <slot>{{ text }}</slot>
    </component>
</template>

<style lang="scss" scoped>
.link {
    color: $secondary-link-color;
    text-decoration: underline;
    &:hover {
        color: $secondary-link-color;
    }
}
</style>
