<script setup>
import { computed } from 'vue'
const props = defineProps({
    styling: { type: String, default: '' },
    size: { type: String, default: 'md' },
    text: { type: String, default: '' }
})

const classes = computed(() => {
    const classes = ['base-tag']

    if (props.styling) {
        classes.push(`base-tag_style--${props.styling}`)
    }

    classes.push(`base-tag_size--${props.size}`)

    return classes
})
</script>

<script>
export default {
    name: 'BaseTag'
}
</script>

<template>
    <div :class="classes">
        <slot>{{ text }}</slot>
    </div>
</template>

<style scoped lang="scss">
.base-tag {
    width: fit-content;
    &_size {
        &--sm {
            padding: 2px 6px;
            border-radius: 12px;
            font-weight: 300;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.286px;
        }
        &--md {
            padding: 6px 12px;
            border-radius: 20px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.016em;
        }
    }

    &_style {
        &--blue {
            background: #8aa0c61a;
            color: #6f8cc5;
        }
        &--pale-blue {
            background: #8aa0c61a;
            color: #799fc1;
        }
        &--green {
            background: #179c2d1a;
            color: #228733;
        }
        &--grey {
            background: #f1f5fd;
            color: #adbfe0;
        }
        &--bright-blue {
            color: #59a1e4;
            background: #ecf4fc;
        }
        &--orange {
            color: #d76525;
            background: #ffe7e7;
        }
        &--light-orange {
            color: #ea9129;
            background: #ffeedd;
        }
        &--purple {
            color: #ac62c7;
            background: #dd7dff26;
        }
        &--maroon {
            color: #d58787;
            background: #dd98981a;
        }
        &--loading {
            color: #ffffff;
            background: #c3c3c3;
        }
        &--resource-blue {
            color: #2f76ff;
            background: #d3e2ff;
        }
        &--resource-orange {
            color: #f74e00;
            background: #ffe7e7;
        }
        &--resource-green {
            color: #179c2d;
            background: #e9f8ee;
        }
        &--resource-purple {
            color: #8459ff;
            background: rgba(158, 122, 255, 0.3);
        }
        &--resource-pink {
            color: #ff2568;
            background: rgba(255, 122, 164, 0.3);
        }
        &--resource-yellow {
            color: #e88000;
            background: rgba(255, 194, 51, 0.3);
        }
    }
}
</style>
