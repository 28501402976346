<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import validation from '@/utils/validation'
import { getExternalErrors } from '@/utils/formatting'

const store = useStore()
const form = ref({
    email: ''
})
const formLabels = {
    email: 'Email or Username'
}

const response = ref({
    isError: false,
    errorText: 'Something went wrong. Try again',
    isSuccess: false,
    succesText: 'Head over to your inbox to reset your password'
})

const externalErrors = ref({})
const autoDirty = ref(false)

const rules = computed(() => {
    return {
        email: {
            required: validation.required(formLabels.email),
            validEmail: validation.validEmail
        }
    }
})

const handlerForm = async (form) => {
    try {
        const res = await store.dispatch('forgotPassword', form)
        console.log('forgotPassword form res: ', res)
        if (res.data?.success) {
            response.value.isSuccess = true
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true

            const errors = res.errInfo.errors
            externalErrors.value = getExternalErrors(errors)
            console.log('externalErrors.value: ', externalErrors.value)
        } else {
            throw new Error()
        }
    } catch (error) {
        console.log('error: ', error)
        response.value.isError = true
    }
}
</script>

<script>
export default {
    name: 'EmailForm'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        :success="response.isSuccess"
        :success-text="response.succesText"
        :error="response.isError"
        :error-text="response.errorText"
        :on-submit="handlerForm"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        submit-text="Forgot Password"
    >
        <template #default="{ validator }">
            <BaseInput
                type="text"
                v-model="form.email"
                :validator="validator.email"
                :name="formLabels.email"
                :placeholder="formLabels.email"
                required
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>
