import { authAPI } from '@/api/auth-api'
import { partnersAPI } from '@/api/partners-api'
import { productsAPI } from '@/api/products-api'
import { dealsAPI } from '@/api/deals-api'
import { leadsAPI } from '@/api/leads-api'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { NO_FILTER_RESULTS } from '@/constants/filters'
import { userCredentialActions } from '@/constants/user-settings'

export const admin = {
    namespaced: true,
    state: () => ({
        users: {
            list: [],
            total: 0,
            loading: false
        },
        partners: {
            list: [],
            total: 0,
            loading: false
        },
        products: {
            list: [],
            total: 0,
            loading: false
        },
        deals: {
            list: [],
            total: 0,
            loading: false
        },
        leads: {
            list: [],
            total: 0,
            loading: false
        }
    }),
    getters: {
        getUsers: (state) => (limit) => {
            return {
                ...state.users,
                list: state.users.list.slice(0, limit)
            }
        },
        getPartners:
            (state) =>
            (limit = 10) => {
                return {
                    ...state.partners,
                    list: state.partners.list.slice(0, limit)
                }
            },
        getProducts: (state) => (limit) => {
            return {
                ...state.products,
                list: state.products.list.slice(0, limit)
            }
        },
        getDeals: (state) => (limit) => {
            return {
                ...state.deals,
                list: state.deals.list.slice(0, limit)
            }
        },
        getLeads: (state) => (limit) => {
            return {
                ...state.leads,
                list: state.leads.list.slice(0, limit)
            }
        }
    },
    mutations: {
        /* users START */
        SET_USERS_LOADING(state, payload) {
            state.users.loading = payload
        },
        SET_USERS(state, payload) {
            state.users.list = payload
        },
        SET_USERS_TOTAL(state, payload) {
            state.users.total = payload
        },
        UPDATE_USER(state, newUser) {
            state.users.list = state.users.list.map((user) => {
                return user._id === newUser._id ? newUser : user
            })
        },
        /* users END */

        /* partners START */
        SET_PARTNERS_LOADING(state, payload) {
            state.partners.loading = payload
        },
        SET_PARTNERS(state, payload) {
            state.partners.list = payload
        },
        SET_PARTNERS_TOTAL(state, payload) {
            state.partners.total = payload
        },
        /* partners END */

        /* products START */
        SET_PRODUCTS_LOADING(state, payload) {
            state.products.loading = payload
        },
        SET_PRODUCTS(state, payload) {
            state.products.list = payload
        },
        SET_PRODUCTS_TOTAL(state, payload) {
            state.products.total = payload
        },
        /* products END */

        /* deals START */
        SET_DEALS_LOADING(state, payload) {
            state.deals.loading = payload
        },
        SET_DEALS(state, payload) {
            state.deals.list = payload
        },
        SET_DEALS_TOTAL(state, payload) {
            state.deals.total = payload
        },
        /* deals END */

        /* leads START */
        SET_LEADS_LOADING(state, payload) {
            state.leads.loading = payload
        },
        SET_LEADS(state, payload) {
            state.leads.list = payload
        },
        SET_LEADS_TOTAL(state, payload) {
            state.leads.total = payload
        }
        /* leads END */
    },
    actions: {
        /* users START */
        async fetchUsers({ commit }, { isFilterSet, ...params }) {
            commit('SET_USERS_LOADING', true)

            try {
                const res = await authAPI.fetchUsers(params)
                console.log('admin res: ', res)

                if (res?.results?.length) {
                    commit('SET_USERS', res.results)
                    commit('SET_USERS_TOTAL', res._meta[0].count)
                } else if (!res?.results?.length && isFilterSet) {
                    pushNotification({ message: NO_FILTER_RESULTS })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to fetch the Users')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }

            commit('SET_USERS_LOADING', false)
        },
        async blockUser({ commit, state }, id) {
            try {
                const params = {
                    id,
                    action: userCredentialActions.REMOVE
                }
                const res = await authAPI.setUserCredentials(params)

                if (res?.success) {
                    const user = state.users.list.find(
                        (u) => u._id === params.id
                    )
                    commit('UPDATE_USER', { ...user, deleted: true })

                    pushNotification({
                        message: `<span>${user.name}</span> was blocked successfully`,
                        type: notificationTypes.SUCCESS
                    })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to block the User')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async deleteUser({ commit, state }, email) {
            try {
                const res = await authAPI.deleteUser({ email })

                if (res?.data) {
                    const users = state.users.list.filter((user) => {
                        return user.email !== email
                    })
                    commit('SET_USERS', users)
                    commit('SET_USERS_TOTAL', state.users.total - 1)

                    pushNotification({
                        message: `The User with <span>${email}</span> was deleted successfully`,
                        type: notificationTypes.SUCCESS
                    })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to delete the User')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async inviteUser(cxt, params) {
            try {
                const res = await authAPI.inviteUser(params)
                if (res.data) {
                    pushNotification({
                        message: `The invitation to ${params.name} was sent <span>successfully</span>`,
                        type: notificationTypes.SUCCESS
                    })
                } else {
                    throw new Error()
                }
            } catch {
                pushNotification({
                    message: `Failed to send invitation to ${params.name}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async registerUser({ commit, state }, data) {
            try {
                const res = await authAPI.registerUser(data)
                console.log('registerUser res: ', res)

                if (res.data) {
                    commit('SET_USERS', [res.data, ...state.users.list])
                    commit('SET_USERS_TOTAL', state.users.total + 1)

                    pushNotification({
                        message: `${data.name} was granted access to the Portal successfully`,
                        type: notificationTypes.SUCCESS
                    })
                }

                return res
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        /* users END */

        /* partners START */
        async fetchPartners({ commit }, { isFilterSet, ...params }) {
            commit('SET_PARTNERS_LOADING', true)

            try {
                const res = await partnersAPI.fetchPartners(params)
                console.log('admin partners res: ', res)

                if (res?.data?.results?.length) {
                    commit('SET_PARTNERS', res.data.results)
                    commit('SET_PARTNERS_TOTAL', res.data._meta[0].count)
                } else if (!res?.data?.results?.length && isFilterSet) {
                    pushNotification({ message: NO_FILTER_RESULTS })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to fetch the Partners')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }

            commit('SET_PARTNERS_LOADING', false)
        },
        async parsePartners() {
            partnersAPI
                .parsePartners()
                .then((res) => {
                    console.log('parsePartners res: ', res)

                    if (res.data) {
                        pushNotification({
                            message:
                                'Partners were <span>successfully</span> parsed',
                            type: notificationTypes.SUCCESS
                        })
                        return
                    }

                    throw new Error()
                })
                .catch((err) => {
                    console.log('parsePartners form err: ', err)

                    pushNotification({
                        message: 'Failed to parse Partners. Try again',
                        type: notificationTypes.ERROR
                    })
                })
        },
        async deletePartners() {
            alert('In development mode...')
        },
        /* partners END */

        /* products START */
        async fetchProducts({ commit }, { isFilterSet, ...params }) {
            commit('SET_PRODUCTS_LOADING', true)

            try {
                const res = await productsAPI.fetchProducts(params)
                console.log('admin products res: ', res)

                if (res?.data?.results?.length) {
                    commit('SET_PRODUCTS', res.data.results)
                    commit('SET_PRODUCTS_TOTAL', res.data._meta[0].count)
                } else if (!res?.data?.results?.length && isFilterSet) {
                    pushNotification({ message: NO_FILTER_RESULTS })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to fetch the products')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }

            commit('SET_PRODUCTS_LOADING', false)
        },
        async registerProduct({ commit, state }, data) {
            try {
                const res = await productsAPI.registerProduct(data)
                console.log('registerProduct STORE res: ', res)

                if (res.data) {
                    commit('SET_PRODUCTS', [res.data, ...state.products.list])
                    commit('SET_PRODUCTS_TOTAL', state.products.total + 1)

                    pushNotification({
                        message: `The product "${data.name}" was created <span>successfully</span>`,
                        type: notificationTypes.SUCCESS
                    })
                }

                return res
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async deleteProduct({ commit, state }, product) {
            try {
                const res = await productsAPI.deleteProduct(product)

                if (res?.data) {
                    const products = state.products.list.filter((p) => {
                        return p._id !== product._id
                    })
                    commit('SET_PRODUCTS', products)
                    commit('SET_PRODUCTS_TOTAL', state.products.total - 1)

                    pushNotification({
                        message: `The Product "${product.name}" was deleted <span>successfully</span>`,
                        type: notificationTypes.SUCCESS
                    })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to delete the Product')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        /* products END */

        /* deals START */
        async fetchDeals({ commit }, { isFilterSet, ...params }) {
            commit('SET_DEALS_LOADING', true)

            try {
                const res = await dealsAPI.fetchDeals(params)
                console.log('fetchDeals res: ', res)

                if (res?.data?.results?.length) {
                    commit('SET_DEALS', res.data.results)
                    commit('SET_DEALS_TOTAL', res.data._meta[0].count)
                } else if (!res?.data?.results?.length && isFilterSet) {
                    pushNotification({ message: NO_FILTER_RESULTS })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to fetch the Deals')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }

            commit('SET_DEALS_LOADING', false)
        },
        async registerDeal({ commit, state }, deal) {
            try {
                const res = await dealsAPI.submitDeal(deal)
                console.log('registerDeal store res: ', res)

                if (res?.data) {
                    commit('SET_DEALS', [res.data, ...state.deals.list])
                    commit('SET_DEALS_TOTAL', state.deals.total + 1)

                    pushNotification({
                        message: `The deal "${res.data.name}" was created <span>successfully</span>`,
                        type: notificationTypes.SUCCESS
                    })
                }

                return res
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async deleteDeal({ commit, state }, deal) {
            /**
             * удаление сделки пока не будет, мы не можем удалить сделку из СФ.
             * поведение удаления не тестировалось.
             */
            try {
                const res = await dealsAPI.deleteDeal(deal)
                console.log('deleteDeal STORE res: ', res)

                if (res?.data) {
                    const deals = state.deals.list.filter((d) => {
                        return d._id !== deal._id
                    })
                    commit('SET_DEALS', deals)
                    commit('SET_DEALS_TOTAL', state.deals.total - 1)

                    pushNotification({
                        message: `The Deal "${deal.name}" was deleted <span>successfully</span>`,
                        type: notificationTypes.SUCCESS
                    })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to delete the Deal')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        /* deals END */

        /* leads START */
        async fetchLeads({ commit }, { isFilterSet, ...params }) {
            commit('SET_LEADS_LOADING', true)

            try {
                const res = await leadsAPI.fetchLeads(params)
                console.log('fetchLeads res: ', res)

                if (res?.results?.length) {
                    commit('SET_LEADS', res.results)
                    commit('SET_LEADS_TOTAL', res._meta[0].count)
                } else if (!res?.results?.length && isFilterSet) {
                    pushNotification({ message: NO_FILTER_RESULTS })
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Failed to fetch the Leads')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }

            commit('SET_LEADS_LOADING', false)
        },
        async registerLead({ commit, state }, data) {
            try {
                const res = await leadsAPI.registerLead(data)
                console.log('registerLead res: ', res)

                if (res.data?.id) {
                    const newLead = { _id: res.data.id, ...data }
                    commit('SET_LEADS', [newLead, ...state.leads.list])
                    commit('SET_LEADS_TOTAL', state.leads.total + 1)

                    pushNotification({
                        message: `The lead "${data.name}" was created <span>successfully</span>`,
                        type: notificationTypes.SUCCESS
                    })
                }

                return res
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        }
        /* leads END */
    }
}
