import { roles } from '@/constants/rbac/roles'

/**
 * ADMIN: 'admin' -> MANAGER: 'manager'
 * SUPER_ADMIN: 'super' -> ADMIN: 'admin'
 */

export const registrationMode = {
    MANAGER: 'manager',
    ADMIN: 'admin'
}

export const partnerRoleOptions = [
    {
        value: roles.USER.toLowerCase(),
        text: 'User'
    },
    {
        value: roles.MANAGER.toLowerCase(),
        text: 'Manager'
    },
    {
        value: roles.VIEWER.toLowerCase(),
        text: 'Viewer'
    },
    {
        value: roles.ADMIN.toLowerCase(),
        text: 'Admin'
    }
]

const getPartnerRoles = (options) => {
    return options.reduce((acc, { value, text }) => {
        return { ...acc, [value]: text }
    }, {})
}

export const partnerRoles = getPartnerRoles(partnerRoleOptions)
