export const countries = require('countries-phone-masks')

export const getMask = function (iso2) {
    let mask = countries.find(({ iso }) => iso === iso2)?.mask

    if (typeof mask === 'undefined') {
        mask = countries.find(({ iso }) => iso === 'US').mask
    }

    mask = Array.isArray(mask)
        ? mask
              .map((el) => el.replace(/#/g, '_'))
              .sort((a, b) => (a.length < b.length ? -1 : 1))
        : mask.replace(/#/g, '_')
    return mask
}

export const phoneCallback = function (value, iso) {
    const mask = getMask(iso)
    const matrix = Array.isArray(mask)
        ? mask.find((el) => el.replace(/[^_]/g, '').length >= value.length) ||
          mask[mask.length - 1]
        : mask
    const maxLength = matrix.length
    let locVal = value

    let i = 0
    const def = matrix.replace(/\D/g, '')
    let val = locVal.replace(/\D/g, '')

    if (val.length < matrix.match(/([_\d])/g).length) {
        locVal = ''
    }

    if (def.length >= val.length) val = def

    locVal = matrix.replace(/./g, function (a) {
        return /[_\d]/.test(a) && i < val.length
            ? val.charAt(i++)
            : i >= val.length
            ? ''
            : a
    })

    return { value: locVal, maxLength }
}

function findCountryByPhone(value) {
    if (!value.indexOf('+') && value.length > 1) {
        const goodCountries = countries.filter(
            ({ code }) => !value.indexOf(code)
        )

        const country = goodCountries.find((el) => {
            if (Array.isArray(el.mask)) {
                return el.mask.some((mask) => {
                    const str =
                        '^' +
                        el.code.replace('+', '') +
                        mask
                            .replace(/[()-]/g, '')
                            .split('')
                            .map((el) => (el === '#' ? '[0-9]' : el))
                            .join('') +
                        '$'
                    return new RegExp(str).test(value.replace('+', ''))
                })
            } else {
                const str =
                    '^' +
                    el.code.replace('+', '') +
                    el.mask
                        .replace(/[()-]/g, '')
                        .split('')
                        .map((el) => (el === '#' ? '[0-9]' : el))
                        .join('') +
                    '$'
                return new RegExp(str).test(value.replace('+', ''))
            }
        })

        return country
    }

    return ''
}

export function getInitialFormattedValue(value) {
    const country = findCountryByPhone(value)

    if (country) {
        return value.replace(country.code, '')
    }

    return ''
}

export function getInitialCountryISO(value) {
    const country = findCountryByPhone(value)

    if (country) {
        return country.iso
    }

    return ''
}
