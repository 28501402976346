<script setup>
import { computed, ref } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    validator: { type: [Object, Boolean], default: false },
    placeholder: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    required: { type: Boolean, default: false }
})

const emit = defineEmits(['update:modelValue'])

const hasFocus = ref(false)

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value.toString())
    }
})

const classes = computed(() => {
    const classes = ['base-date']

    classes.push(`base-date_${props.mode}`)

    if (props.required) {
        classes.push(`base-date_required`)
    }
    if (errorText.value) {
        classes.push(`base-date_error`)
    }
    if (props.modelValue) {
        classes.push(`base-date_has-value`)
    }
    if (hasFocus.value) {
        classes.push(`base-date_focus`)
    }

    return classes
})

const errorText = computed(() => {
    return props.validator?.$errors[0]?.$message
})

const toYear = computed(() => new Date().getFullYear() + 10)
</script>

<script>
export default {
    name: 'BaseDate'
}
</script>

<template>
    <div :class="classes">
        <VueDatePicker
            v-model="value"
            :clearable="false"
            auto-apply
            :day-names="['S', 'M', 'T', 'W', 'T', 'F', 'S']"
            :highlight-week-days="[0, 6]"
            highlight-disabled-days
            hide-offset-dates
            month-name-format="long"
            week-start="0"
            :year-range="[2000, toYear]"
            :enable-time-picker="false"
            hide-input-icon
            input-class-name="base-date__input"
            menu-class-name="base-date__menu"
            model-type="yyyy-MM-dd"
            @open="hasFocus = true"
            @closed="hasFocus = false"
            v-bind="$attrs"
        />
        <span class="base-date__label">{{ placeholder }}</span>
        <span v-if="validator.$error" class="base-date__helper">
            {{ errorText }}
        </span>
    </div>
</template>

<style lang="scss">
.base-date {
    position: relative;
    padding: 8px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    &:before {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
        width: 16px;
        height: 16px;
        background-image: url('@/assets/images/common/calendar.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__input {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 24px;
        cursor: default;
        width: 100%;
        outline: none;
        padding: 10px 20px;
        background-color: transparent;
        border-radius: 32px;
        transition: 0.2s;
        position: relative;
        z-index: 1;
    }
    &__menu {
        right: 0;
        left: 0 !important;
        top: calc(100% + 10px) !important;
        transform: translate(0px, -18px) !important;
        padding-top: 4px;
        border-radius: 0;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 100%;
            background-color: #f5f5f5;
        }

        .dp {
            &__arrow {
                &_top {
                    display: none;
                }
            }
            &__overlay {
                font-family: ReadexPro, sans-serif;
                font-size: 12px;
                line-height: 16px;
                color: #969696;
                &_cell {
                    border: 1px solid transparent;
                    &_active {
                        background: #f74e00;
                    }
                    &:hover {
                        color: #969696;
                        border: 1px solid #f74e00;
                        background-color: transparent;
                    }
                }
            }
            &__btn {
                &[aria-label='Previous month'] {
                    order: 1;
                }
                &[aria-label='Next month'] {
                    order: 2;
                }
            }
            &__month_year_select {
                font-family: ReadexPro, sans-serif;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #969696;
            }
            &__inner {
                &_nav {
                    color: #f74e00;
                    &_disabled,
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
            &__calendar {
                &_header {
                    &_item {
                        font-family: ReadexPro, sans-serif;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #969696;
                    }
                    &_separator {
                        display: none;
                    }
                }
                &_item {
                    display: flex;
                    justify-content: center;
                    font-family: ReadexPro, sans-serif;
                    font-size: 12px;
                    line-height: 16px;
                    color: #969696;

                    .dp {
                        &__cell {
                            &_inner {
                                border-radius: 50%;
                            }
                            &_disabled {
                                color: #969696;
                            }
                            &_highlight {
                                background-color: transparent;
                                color: #ffb38f;
                            }
                        }
                        &__today {
                            background-color: #f4f5fb;
                            border-color: transparent;
                        }
                        &__date {
                            &_hover {
                                &:hover {
                                    color: #969696;
                                    border: 1px solid #f74e00;
                                    background-color: transparent;
                                }
                            }
                        }
                        &__active {
                            &_date {
                                background-color: #f74e00;
                            }
                        }
                    }
                }
            }
        }
    }

    &__label {
        position: absolute;
        cursor: text;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
        transition: 0.3s;
        z-index: 0;
    }
    &_focus .base-date__label,
    &_has-value .base-date__label {
        padding: 0 2px;
        top: 9px;
        left: 20px;
        font-size: 12px;
        line-height: 16px;
        z-index: 1;
    }

    &__helper {
        position: absolute;
        top: 100%;
        left: 20px;
        transform: translate(0, calc(-50% - 10px));
        font-size: 12px;
        line-height: 16px;
        padding: 0 4px;
        display: inline-block;
        max-width: calc(100% - 32px);
        z-index: 1;
    }
    &_light {
        .base-date__menu {
            border: 1px solid $focus-border-color !important;
            border-top: none !important;
        }
        .base-date__label {
            background-color: $primary-label-bg;
            color: $secondary-field-text-color;
        }
        .base-date__helper {
            background-color: $primary-label-bg;
            color: $warning-text-color;
        }
        .base-date__input {
            border: 1px solid $primary-border-color;
            &:hover {
                border: 1px solid $hover-border-color;
            }
        }
        &.base-date_focus .base-date__input {
            border: 1px solid $focus-border-color;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.base-date_error {
            .base-date__input {
                border: 1px solid $warning-border-color;
            }
            .base-date__menu {
                border: 1px solid $warning-border-color !important;
                border-top: none !important;
            }
        }
    }
    &_required {
        .base-date__label {
            &:after {
                content: '*';
                color: $warning-text-color;
                padding-left: 5px;
            }
        }
    }
}
</style>
