<script setup>
defineProps({
    totalItems: { type: Number, required: true },
    maxPagesShown: { type: Number, default: 3 },
    itemsPerPage: { type: Number, default: 10 }
})
</script>
<script>
export default {
    name: 'BasePagination'
}
</script>

<template>
    <vue-awesome-paginate
        v-if="totalItems > itemsPerPage"
        :total-items="totalItems"
        :items-per-page="itemsPerPage"
        :max-pages-shown="maxPagesShown"
        v-bind="$attrs"
        class="base-pagination"
    >
        <template #prev-button>
            <BaseIcon i="pagination" />
        </template>
        <template #next-button>
            <BaseIcon i="pagination" />
        </template>
    </vue-awesome-paginate>
</template>

<style lang="scss" scoped>
.base-pagination {
    &.pagination-container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        width: 100%;
    }
    &:deep(.paginate-buttons) {
        display: flex;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        outline: none;
        color: #c4caec;
        padding: 2px 12px;
        transition: 0.2s;
        &:hover {
            color: #ff7231;
        }
        &.active-page {
            color: #ff7231;
            cursor: default;
        }
        &.back-button,
        &.next-button {
            color: #f74e00;
            .icon {
                width: 11px;
                height: 18px;
            }
        }
        &.next-button {
            .icon {
                transform: rotate(-180deg);
            }
        }
    }
}
</style>
