<script setup>
import { leadValues } from '@/constants/leadValues'
import { computed } from 'vue'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    titles: { type: Array, required: true },
    filterStatusOptions: { type: [Boolean, Array], default: false }
})
const classes = computed(() => {
    const classes = ['leads-header']

    classes.push(`leads-header_col-${props.titles.length}`)

    if (props.mode) {
        classes.push(`leads-header_${props.mode}`)
    }

    return classes
})
</script>
<script>
export default {
    name: 'LeadsListHeader'
}
</script>

<template>
    <div :class="classes">
        <div
            v-for="(item, index) in titles"
            :key="index"
            class="leads-header__item"
            :class="
                item.value === leadValues.ACTION_STATUS
                    ? 'leads-header__item_text-end'
                    : null
            "
        >
            <template
                v-if="item.value === leadValues.STATUS && filterStatusOptions"
            >
                <div class="leads-header__item--status">
                    {{ item.title }}
                    <BaseFilter
                        :filter-options="filterStatusOptions"
                        emit-name-on-apply="onStatusFilterApply"
                        emit-name-on-reset="onStatusFilterReset"
                    />
                </div>
            </template>
            <template v-else>
                {{ item.title }}
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.leads-header {
    display: grid;
    padding: 12px 0;
    border-bottom: 1px solid #f4f6fa;
    &_col-5 {
        grid-template-columns: repeat(5, 1fr);
    }
    &_col-6 {
        grid-template-columns: repeat(6, 1fr);
    }

    &__item {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.019em;
        color: #b5b5b5;

        &--status {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &_text-end {
            text-align: right;
        }
    }
}
</style>
