import axios from 'axios'
import configLoader from '@/utils/config'

if (!window.configLoaded) {
    await configLoader()
}

export const instance = axios.create({
    baseURL: window.config.baseAPIUrl,
    withCredentials: true
})
