<script setup>
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { userCredentialActions } from '@/constants/user-settings'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useVfm } from 'vue-final-modal'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'
import { partnerRoles } from '@/constants/partnerRegistration'

const props = defineProps({
    user: { type: Object, default: () => {} }
})
const store = useStore()
const vfm = useVfm()
const modalIdSymbol = Symbol(props.user._id)

const classes = computed(() => {
    const classes = ['text', 'user']

    if (isPivotal.value) {
        classes.push('user_admin')
    }

    return classes
})

const isPivotal = computed(() => props.user.pivotal)

const confirmed = computed(() => props.user.confirmed ?? false)
const deleted = computed(() => props.user.deleted ?? false)

const avatar = computed(() => {
    const [firstName, lastName] = props.user.name.split(' ')
    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`
})

const userRole = computed(() => partnerRoles[props.user.role.toLowerCase()])

const status = computed(() => {
    if (confirmed.value && !deleted.value) {
        return {
            text: 'Active',
            styling: 'green'
        }
    } else if (deleted.value) {
        return {
            text: 'Removed',
            styling: 'maroon'
        }
    } else {
        return {
            text: 'New',
            styling: 'purple'
        }
    }
})

const loadingAction = ref(false)

const setCredentials = async (params) => {
    loadingAction.value = true
    await store.dispatch('setUserCredentials', params)
    loadingAction.value = false
}

const removeUser = (id, action) => {
    setCredentials({ id, action })
    cancelModal()
}

const openModal = () => vfm.open(modalIdSymbol)
const cancelModal = () => vfm.close(modalIdSymbol)
</script>
<script>
export default {
    name: 'UserSettingsInfo'
}
</script>

<template>
    <div :class="classes">
        <div class="user__inner">
            <div class="user__avatar">{{ avatar }}</div>
            <div>
                <div>{{ user.name }}</div>
                <a class="user__email" :href="`mailto:${user.email}`">
                    {{ user.email }}
                </a>
            </div>
            <div>{{ userRole }}</div>
            <div>
                <div v-if="user.pivotal">—</div>
                <BaseTag v-else :styling="status.styling" :text="status.text" />
            </div>
            <div>
                <div v-if="user.pivotal">—</div>
                <div v-else class="user__actions">
                    <button
                        v-if="
                            !deleted &&
                            checkHasPermissions([permissionsEnum.BLOCK_USER])
                        "
                        :disabled="loadingAction"
                        class="user__action user__action_remove"
                        @click="openModal"
                    >
                        Remove
                        <LoadingSpinner v-if="loadingAction" />
                        <BaseIcon v-else i="user-remove" />
                    </button>
                    <div v-else>—</div>
                </div>
            </div>
        </div>
    </div>
    <BaseModal :modal-id="modalIdSymbol" :widthContent="658">
        <div class="user__modal">
            <h3 class="subtitle">
                Are you sure you want to remove
                <!-- eslint-disable-next-line -->
                <span>{{ user.name }}</span>?
            </h3>
            <div class="user__modal--actions">
                <BaseButton
                    size="md"
                    design="outline"
                    @click="removeUser(user._id, userCredentialActions.REMOVE)"
                >
                    Remove
                </BaseButton>
                <BaseLink @click="cancelModal">Cancel</BaseLink>
            </div>
        </div>
    </BaseModal>
</template>
<style lang="scss" scoped>
.user {
    position: relative;
    &__inner {
        box-shadow: 0px 4px 34px #adbfff4d;
        display: grid;
        grid-template-columns: 64px 2.2fr 1.2fr 1fr 2.5fr;
        align-items: center;
        gap: 16px;
        border-radius: 20px;
        padding: 16px 24px;
        background: #ffffff;
        position: relative;
        transition: all 0.1s;
        z-index: 2;
    }
    &__timer {
        z-index: 1;
    }

    &__avatar {
        background: #ffe7e7;
        width: 64px;
        height: 64px;
        font-weight: 600;
        font-size: 32px;
        line-height: 37px;
        text-transform: uppercase;
        background: #ffe7e7;
        border-radius: 50%;
        color: #ffb38f;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    }
    &__email {
        overflow-wrap: anywhere;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &__actions {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        @include media_max_xl {
            gap: 8px;
        }
    }
    &__action {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        min-width: 130px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.2s;
        outline: none;
        background: transparent;
        .icon {
            width: 24px;
            height: 24px;
        }

        &.user__action_remove {
            border: 1px solid #f3919d;
            color: #f3919d;
            &:hover {
                background: #ffe7ea;
            }
            &:active,
            &:focus {
                color: #ffffff;
                background: #f3919d;
            }
            &:disabled {
                color: #cfcfcf;
                border: 1px solid #cfcfcf;
                background: transparent;
                cursor: not-allowed;
            }
        }
    }
    &__modal {
        .subtitle {
            text-align: center;
            margin-bottom: 33px;
            span {
                color: #12429d;
            }
        }
        &--actions {
            display: flex;
            gap: 28px;
            justify-content: center;
            align-items: center;
            .link {
                cursor: pointer;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.019em;
                color: #969696;
                text-decoration: none;
                &:hover {
                    color: #969696;
                    text-decoration: underline;
                }
            }
        }
    }

    &_admin {
        .user__inner {
            background: #2f76ff;
            background-image: url('@/assets/images/common/user-admin-bg.svg');
            background-repeat: no-repeat;
        }

        &.text,
        a {
            color: #ffffff;
        }

        .user__avatar {
            background: #7eaaff;
            color: #d3e2ff;
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: all 0.1s ease-in-out;
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
    transform: translate(0, -10px);
}
</style>
