export default {
    title: 'Quote Calculator Page',
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: 'Quote Calculator description'
        }
    ]
}
