import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'

export const handlerAPIErrors = (err, options) => {
    const errorInstance = {
        errInfo: {
            status: null,
            isError: true,
            message: '',
            errors: null
        }
    }

    console.log(
        `handlerAPIErrors ${options.info ? 'in ' + options.info : ''}: `,
        err
    )

    if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        const status = err.response.status

        if (status === 404) {
            pushNotification({
                message: `<span>Error 404:</span> Page not found`,
                type: notificationTypes.ERROR
            })
        } else if (status === 403) {
            const errors = err.response.data.errors

            errorInstance.errInfo.errors = [...errors]
            errorInstance.errInfo.status = status
        } else if (status === 401) {
            if (Array.isArray(err.response.data?.errors)) {
                const errors = err.response.data.errors
                errorInstance.errInfo.errors = [...errors]
            } else {
                errorInstance.errInfo.message = err.response.data
            }

            errorInstance.errInfo.status = status
        } else if (status === 400) {
            const errors = err.response.data.errors
            const name = err.response.data.name

            if (errors?.length) {
                errors.map((error) => {
                    if (error.msg && (error.value || error.param)) {
                        pushNotification({
                            message: `${error.msg}: <span>${
                                error.value || error.param
                            }</span>`,
                            type: notificationTypes.ERROR
                        })
                    }
                })
                errorInstance.errInfo.errors = [...errors]
                errorInstance.errInfo.status = status
            } else if (name) {
                pushNotification({
                    message: `${name}`,
                    type: notificationTypes.ERROR
                })
            }
        } else if (status === 500) {
            const statusCode = err.response.data.statusCode
            const message = err.response.data.name

            pushNotification({
                message: `<span>Error ${statusCode}:</span> ${message}`,
                type: notificationTypes.ERROR
            })
        } else {
            // Rethrowing
            throw err
        }

        return errorInstance
    } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        // and an instance of http.ClientRequest in node.js

        const code = err.code || 'Error'
        const message =
            err.message || 'The request was made but no response was received'

        pushNotification({
            message: `<span>${code}:</span> ${message}`,
            type: notificationTypes.ERROR
        })

        return errorInstance
    } else {
        // Something happened in setting up the request that triggered an Error

        const message =
            err.message || 'Something happened in setting up the request'
        pushNotification({
            message: `<span>Error:</span> ${message}`,
            type: notificationTypes.ERROR
        })

        return errorInstance
    }
}
