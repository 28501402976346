<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
    mode: { type: String, default: 'light' } // light, dark
})
const classes = computed(() => {
    const classes = ['dashboard-banner']

    if (props.mode) {
        classes.push(`dashboard-banner_${props.mode}`)
    }

    return classes
})

const firstName = computed(() => store.getters.firstName)
</script>
<script>
export default {
    name: 'DashboardBanner'
}
</script>

<template>
    <div :class="classes">
        <div class="dashboard-banner__content">
            <h1 class="dashboard-banner__title">
                Welcome back, {{ firstName }}!
            </h1>
            <p class="text">
                Unlock new revenue streams with MSP360's innovative product
                suite. Delivering value to your business is MSP360's top
                priority.
            </p>
        </div>
        <div class="dashboard-banner__image">
            <BaseImage alt="Base Image" image="dashboard/banner-hero" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.dashboard-banner {
    background: #7eaaff;
    overflow: hidden;
    border-radius: 20px;
    min-height: 308px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url('@/assets/images/dashboard/banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;

    @include media_lg {
        min-height: 230px;
        position: relative;
    }

    &__content {
        padding: 24px 0px 24px 52px;
        color: #ffffff;

        @include media_lg {
            padding: 30px 0 30px 52px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 64px;
        line-height: 74px;
        letter-spacing: -0.019em;
        margin-bottom: 24px;

        @include media_lg {
            font-size: 54px;
            line-height: 64px;
            margin-bottom: 20px;
        }
    }
    .text {
        color: #ffffff;

        @include media_lg {
            margin-right: 42%;
        }
    }
    &__image {
        align-self: end;
        flex: 0 0 573px;
        max-width: 45%;

        @include media_lg {
            position: absolute;
            bottom: 0;
            right: 0;
            flex: 0 0 410px;
            max-width: 42%;
        }
    }
}
</style>
