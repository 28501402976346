<script setup>
import { copyInClipboard } from '@/utils/common'

defineProps({
    text: { type: String, default: '' }
})
</script>
<script>
export default {
    name: 'CopyClipboardIcon'
}
</script>

<template>
    <BaseIcon @click="copyInClipboard(text)" i="copy" class="clipboard-icon" />
</template>

<style lang="scss" scoped>
.clipboard-icon.icon {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
</style>
